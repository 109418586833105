/* eslint-disable @typescript-eslint/no-unused-vars */
import { type GridRenderCellParams, GridValidRowModel } from '@mui/x-data-grid-pro';
import { cloneDeep } from 'lodash';
import React, { ReactNode, memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  CustomStatus,
  CustomTooltipWithLabel,
  downloadFile,
  FilterProp,
  renderHeaderActionButtons,
  TableDashboard,
  useAppDispatch,
  useAppSelector,
} from '../../globalUtils/globalExports';
import {
  userTypes,
  fetchUsersData,
  deleteUser,
  exportToCsvForUserList,
  getUserReducer,
  setTableProps,
  getReferenceDataReducer,
  getFilterReducer,
  getFilterSelectedValue,
  setFilterData,
  getCurrentFiltersValues,
  setRedirectedStateForUserFilters,
  setTableColumnPrefrences,
  getGlobalReducer,
} from '../../rmsReduxStore/reduxExports';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import './users.css';
import { Id, toast } from 'react-toastify';
import { NoDataComponentUsers } from '../../globalUtils/TableDashboard/TableComponents';
import { ActionIcons } from '../../globalUtils/globalIcons';
import CustomDialog from '../../globalUtils/CustomDialog/CustomDialog';
import access from '../../auth/service/AccessControl';
import { formatNumberWithCommas } from '../../globalUtils/globalHooks';

const UserManagementDashboard = memo(function UserManagementDashboard() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { users, userCount, userListTableProps, loader, userFiltersAppliedFromRedirection } = useAppSelector(getUserReducer);
  const { screenFilters } = useAppSelector(getFilterReducer)
  const { orgReferenceData } = useAppSelector(getReferenceDataReducer)
  const {screenTableColumnPrefrences} = useAppSelector(getGlobalReducer)
  const [exportAnchorEl, setExportAnchorEl] = useState(null);
  const [exportingFile, setExportingFile] = useState(false);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState<string | null>(null);
  const userListTablePropsRef = useRef(userListTableProps);
  const usersRef = useRef(users);

  useEffect(() => {
    userListTablePropsRef.current = userListTableProps;
  }, [userListTableProps]);

  useEffect(() => {
    usersRef.current = users;
  }, [users]);

  const handleNameClick = async (event, rowData) => {
    navigate('/user', { state: { userId: rowData.id, mode: 'View', rowData: rowData } });
  };

  // TODO: Edit Button click
  const onEditIconClick = (rowData) => {
    navigate('/user', { state: { userId: rowData.id, mode: 'Edit', rowData: rowData } })
  }

  // TODO: Handles global search box
  const onChangeOfSearchText = useCallback(async (searchTerm) => {
    if (userListTablePropsRef?.current) {
      await layoutCallback(1, userListTablePropsRef?.current?.pageSize, userListTablePropsRef?.current?.view, userListTablePropsRef?.current?.sortBy, userListTablePropsRef?.current?.order, null, searchTerm)
    }
  }, [userListTablePropsRef?.current, usersRef?.current]);

  const searchBoxConfiguration = useMemo(() => {
    return {
      searchFieldId: 'manage-users-search-box',
      searchFieldName: 'manage-users-search-box',
      handleSearch: onChangeOfSearchText,
    };
  }, [onChangeOfSearchText]);

  // TODO: Delete Handlers
  const userDeletionSuccessToast = useCallback((): Id => {
    return toast.success('User deleted.');
  }, []);

  const userDeletionFailureToast = useCallback((message: string): Id => {
    return toast.warn(message ?? 'Something went wrong.');
  }, []);

  const onDeleteIconClick = useCallback(async (id: string): Promise<void> => {
    setSelectedUserId(id);
    setIsDeleteDialogOpen(true);
  }, []);

  const handleDeleteDialogClose = useCallback(() => {
    setIsDeleteDialogOpen(false);
    setSelectedUserId(null);
  }, []);

  const handleDeleteUser = useCallback(async () => {
    const { data, status } = await dispatch(deleteUser({
      userId: selectedUserId
    }));
    if ((status === 200 || status === 202) && data?.message === 'Success') {
      userDeletionSuccessToast();
      layoutCallback(1, userListTablePropsRef?.current?.pageSize, userListTablePropsRef?.current?.view, userListTablePropsRef?.current?.sortBy, userListTablePropsRef?.current?.order, null, '');
    } else {
      userDeletionFailureToast(data?.message)
    }
    handleDeleteDialogClose();
  }, [selectedUserId, userListTablePropsRef?.current]);

  const renderExportMenuItem = (fileType: 'csv' | 'xlsx', label: string): JSX.Element => (
    <MenuItem key={fileType}>
      <div className='export__btn-option' onClick={(e) => {
        e?.stopPropagation();
        onExportBtnClick(fileType);
      }}>
        {label}
      </div>
    </MenuItem>
  );

  // TODO: Adds action btns in header and their callbacks
  const headerActionBtns = renderHeaderActionButtons({
    actionBtns:
      [
        {
          id: 'add_new_btn',
          isAccessible: access?.users?.add(),
          headerActionBtnClick: (): void => {
            navigate('/user', { state: { mode: 'Add' } })
          }
        },
        {
          id: 'export_csv_btn',
          isAccessible: access?.users?.export(),
          headerActionBtnClick: (event): void => {
            setExportAnchorEl(event.currentTarget);
          }
        }
      ]
  })

  const actionIconsList = useMemo(() => {
    return [{ icon: 'pencilIcon', isAccessible: access?.users?.edit(), action: (_, rowData) => onEditIconClick(rowData) },
    { icon: 'deleteIcon', isAccessible: access?.users?.delete(), action: (_, rowData) => onDeleteIconClick(rowData?.id) }];
  }, [onDeleteIconClick]);

  // TODO: Table Layout Callbacks
  const mobileViewConfiguration = useMemo(() => {
    return {
      headerDataConfig: {
        headerLeftDataConfig: ['name'],
        headerRightDataConfig: {
          actionIconsComponent: false,
          statusIconComponent: true,
        },
      },
      statusIconKey: 'status',
      statusIconMapper: {
        'Active': 'status__active__user__icon',
        'Inactive': 'status__inactive__user__icon'
      },
      contentDataConfig: [
        [
          {
            key: 'mobileNumber',
            iconClassName: 'rms__user_mobile_view_phno_icon',
          },

          {
            key: 'emailId',
            iconClassName: 'rms__user_mobile_view_mail_icon',
          },
        ],
        [
          {
            key: 'roleName',
            iconClassName: 'rms__user_mobile_view_role_type_icon',
          },
          {
            key: 'orgName',
            iconClassName: 'rms__user_mobile_view_user_type_icon',
          },
        ],
      ],
    };
  }, []);


  const getTableRows = useCallback((): IManageUser[] => {
    return users !== null && users?.length > 0 ? users : [];
  }, [users]);

  const userColumns = useMemo(() => {
    return [
      {
        field: 'name',
        // flex: 1,
        maxWidth: 500,
        headerName: 'Name',
        minWidth: 130,
        hideable:false,
        renderCell: (params: GridRenderCellParams<GridValidRowModel>): JSX.Element => {
          const rowData = params?.row;
          return (
            <div
              className="individual__user__text"
              style={{width: '100%'}}
              onClick={(e) => {
                e?.stopPropagation()
                handleNameClick(e, params.row);
              }
              }
            >
              <CustomTooltipWithLabel label={rowData?.name} labelId='user__dashboard__name'/>
            </div>
          );
        },
      },
      {
        field: 'mobileNumber',
        headerName: 'Mobile Number',
        minWidth: 120,
        editable: false,
        flex: 1,
        renderCell: (params: GridRenderCellParams<GridValidRowModel>): ReactNode => {
          const rowData = params?.row;
          if (rowData?.countryCode && rowData?.mobileNumber) return rowData?.countryCode + rowData?.mobileNumber
          return rowData?.mobileNumber
        },
      },
      {
        field: 'emailId',
        headerName: 'Email Address',
        flex: 1,
        minWidth: 230,
      },
      {
        field: 'roleName',
        headerName: 'Role',
        flex: 1,
        minWidth: 100,
      },
      {
        field: 'orgName',
        headerName: 'Organisation',
        flex: 1,
        minWidth: 120,
      },
      {
        field: 'chargers',
        headerName: 'Access to Chargers',
        flex: 1,
        minWidth: 160,
        headerAlign: 'right',
        align: 'right',
        renderCell: (params: GridRenderCellParams<GridValidRowModel>): JSX.Element => {
          const rowData = params?.row;
          return (
            <div
              className="individual__user__text"
              onClick={(e) => {
                e?.stopPropagation()
                handleNameClick(e, params.row);
              }
              }
            >
              {formatNumberWithCommas(rowData?.chargers)}
            </div>
          );
        },
      },
      {
        field: 'status',
        headerName: 'Status',
        minWidth: 100,
        flex: 1,
        editable: false,
        headerAlign: 'center',
        align: 'center',
        renderCell: (params: GridRenderCellParams<GridValidRowModel, Date>): JSX.Element => {
          return <CustomStatus statusText={params?.row?.status}
            statusIconClassName={params?.row?.status === 'Active' ? 'status__active__user__icon' : 'status__inactive__user__icon'} />;
        },
      },
      {
        field: 'notes',
        headerName: 'Notes',
        minWidth: 100,
        flex: 1,
        sortable: false,
        renderCell: (params: GridRenderCellParams<GridValidRowModel>): JSX.Element => {
          const rowData = params?.row;
          return <CustomTooltipWithLabel labelId='user__dashboard-notes' label={rowData?.notes} />
        },
      },

      {
        field: 'View',
        headerName: 'Actions',
        flex: 1,
        sortable: false,
        minWidth: 100,
        renderCell: (params: GridRenderCellParams<GridValidRowModel, Date>): JSX.Element => {
          const rowData = params?.row;
          return (
            <div className="charging__station__action__icons__wrap">
              <ActionIcons actionIconsList={actionIconsList} data={rowData} />
            </div>
          );
        },
      },
    ];
  }, []);


  const layoutCallback = useCallback(async (pageNumber: number, pageSize: number, view: string, sortField: string, sortOrder: string, filterData, searchTerm) => {
    const tableProps: IListTableProps = cloneDeep(userListTablePropsRef.current)
    await dispatch(fetchUsersData({
      sortBy: sortField || userListTablePropsRef?.current?.sortBy,
      order: sortOrder || userListTablePropsRef?.current?.order,
      pageSize,
      pageNumber,
      filters: filterData ? filterData : getCurrentFiltersValues('users', true),
      searchTerm: searchTerm != null && searchTerm != undefined ? searchTerm : userListTablePropsRef?.current?.searchTerm
    }));

    if (tableProps) {
      tableProps.sortBy = sortField || userListTablePropsRef?.current?.sortBy;
      tableProps.order = sortOrder || userListTablePropsRef?.current?.order;
      tableProps.pageNumber = pageNumber;
      tableProps.pageSize = pageSize;
      tableProps.view = view || 'list'
      if (searchTerm != null && searchTerm != undefined) {
        tableProps.searchTerm = searchTerm;
      }
    }
    await dispatch(setTableProps(tableProps, 'usersList'))
  }, [userListTablePropsRef?.current, dispatch]);

  // Handler For Column Prefrence Chnages
  const handleColumnPrefrenceChange = useCallback(async (operation,data) => {
    await dispatch(setTableColumnPrefrences('users',operation,data))
 },[])

  // ********************************************
  // TODO: Filter side pop up 
  // onchange -> handles onchange of all the dropdowns, layout props -> pass the required dropdowns, footer button action
  const handleFilterDataChange = useCallback(async (val, filterKey, isGlobal) => {
    const res = await setFilterData(val, filterKey, 'users', 'SET', isGlobal ? isGlobal : false, true)(dispatch)
    if (res?.message === 'Action dispatched successfully') {
      if (userListTablePropsRef?.current) {
        await layoutCallback(1, userListTablePropsRef?.current?.pageSize, userListTablePropsRef?.current?.view, userListTablePropsRef?.current?.sortBy, userListTablePropsRef?.current?.order, res?.filters, userListTablePropsRef?.current?.searchTerm)
      }
    }
  }, [userListTablePropsRef?.current])

  const onResetButtonClick = useCallback(async (callListApi: boolean) => {
    const res = await setFilterData(null, '', 'users', 'CLEAR', false)(dispatch);
    if (res?.message === 'Action dispatched successfully') {
      if (userListTablePropsRef?.current && callListApi) {
        layoutCallback(1, userListTablePropsRef?.current?.pageSize, userListTablePropsRef?.current?.view, userListTablePropsRef?.current?.sortBy, userListTablePropsRef?.current?.order, res?.filters, userListTablePropsRef?.current?.searchTerm)
      }
    }
    setRedirectedStateForUserFilters(false)(dispatch)
  }, [userListTablePropsRef?.current, userFiltersAppliedFromRedirection])

  const orgFilterProps: FilterProp[] = useMemo(() => {
    return [
      {
        filterLabel: 'Organisations',
        filterType: 'dropdown',
        filterId: 'organisation',
        filterDropdownProps: {
          selectValue: getFilterSelectedValue('orgName', 'users', false),
          selectOptions: orgReferenceData ?? [],
          handleSelectOptionChange: (val): void => {
            handleFilterDataChange(val, 'orgName', false)
          },
          selectDropDownId: 'user-org-filter-dropdown',
          isMultiSelect: true
        }
      }
    ]
  }, [screenFilters, orgReferenceData, userListTablePropsRef?.current])

  // ***********************************************************
  // TODO: Export Handlers
  const handleClose = (): void => {
    setExportAnchorEl(null);
  };

  const fileDownloadSuccessToast = useCallback((): Id => {
    setExportingFile(false);
    return toast.success('File downloaded.');
  }, []);

  const fileDownloadFailureToast = useCallback((): Id => {
    setExportingFile(false);
    return toast.warn('Something went wrong. Please export again.');
  }, []);


  const onExportBtnClick = useCallback(async (fileType: 'csv' | 'xlsx'): Promise<void> => {
    if (exportingFile) {
      // If already exporting, prevent another export
      return;
    }
    try {
      const response = await dispatch(exportToCsvForUserList({
        fileType: fileType,
        filters: getCurrentFiltersValues('users', true)
      }));
      if (response?.status === 202 || response?.status === 200) {
        downloadFile({ url: response?.data?.url });
        fileDownloadSuccessToast()
      } else {
        fileDownloadFailureToast();
        setExportAnchorEl(null);
      }
    } finally {
      // Ensure that exporting status is updated even if there's an error
      setExportingFile(false);
      setExportAnchorEl(null);

    }
  }, [exportingFile, fileDownloadSuccessToast, fileDownloadFailureToast])

  // TODO: Redux state Clean up
  const userListCleanUpState = useCallback(() => {
    const action = {
      type: userTypes.CLEAR_USER_LIST_DATA,
    }
    dispatch(action);
    if (userFiltersAppliedFromRedirection) {
      onResetButtonClick(false)
    }
    setRedirectedStateForUserFilters(false)(dispatch)
  }, [])

  useEffect(() => {
    window.addEventListener('beforeunload', userListCleanUpState);
    return (): void => {
      userListCleanUpState()
      window.removeEventListener('beforeunload', userListCleanUpState);
    }
  }, [])

  return (
    <>
      <TableDashboard
        tableId="manage-users-table"
        tableClassName="manage__users__table"
        header={'Users'}
        searchBoxIncluded={true}
        searchBoxConfiguration={searchBoxConfiguration}
        gridColumns={userColumns}
        tableRows={getTableRows()}
        mobileViewConfiguration={mobileViewConfiguration}
        layoutView={'list'}
        headerActionBtns={headerActionBtns}
        listLayoutCallBack={layoutCallback}
        totalCount={userCount}
        showSkeleton={!users}
        totalCountText={'users'}
        showLoader={loader}
        customNoDataComponent={NoDataComponentUsers}
        pageNumber={userListTableProps?.pageNumber}
        tablePageSize={userListTableProps?.pageSize}
        sortField={userListTableProps?.sortBy}
        sortOrder={userListTableProps?.order}
        filterProps={orgFilterProps}
        handleFilterDataChange={handleFilterDataChange}
        excludeGlobalFilters={true}
        referenceDataCallbacksFilters={['orgName']}
        columnPrefrences={screenTableColumnPrefrences?.find((item) => item.screen === 'users')}
        handleColumnPrefrenceChange={handleColumnPrefrenceChange}
      />

      <Menu
        id="export-option-dropdown"
        anchorEl={exportAnchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        className='export__option__dropdown'
        sx={{ top: '30px' }}
        open={Boolean(exportAnchorEl)}
        onClose={handleClose}

      >
        {renderExportMenuItem('csv', 'To CSV')}
        {renderExportMenuItem('xlsx', 'To Excel')}
      </Menu>

      <CustomDialog
        dialogConfig={{
          dialogDescription: 'Are you sure you want to delete this user?',
          dialogCancelTitle: 'Cancel',
          dialogOkTitle: 'Delete',
        }}
        show={isDeleteDialogOpen}
        handleClose={handleDeleteDialogClose}
        handleSubmit={handleDeleteUser}
      />
    </>
  );
})

export default UserManagementDashboard;
