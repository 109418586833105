

import {
  DataGridPro,
  GridValidRowModel,
  type GridRenderCellParams,
  GridRowParams,
  GridSortModel,
} from '@mui/x-data-grid-pro';
import React, {
  ReactNode,
  useState,
  type FC,
  useMemo,
  useCallback,
  useEffect,
} from 'react';
import {
  AlarmCards,
  SelectDropDown,
  SortedAscendingIcon,
  SortedDescendingIcon,
  alarmCardMapper,
  useAppDispatch,
  useAppSelector,
  NoDataComponentAlarms,
} from '../../globalUtils/globalExports';
import { fetchAlarmListData, getAlarmReducer, getCurrentFiltersValues, getFilterSelectedValue, getReferenceDataReducer, setFilterData, setRedirectedStateForAlarmFilters } from '../../rmsReduxStore/reduxExports';
import { useNavigate } from 'react-router-dom';
import { formatRelativeTimeForTable } from '../../globalUtils/globalHooks';
import {
  AlarmCardSkeleton,
  AlarmTableSkeleton,
} from '../../globalUtils/SkeletonDesign/SkeletonDesign';
import { CardPropTypes } from '../../globalUtils/AlarmCards/AlarmCards';
import { Arrow, Icon } from '../../globalUtils/globalIcons';
import { LicenseInfo } from '@mui/x-license-pro';


const AlarmSummary: FC = () => {
  LicenseInfo.setLicenseKey('592863905b14abe78770cb5851e88222Tz04Nzg4MixFPTE3NDM5MjM4MzUwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=');
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { alarms, alarmDashboardCount } = useAppSelector(getAlarmReducer);
  const { alarmSeverityReferenceData } = useAppSelector(getReferenceDataReducer);

  const [value, setValue] = useState<IGeneralReferenceData>({ id: 'all', value: 'All', label: 'All', name: 'All' });
  const [selectedAlarmCard, setSelectedAlarmCard] = useState<string>('')

  const handleAlarmFilter = useCallback(async (val): Promise<void> => {
    const res = await setFilterData(val, 'severity', 'dashboard', 'SET', false)(dispatch)
    if (res?.message === 'Action dispatched successfully') {
      dispatch(fetchAlarmListData({ pageSize: 5, pageNumber: 1, filters: res?.filters }))
    }
  }, [setFilterData])

  const handleSelectChange = (val): void => {
    const cardValue = val?.label === 'All' ? 'TOTAL' : val?.value?.toString()?.toLocaleUpperCase()
    setValue(val);
    handleAlarmFilter(val);
    setSelectedAlarmCard(cardValue);
  };

  const handleCardClick = useCallback((props: CardPropTypes): void => {
    // eslint-disable-next-line react/prop-types
    const { headerText } = props;
    // eslint-disable-next-line react/prop-types
    const value = headerText === 'TOTAL' ? 'all' : headerText[0] + headerText.slice(1).toLowerCase();
    setRedirectedStateForAlarmFilters(true, value)(dispatch)
      .then((res) => {
        if (res === 'Action dispatched successfully') {
          navigate('/alarms', {
            state: { severityId: value }
          });
        }
      })
      .catch((error) => {
        console.error('Error dispatching action:', error); // Handle error
      });

  }, [alarmSeverityReferenceData])

  const handleSortModelChange = useCallback(
    (model: GridSortModel) => {
      if (model?.length > 0) {
        const sortOrder =
          model?.[0]?.sort === 'asc'
            ? 'ascending'
            : model?.[0]?.sort === 'desc'
              ? 'descending'
              : ''
        const dashboardCurrentFilters = getCurrentFiltersValues('dashboard');
        const sortField = (model?.[0]?.field?.length ?? 0) > 0 ? model?.[0]?.field : null;
        dispatch(fetchAlarmListData({ sortBy: sortField, order: sortOrder, pageSize: 5, pageNumber: 1, filters: dashboardCurrentFilters }))
      }
    }, [])

  const handleSelectedAlarm = useCallback((event, data) => {
    navigate('/alarm?alarmId=' + data?.id, {
      state: {
        row: data,
        calledFrom: 'dashboard'
      },
    });
  }, []);

  const columns = useMemo(() => {
    return [
      {
        field: 'severity',
        headerName: 'Alarm',
        minWidth: 50,
        maxWidth: 52,
        headerClassName: 'dashboard__alarm__header-severity',
        renderHeader: (): JSX.Element => {
          return (
            <div className='dashboard__alarm__header__alarm-icon'>
              <Icon iconClassName={'total__alarm__icon'} />
            </div>
          )
        },
        renderCell: (
          params: GridRenderCellParams<GridValidRowModel>
        ): ReactNode => {
          const rowData = params?.row;


          return (
            <div className="alarm__summary__name icon__severity">

              <Icon
                iconClassName={
                  alarmCardMapper[rowData?.severity]?.alarmSummaryIcon
                }
              />

            </div>
          );
        },
      },
      {
        field: 'alarmVisibleName',
        flex: 1.25,
        headerName: 'Alarm',
        minWidth: 100,
        renderCell: (
          params: GridRenderCellParams<GridValidRowModel>
        ): ReactNode => {
          const rowData = params?.row;
          return (
            <div className="alarm__summary__name">
              <div className="alarm__summary__name">
                {rowData?.alarmVisibleName}
              </div>
            </div>
          );
        },
      },
      {
        field: 'alarmSetTime',
        flex: 1,
        headerName: 'Time',
        minWidth: 90,
        renderCell: (
          params: GridRenderCellParams<GridValidRowModel>
        ): ReactNode => {
          const rowData = params?.row;
          const date = new Date(rowData?.alarmSetTime);
          return formatRelativeTimeForTable(date);
        },
      },
      {
        field: 'chargerVisibleId',
        headerName: 'Charger',
        minWidth: 100,
        editable: false,
        flex: 1,
        renderCell: (
          params: GridRenderCellParams<GridValidRowModel>
        ): ReactNode => {
          const rowData = params?.row;
          return (
            <div className="alarm__summary__charger__id">
              {rowData?.chargerVisibleId}
              <Arrow color="black" direction="right" />
            </div>
          );
        },
      },
    ];
  }, [alarmCardMapper]);

  const getRows = useCallback(() => {
    const updatedAlarmsData =
      alarms !== null && alarms?.length > 0
        ? alarms?.map((alarm) => {
          const { alarmId, ...rest } = alarm;
          return { id: alarmId, ...rest };
        })
        : [];

    return updatedAlarmsData ?? [];
  }, [alarms]);

  useEffect(() => {
    const severityFilterSelectedValues = getFilterSelectedValue('severity', 'dashboard', false)
    if (severityFilterSelectedValues && severityFilterSelectedValues?.length > 0) {
      const value = severityFilterSelectedValues?.[0].id === 'all' ? 'TOTAL' : severityFilterSelectedValues?.[0].id;
      if (alarmSeverityReferenceData) {
        const filterValue = alarmSeverityReferenceData?.length > 0 && alarmSeverityReferenceData?.find(item => item?.id?.toLocaleLowerCase() === value?.toLocaleLowerCase());
        if (filterValue) {
          setValue(filterValue)
        }
      }
      setSelectedAlarmCard(value.toUpperCase());
    } else {
      setSelectedAlarmCard('TOTAL');
    }
  }, [])

  return (
    <div className="alarm__summary__container">
      <div className="alarm__summary-header-wrap">
        <div className="alarm__summary-header">Active Alarms
        </div>
        <div className="alarm__summary-dropdown">
          <SelectDropDown
            selectValue={value}
            selectOptions={alarmSeverityReferenceData?.filter((item) => item.id !== 'No Alarm') ?? []}
            handleSelectOptionChange={handleSelectChange}
            selectDropDownId="alarm-summary-dropdown"
            isMultiSelect={false}
          />
        </div>
      </div>

      {!alarmDashboardCount ? (
        <AlarmCardSkeleton />
      ) : (
        <div className="alarm__summary-cards-wrap">
          {alarmDashboardCount &&
            alarmDashboardCount !== null &&
            alarmDashboardCount?.length > 0 &&
            alarmDashboardCount?.map((alarmType, index) => (
              <AlarmCards
                key={alarmType?.severity + index}
                alarmContainerClassName="alarms__dashboard__container-alarm-cards"
                cardId={`alarm-summary-${alarmType?.severity}`}
                alarmCount={alarmType?.alarmCount}
                headerText={alarmType?.severity}
                onCardClick={handleCardClick}
                selectedCard={selectedAlarmCard}
              />
            ))}
        </div>
      )}

      <div className="alarm__summary-table">
        {!alarms ? (
          <AlarmTableSkeleton />
        ) : (
          <DataGridPro
            rows={getRows() ?? []}
            columns={columns}
            disableColumnMenu
            disableColumnReorder
            key={'alarm-summary-table'}
            className={'alarm__summary__table'}
            rowHeight={36}
            slots={{
              footer: () => null,
              noRowsOverlay: NoDataComponentAlarms,
              columnSortedAscendingIcon: SortedAscendingIcon,
              columnSortedDescendingIcon: SortedDescendingIcon,

            }}
            getRowClassName={(): string => {
              return 'alarm__summary__table-dashboard-row';
            }}
            sortingMode="server"
            onSortModelChange={handleSortModelChange}

            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            onRowClick={(rowData: GridRowParams<GridValidRowModel>, e) => {
              //navigate('/alarms?alarmId=' +rowData.row );
              (handleSelectedAlarm(e, rowData.row))
            }
            }
          />
        )}
      </div>

    </div>
  );
};

export default AlarmSummary;
