import PropTypes from 'prop-types';
import * as React from 'react';
import './CustomUpload.css';
import Button from '@mui/material/Button/Button';
import { styled } from '@mui/material/styles';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';

const CustomUploadFieldType = {
  inputFieldId: PropTypes.string.isRequired,
  isRequired: PropTypes.bool.isRequired,
  inputName: PropTypes.string,
  className: PropTypes.string,
  inputPlaceHolder: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.oneOf([null]),
  ]),
  isDisabled: PropTypes.bool,
  handleInputChange: PropTypes.func,
  showDialog: PropTypes.func,
  fileName: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.oneOf([null]),
  ]),
};

const VisuallyHiddenInput = styled('input')({
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

  
export type CustomUploadFieldPropTypes = PropTypes.InferProps<
  typeof CustomUploadFieldType
>;

const CustomUpload: React.FC<CustomUploadFieldPropTypes> = (props) => {
  const {
    inputFieldId,
    isRequired,
    inputName,
    className,
    inputPlaceHolder,
    isDisabled,
    fileName,
    handleInputChange,
    showDialog
  } = props

  const handleClick = () => {
    if (fileName && fileName.trim() !== '') {
      showDialog?.();
    }
  };

  return (
    <div className="rms__upload__wrap">
      <div className={'rms__custom__upload__field__container ' + (isDisabled ? 'disabled' : '')}>
        <div className="rms__upload__left__wrap">

          <div className="rms__upload__text" style={fileName ? { cursor: 'pointer' } : { cursor: 'default' }} onClick={() => !isDisabled && handleClick?.()}>{fileName ? fileName : 'No file selected'}
          </div>
          {
            (fileName == null || fileName == undefined || fileName == '') ?
              <Button
                id='upload_button'
                className={'rms__custom__upload__field ' + className}
                component="label"
                variant="contained"
                endIcon={<FileUploadOutlinedIcon style={{ color: 'var(--secondary-blue-color)' }} />}
              >
                {(fileName == null || fileName == undefined || fileName == '') &&
                  <VisuallyHiddenInput
                    type={'file'}
                    id={inputFieldId}
                    accept={className === 'bulk_charger_upload' ? '.xlsx, .csv' : 'image/*'}
                    name={inputName || undefined}
                    required={isRequired}
                    placeholder={inputPlaceHolder || undefined}
                    disabled={isDisabled || undefined}
                    onChange={(event) => {
                      handleInputChange?.(event?.target?.files);
                    }}
                  />}
              </Button> :
              <Button
                id='upload_button'
                // component="label"
                variant="contained"
                disabled = {isDisabled || false}
                onClick={() => {
                  if (fileName != null && fileName != undefined && fileName != '') handleInputChange?.(null);
                }}
                endIcon={ !isDisabled ? <CloseOutlinedIcon style={{ color: 'var(--secondary-blue-color)' }} />  : <></>}
              >
              </Button>
          }
        </div>
      </div>
    </div>
  )
}

export default CustomUpload;