import { cloneDeep } from 'lodash';
import React, { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import {
  DashboardLoader,
  downloadFile,
  FilterProp,
  formatRelativeTimeForTable,
  renderHeaderActionButtons,
  TableDashboard,
  useAppDispatch,
  useAppSelector,
} from '../../globalUtils/globalExports';
import {
  getReportReducer,
  fetchExceptionLogsData,
  setReportListTableProps,
  reportTypes,
  getFilterSelectedValue,
  getReferenceDataReducer,
  getFilterReducer,
  setFilterData,
  getCurrentFiltersValues,
  exportExceptionLogs,
} from '../../rmsReduxStore/reduxExports';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import './reports.css';
import { Id, toast } from 'react-toastify';
import { NoDataComponentExceptionLogs } from '../../globalUtils/TableDashboard/TableComponents';
import access from '../../auth/service/AccessControl';

const ExceptionLogsDashboard = memo(function ExceptionLogsDashboard() {
  const dispatch = useAppDispatch();
  const { exceptionLogsColumnHeaders, exceptionLogsData, exceptionLogsCount, reportLoader, exceptionLogsListTableProps } = useAppSelector(getReportReducer);
  const { chargerVisibleIdReferenceData,exceptionLogsSeverityReferenceData } = useAppSelector(getReferenceDataReducer);
  const { screenFilters } = useAppSelector(getFilterReducer)
  const [exportAnchorEl, setExportAnchorEl] = useState(null);
  const [exportingFile, setExportingFile] = useState(false);
  const [dashboardLoader, setDashboardLoader] = useState(false)
  const exceptionLogsListTablePropsRefs = useRef(exceptionLogsListTableProps)

  useEffect(() => {
    exceptionLogsListTablePropsRefs.current = exceptionLogsListTableProps;
  }, [exceptionLogsListTableProps])

  // TODO: Handles global search box
  const onChangeOfSearchText = useCallback(async (searchTerm) => {
    if (exceptionLogsListTablePropsRefs) {
      await layoutCallback(1, exceptionLogsListTablePropsRefs?.current?.pageSize, exceptionLogsListTablePropsRefs?.current?.view, exceptionLogsListTablePropsRefs?.current?.sortBy, exceptionLogsListTablePropsRefs?.current?.order, null, searchTerm)
    }
  }, [exceptionLogsListTablePropsRefs, exceptionLogsColumnHeaders, exceptionLogsData]);

  const searchBoxConfiguration = useMemo(() => {
    return {
      searchFieldId: 'exception-logs-search-box',
      searchFieldName: 'exception-logs-search-box',
      handleSearch: onChangeOfSearchText,
    };
  }, [onChangeOfSearchText]);

  // TODO: Adds action btns in header and their callbacks
  const headerActionBtns = renderHeaderActionButtons({
    actionBtns:
      [
        {
          id: 'export_csv_btn',
          isAccessible: access?.reports?.exceptionLogs?.export(),
          headerActionBtnClick: (event): void => {
            event?.stopPropagation()
            setExportAnchorEl(event.currentTarget);
          }
        }
      ]
  })

  // TODO: Table Layout Callbacks
  const mobileViewConfiguration = useMemo(() => {
    return {
      headerDataConfig: {
        headerLeftDataConfig: ['severity', 'visibleId'],
        headerRightDataConfig: {
          actionIconsComponent: false,
          statusIconComponent: false,
        },
      },
      contentDataConfig: [
        [],
      ],
    };
  }, []);

  const getTableRows = useCallback(() => {
    if (!exceptionLogsData || !exceptionLogsColumnHeaders) {
      return [];
    }
    const data = cloneDeep(exceptionLogsData)
    exceptionLogsColumnHeaders?.forEach((column) => {
      const { key, dataType, unit } = column;
      if (key && (dataType || unit)) {

        data?.forEach((log) => {
          if (log?.[key] && dataType === 'Date') {
            log[key] = formatRelativeTimeForTable(log[key]);
          }
          if (log?.[key] !== null && log?.[key] !== undefined && unit) {
            log[key] = `${log[key]} ${unit}`;
          }
        })
      }
    })
    return data || [];
  }, [exceptionLogsData, exceptionLogsColumnHeaders]);

  const exceptionLogsColumns = exceptionLogsColumnHeaders?.map(item => {
    return ({ field: item?.key, description: item?.description, flex: 1, headerName: item?.visibleName, minWidth: (item?.visibleName?.length > 25) ? 200 : 150,pinnable:false})
  })

  const layoutCallback = useCallback(async (pageNumber: number, pageSize: number, view: string, sortField: string, sortOrder: string, filterValue, searchTerm) => {
    const tableProps: IListTableProps = cloneDeep(exceptionLogsListTablePropsRefs.current)
    await dispatch(fetchExceptionLogsData({
      sortBy: sortField || '',
      order: sortOrder || 'ascending',
      pageSize,
      pageNumber,
      filters: filterValue ? filterValue : getCurrentFiltersValues('exceptionLogsReport',true),
      searchTerm: searchTerm != null && searchTerm != undefined ? searchTerm : exceptionLogsListTablePropsRefs?.current?.searchTerm
    }));
    if (tableProps) {
      tableProps.view = view || 'list'
      tableProps.sortBy = sortField || ''
      tableProps.order = sortOrder || 'ascending';
      tableProps.pageNumber = pageNumber;
      tableProps.pageSize = pageSize;
      if (searchTerm != null && searchTerm != undefined) {
        tableProps.searchTerm = searchTerm;
      }
    }
    await dispatch(setReportListTableProps(tableProps, 'exceptionLogs'))
  }, [exceptionLogsListTablePropsRefs, dispatch]);

  // TODO: Redux state Clean up
  const exceptionLogsCleanUpStates = useCallback(() => {
    const action = {
      type: reportTypes.CLEAR_EXCEPTION_LOGS_LIST_DATA,
    }
    dispatch(action);
  }, [])

  useEffect(() => {
    window.addEventListener('beforeunload', exceptionLogsCleanUpStates);
    return (): void => {
      exceptionLogsCleanUpStates()
      window.removeEventListener('beforeunload', exceptionLogsCleanUpStates);
    }
  }, [])

  // TODO: Export Handlers
  const handleClose = (): void => {
    setExportAnchorEl(null);
  };

  const fileDownloadSuccessToast = useCallback((): Id => {
    setExportingFile(false);
    return toast.success('File downloaded.');
  }, []);

  const fileDownloadFailureToast = useCallback((): Id => {
    setExportingFile(false);
    return toast.warn('Something went wrong. Please export again.');
  }, []);

  const renderExportMenuItem = (fileType: 'csv' | 'xlsx', label: string): JSX.Element => (
    <MenuItem key={fileType}>
      <div className='export__btn-option' onClick={(e) => {
        e?.stopPropagation();
        onExportBtnClick(fileType);
      }}>
        {label}
      </div>
    </MenuItem>
  );

  const dateRangeExceededErrorToast = (message): Id => toast.error(message);

  const dateFilterRangeCheck = (daysLimit) => {
    const filterSetOnValue = getFilterSelectedValue('logInterval', 'exceptionLogsReport', false);
    const startDate = filterSetOnValue?.[0] ? new Date(filterSetOnValue?.[0]) : null;
    const endDate = filterSetOnValue?.[1] ? new Date(filterSetOnValue?.[1]) : null;
    if (startDate && endDate) {
      const differenceInMilliseconds = endDate.getTime() - startDate.getTime();
      const differenceInDays = differenceInMilliseconds / (1000 * 60 * 60 * 24);
      if (differenceInDays < daysLimit) {
        return true;
      }
    }
    return false;
  }

  const onExportBtnClick = useCallback(async (fileType: 'csv' | 'xlsx'): Promise<void> => {
    if (!dateFilterRangeCheck(30)) {
      const filterSetOnValue = getFilterSelectedValue('logInterval', 'exceptionLogsReport', false);
      const startDate = filterSetOnValue?.[0] ? filterSetOnValue?.[0] : null;
      const endDate = filterSetOnValue?.[1] ? filterSetOnValue?.[1] : null;
      if (startDate && endDate) dateRangeExceededErrorToast('Date range exceeded more than 30 days!!')
      else dateRangeExceededErrorToast('Please select date range of 30 days!!')
      return;
    }

    if (exportingFile) {
      // If already exporting, prevent another export
      return;
    }
    try {
      setDashboardLoader(true)
      const response = await dispatch(exportExceptionLogs({
        fileType: fileType,
        filters: getCurrentFiltersValues('exceptionLogsReport',true)
      }));
      if (response?.status === 202 || response?.status === 200) {
        setDashboardLoader(false)
        downloadFile({ url: response?.data?.url });
        fileDownloadSuccessToast()
      } else {
        setDashboardLoader(false)
        fileDownloadFailureToast();
        setExportAnchorEl(null);
      }
    } finally {
      setDashboardLoader(false)
      // Ensure that exporting status is updated even if there's an error
      setExportingFile(false);
      setExportAnchorEl(null);
    }
  }, [exportingFile, fileDownloadSuccessToast, fileDownloadFailureToast])

  // TODO: Filter side pop up 
  const handleFilterDataChange = useCallback(async (val, filterKey, isGlobal) => {
    const res = await setFilterData(val, filterKey, 'exceptionLogsReport', 'SET', isGlobal ? isGlobal : false,true)(dispatch)
    if (res?.message === 'Action dispatched successfully') {
      if (exceptionLogsListTablePropsRefs?.current) {
        await layoutCallback(1, exceptionLogsListTablePropsRefs?.current?.pageSize, exceptionLogsListTablePropsRefs?.current?.view, exceptionLogsListTablePropsRefs?.current?.sortBy, exceptionLogsListTablePropsRefs?.current?.order, res?.filters, exceptionLogsListTablePropsRefs?.current?.searchTerm)
      }
    }
  }, [exceptionLogsData, dispatch],)

  //TODO: Include Date Intervals for dateFilter
  const exceptionLogsFilterProps: FilterProp[] = useMemo(() => {
    const filterSetOnValue = getFilterSelectedValue('logInterval', 'exceptionLogsReport', false);

    return [
      {
        filterLabel: 'Chargers',
        filterType: 'dropdown',
        filterId: 'chargerId',
        filterDropdownProps: {
          selectValue: getFilterSelectedValue('chargerId', 'exceptionLogsReport', false),
          selectOptions: chargerVisibleIdReferenceData ?? [],
          handleSelectOptionChange: (val): void => {
            handleFilterDataChange(val, 'chargerId', false)
          },
          selectDropDownId: 'chargerId-filter-dropdown',
          isMultiSelect: true
        },
      },
      {
        filterLabel: 'Log Severity',
        filterType: 'dropdown',
        filterId: 'severity',
        filterDropdownProps: {
          selectValue: getFilterSelectedValue('logSeverity', 'exceptionLogsReport', false),
          selectOptions: exceptionLogsSeverityReferenceData ?? [],
          handleSelectOptionChange: (val): void => {
            handleFilterDataChange(val, 'logSeverity', false)
          },
          selectDropDownId: 'logSeverity-filter-dropdown',
          isMultiSelect: true
        },
      },
      {
        filterLabel: 'Interval',
        filterType: 'dateFilter',
        filterId: 'exceptionlogInterval',
        dateFilterProps: {
          datePickerId: 'exception__logs_interval',
          datePickerClassName: 'exception__logs_interval',
          selectsRange: true,
          startDate: filterSetOnValue?.[0] ? new Date(filterSetOnValue?.[0]) : null,
          endDate: filterSetOnValue?.[1] ? new Date(new Date(filterSetOnValue?.[1]).getTime()) : null,    
          onChange: (val): void => {
            handleFilterDataChange(val, 'logInterval', false)
          },
          // includeDateIntervals: includeDateIntervals
        }
      },
    ];
  }, [chargerVisibleIdReferenceData,exceptionLogsSeverityReferenceData, screenFilters]);

  return (
    <>

      <DashboardLoader showLoader={dashboardLoader} />
      <TableDashboard
        tableId="exception__logs__table"
        tableClassName="exception__logs__table"
        header={'Exception Logs'}
        searchBoxIncluded={true}
        searchBoxConfiguration={searchBoxConfiguration}
        gridColumns={exceptionLogsColumns}
        tableRows={getTableRows()}
        mobileViewConfiguration={mobileViewConfiguration}
        layoutView={'list'}
        headerActionBtns={headerActionBtns}
        listLayoutCallBack={layoutCallback}
        totalCount={exceptionLogsCount}
        showSkeleton={!exceptionLogsData}
        totalCountText={'exception logs'}
        showLoader={reportLoader}
        customNoDataComponent={NoDataComponentExceptionLogs}
        pageNumber={exceptionLogsListTableProps?.pageNumber}
        tablePageSize={exceptionLogsListTableProps?.pageSize}
        filterProps={exceptionLogsFilterProps}
        handleFilterDataChange={handleFilterDataChange}
        excludeGlobalFilters={true}
        referenceDataCallbacksFilters={['chargerVisibleId','logSeverity']}
      />
      <Menu
        id="export-option-dropdown"
        anchorEl={exportAnchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        className='export__option__dropdown'
        sx={{ top: '30px' }}
        open={Boolean(exportAnchorEl)}
        onClose={handleClose}
      >
        {renderExportMenuItem('csv', 'To CSV')}
        {renderExportMenuItem('xlsx', 'To Excel')}
      </Menu>
    </>
  );
})

export default ExceptionLogsDashboard;