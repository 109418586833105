/* eslint-disable @typescript-eslint/explicit-function-return-type */

export const dropDownSelectStyles = {
  control: (provided, state) => ({
    ...provided,
    color: '#000000',
    fontSize: '13px',
    fontFamily: 'var(--font-family)',
    width: '100% !important',
    border: '1px solid var(--border-color)',
    borderRadius: '4px',
    minHeight: '32px',
    maxHeight:'34px',
    alignItems: 'center',
    // This line disable the blue border
    boxShadow: (state.isFocused) ? '1px solid var(--secondary-blue-color)' : '1px  solid var(--secondary-blue-color)',
    '&:hover': {
      border: (state.isFocused) ? '1px solid var(--secondary-blue-color)' : '1px solid var(--secondary-blue-color)'
    }

  }),
  container: (provided) => ({
    ...provided,
    border: 'none',
    borderRadius: '4px',
    width: '100% !important',
    fontSize: '13px',
    fontFamily: 'var(--font-family)'
  }),
  indicatorSeparator: (provided) => ({
    ...provided,
    width: '0px'

  }),
  menuPortal: base => ({ ...base, zIndex: '9999999' }),
  reactValue: (provided) => ({
    ...provided,
    width: '68%',
    color: '#757575',
    lineHeight: '22px',
    fontWeight: '400',
    fontFamily: 'var(--font-family)'
  }),
  menu: (provided) => ({
    ...provided,
    borderRadius: '4px',
    marginTop: '1px',
    width: '100% !important',
    zIndex: '9999999'
  }),
  indicatorsContainer: (provided) => ({
    ...provided,
    borderRadius: '0px',
    width: '30px',
    height: '32px',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer'

  }),
  multiValueLabel: (provided) => ({
    ...provided,
    padding: '2px',
    whiteSpace: 'normal',
    lineHeight: '22px'
  }),
  multiValueRemove: (base) => ({
    ...base,
    color: '#000',
    backgroundColor: '#fad192',
    borderRadius: 0,
    '&:hover': {
      backgroundColor: '#fad192',
      color: '#000'
    }
  }),

  
  indicatorContainer: (provided) => ({
    ...provided,
    padding: '0px'
  }),
  placeholder: (provided) => ({
    ...provided,
    color: '#757575',
    width: '100%',
    fontWeight: '400',
    fontFamily: 'var(--font-family)'
  }),

  valueContainer: (provided, state) => ({
    ...provided,
    cursor: state.isDisabled ? 'auto' : 'pointer',
    position: 'none',
    maxWidth: '90%',
    borderRadius: '0px',
    padding: '0px 8px'
  }),

  option: (styles, { isDisabled, isFocused, isSelected }) => {
    const color = 'var(--primary-white-color)'
    return {
      ...styles,
      backgroundColor: isDisabled
        ? null
        : isSelected
          ? color
          : isFocused
            ? color
            : null,
      ':active': {
        ...styles[':active'],
        backgroundColor: color
      },
      color: 'var(--primary-text-color)',
      cursor: 'pointer',
      width: '100% !important',
      overflow: 'hidden',
      borderRadius: '0px',
      fontWeight: '300'
    }
  },
  menuList: (provided) => ({
    ...provided,
    paddingBottom: '0px',
    paddingTop: '0px',
    borderRadius: '0px',
    boxShadow: '0px 1px 5px #ddd',
    overflowX: 'hidden',
    width: '100% !important',
    fontWeight: '400',
    fontFamily: 'var(--font-family)'
  })
}
