
import { Navigate } from 'react-router-dom'
import LoginScreen from '../auth/login/LoginScreen'
import AlarmManagementDashboard from '../components/alarms/AlarmsDashboard'
import ChargerPanel from '../components/chargers/ChargerPanel/ChargerPanelDashboard'
import ChargingStationDashboard from '../components/chargingStation/ChargingStationDashboard'
import {
  AddCharger, ManageChargersDashboard, AlarmPanelDashboard,
  AddParameter, EditParameter, ViewParameter,
  UserForm, RoleForm, CustomerForm, RoleManagementDashboard, UserManagementDashboard,
  SetParametersDashboard,
  OrgManagementDashboard, OrgForm, EditHistory,ChargingStationEditHistory,
  ChargerLogsDashboard, ChargerHistoryDashboard, CustomerManagementDashboard, AddBulkCharger,ChargingStationForm,ExceptionLogsDashboard
} from '../components/componentExports'
import Dashboard from '../components/dashboard/Dashboard'
import CreateBroadcastMessage from '../components/chargers/BroadcastMessage/CreateBroadcastMessage'
import EditBroadcastMessage from '../components/chargers/BroadcastMessage/EditBroadcastMessage'
import React from 'react';
import access from '../auth/service/AccessControl'
interface RouteProp {
  path: string;
  component: JSX.Element;
  isEnabled: boolean;
  isSideBarEnabled: boolean;
  isNavbarBarEnabled: boolean;
  searchQuery?: string;
  isAccessible?: boolean;
}

export const unAuthenticatedRoutes = (): RouteProp[] => {
  return [
    {
      path: '/',
      component: <LoginScreen key="login" showOtpScreen={false} />,
      isEnabled: true,
      isSideBarEnabled: false,
      isNavbarBarEnabled: false,
      isAccessible: true
    },
    {
      path: '/otp-validation',
      component: <LoginScreen key="otp-validation" showOtpScreen={true} />,
      isEnabled: true,
      isSideBarEnabled: false,
      isNavbarBarEnabled: false,
      isAccessible: true
    },
    {
      path: '*',
      component: <Navigate to="/" replace />,
      isEnabled: true,
      isSideBarEnabled: false,
      isNavbarBarEnabled: false,
      isAccessible: true
    },
  ]
}

export const authenticatedRoutes = (): RouteProp[] => {
  return [
    {
      path: '/dashboard',
      component: <Dashboard />,
      isEnabled: true,
      isSideBarEnabled: true,
      isNavbarBarEnabled: true,
      isAccessible: access?.dashboard?.view()
    },
    {
      path: '/',
      component: <Navigate to="/dashboard" replace />,
      isEnabled: true,
      isSideBarEnabled: false,
      isNavbarBarEnabled: false,
      isAccessible: access?.dashboard?.view()
    },
    {
      path: '/alarms',
      component: <AlarmManagementDashboard />,
      isEnabled: true,
      isSideBarEnabled: true,
      isNavbarBarEnabled: true,
      isAccessible: access?.alarms?.view()
    },
    {
      path: '/customers',
      component: <CustomerManagementDashboard />,
      isEnabled: true,
      isSideBarEnabled: true,
      isNavbarBarEnabled: true,
      isAccessible: access?.customers?.view()
    },

    {
      path: '/manage-chargers',
      component: <ManageChargersDashboard />,
      isEnabled: true,
      isSideBarEnabled: true,
      isNavbarBarEnabled: true,
      isAccessible: access?.chargers?.view()
    },

    {
      path: '/charger',
      component: <ChargerPanel />,
      isEnabled: true,
      isSideBarEnabled: true,
      isNavbarBarEnabled: true,
      searchQuery: 'chargerId',
      isAccessible: access?.chargers?.view()
    },

    {
      path: '/add-charger',
      component: <AddCharger />,
      isEnabled: true,
      isSideBarEnabled: true,
      isNavbarBarEnabled: true,
      searchQuery: 'chargerId',
      isAccessible: access?.chargers?.add()
    },

    {
      path: '/add-bulk-charger',
      component: <AddBulkCharger />,
      isEnabled: true,
      isSideBarEnabled: true,
      isNavbarBarEnabled: true,
      searchQuery: 'chargerId',
      isAccessible: access?.chargers?.add()
    },

    {
      path: '/charger-detail',
      component: <AddCharger />,
      isEnabled: true,
      isSideBarEnabled: true,
      isNavbarBarEnabled: true,
      searchQuery: 'chargerId',
      isAccessible: access?.chargers?.edit() || access?.chargers?.view()
    },

    {
      path: '/customer',
      component: <CustomerForm />,
      isEnabled: true,
      isSideBarEnabled: true,
      isNavbarBarEnabled: true,
      isAccessible: access?.customers?.add() || access?.customers?.edit() || access?.customers?.view()
    },
    {
      path: '/edit-history',
      component: <EditHistory />,
      isEnabled: true,
      isSideBarEnabled: true,
      isNavbarBarEnabled: true,
      isAccessible: access?.customers?.edit()
    },
    {
      path: '/station-edit-history',
      component: <ChargingStationEditHistory />,
      isEnabled: true,
      isSideBarEnabled: true,
      isNavbarBarEnabled: true,
      isAccessible: access?.chargingStation?.edit()
    },

    {
      path: '/alarm',
      component: <AlarmPanelDashboard />,
      isEnabled: true,
      isSideBarEnabled: true,
      isNavbarBarEnabled: true,
      searchQuery: 'alarmId',
      isAccessible: access?.alarms?.view()
    },

    {
      path: '/add-parameter',
      component: <AddParameter />,
      isEnabled: true,
      isSideBarEnabled: true,
      isNavbarBarEnabled: true,
      isAccessible: access?.updateSetting?.add()
      // searchQuery: 'chargerId'
    },
    {
      path: '/edit-parameter',
      component: <EditParameter />,
      isEnabled: true,
      isSideBarEnabled: true,
      isNavbarBarEnabled: true,
      isAccessible: access?.updateSetting?.edit()
      // searchQuery: 'chargerId'
    },
    {
      path: '/view-parameter',
      component: <ViewParameter />,
      isEnabled: true,
      isSideBarEnabled: true,
      isNavbarBarEnabled: true,
      isAccessible: access?.updateSetting?.view()
      // searchQuery: 'chargerId'
    },

    {
      path: '/update-settings',
      component: <SetParametersDashboard />,
      isEnabled: true,
      isSideBarEnabled: true,
      isNavbarBarEnabled: true,
      isAccessible: access?.updateSetting?.view()
    },
    // {
    //   path: '/broadcast-message',
    //   component: <BroadcastMessageDashboard />,
    //   isEnabled: true,
    //   isSideBarEnabled: true,
    //   isNavbarBarEnabled: true
    // },
    {
      path: '/broadcast-message/create',
      component: <CreateBroadcastMessage />,
      isEnabled: true,
      isSideBarEnabled: true,
      isNavbarBarEnabled: true
    },
    // {
    //   path: '/broadcast-message/detail',
    //   component: <BroadcastMessageDetail />,
    //   isEnabled: true,
    //   isSideBarEnabled: true,
    //   isNavbarBarEnabled: true
    // },
    {
      path: '/broadcast-message/edit',
      component: <EditBroadcastMessage />,
      isEnabled: true,
      isSideBarEnabled: true,
      isNavbarBarEnabled: true
    },
    {
      path: '/charging-stations',
      component: <ChargingStationDashboard />,
      isEnabled: true,
      isSideBarEnabled: true,
      isNavbarBarEnabled: true,
      isAccessible: access?.chargingStation?.view()
    },
    {
      path: '/station',
      component: <ChargingStationForm />,
      isEnabled: true,
      isSideBarEnabled: true,
      isNavbarBarEnabled: true,
      isAccessible: access?.chargers?.add()
    },
    {
      path: '/manage-users',
      component: <UserManagementDashboard />,
      isEnabled: true,
      isSideBarEnabled: true,
      isNavbarBarEnabled: true,
      isAccessible: access?.users?.view()
    },
    {
      path: '/user',
      component: <UserForm />,
      isEnabled: true,
      isSideBarEnabled: true,
      isNavbarBarEnabled: true,
      searchQuery: 'userId',
      isAccessible: access?.users?.add() || access?.users?.edit() || access?.users?.view()
    },
    {
      path: '/roles',
      component: <RoleManagementDashboard />,
      isEnabled: true,
      isSideBarEnabled: true,
      isNavbarBarEnabled: true,
      isAccessible: access?.roles?.view()
    },
    {
      path: '/role',
      component: <RoleForm />,
      isEnabled: true,
      isSideBarEnabled: true,
      isNavbarBarEnabled: true,
      searchQuery: 'roleId',
      isAccessible: access?.roles?.view()
    },
    {
      path: '/manage-org',
      component: <OrgManagementDashboard />,
      isEnabled: true,
      isSideBarEnabled: true,
      isNavbarBarEnabled: true,
      isAccessible: access?.organisation?.view()
    },
    {
      path: '/org',
      component: <OrgForm />,
      isEnabled: true,
      isSideBarEnabled: true,
      isNavbarBarEnabled: true,
      searchQuery: 'orgId',
      isAccessible: access?.organisation?.add() || access?.organisation?.view() || access?.organisation?.edit()
    },
    {
      path: '/chargingHistory',
      component: <ChargerHistoryDashboard />,
      isEnabled: true,
      isSideBarEnabled: true,
      isNavbarBarEnabled: true,
      isAccessible: access?.reports?.chargingHistory?.view()
    },
    {
      path: '/chargerLogs',
      component: <ChargerLogsDashboard />,
      isEnabled: true,
      isSideBarEnabled: true,
      isNavbarBarEnabled: true,
      isAccessible: access?.reports?.chargerLog?.status?.view() || access?.reports?.chargerLog?.setting?.view() || access?.reports?.chargerLog?.systemInfo?.view()
    },
    {
      path: '/exceptionLogs',
      component: <ExceptionLogsDashboard />,
      isEnabled: true,
      isSideBarEnabled: true,
      isNavbarBarEnabled: true,
      isAccessible: access?.reports?.exceptionLogs?.view() 
    },
    {
      path: '*',
      component: <Navigate to="/dashboard" replace />,
      isEnabled: true,
      isSideBarEnabled: false,
      isNavbarBarEnabled: false,
      isAccessible: true
    },
  ]
}

export default { authenticatedRoutes, unAuthenticatedRoutes }
