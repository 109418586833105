import { type GridRenderCellParams, GridValidRowModel } from '@mui/x-data-grid-pro';
import { cloneDeep } from 'lodash';
import React, { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import {
  CustomDialog,
  CustomTooltipWithLabel,
  downloadFile,
  renderHeaderActionButtons,
  TableDashboard,
  useAppDispatch,
  useAppSelector,
} from '../../globalUtils/globalExports';
import {
  // deleteOrg,     
  exportToCsvForOrgList,
  getUserReducer,
  fetchOrgsData,
  setTableProps,
  setRedirectedStateForUserFilters,
  deleteOrg,
  setTableColumnPrefrences,
  getGlobalReducer,
  userTypes
} from '../../rmsReduxStore/reduxExports';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import './users.css';
import { Id, toast } from 'react-toastify';
import { NoDataComponentOrgs } from '../../globalUtils/TableDashboard/TableComponents';
import { ActionIcons } from '../../globalUtils/globalIcons';
import { useNavigate } from 'react-router-dom';
import { formatNumberWithCommas } from '../../globalUtils/globalHooks';
import access from '../../auth/service/AccessControl';

const OrgManagementDashboard = memo(function RoleManagementDashboard() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { orgs, orgsCount, orgListTableProps, loader } = useAppSelector(getUserReducer);
  const {screenTableColumnPrefrences} = useAppSelector(getGlobalReducer)
  const [exportAnchorEl, setExportAnchorEl] = useState(null);
  const [exportingFile, setExportingFile] = useState(false);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [selectedOrgId, setSelectedOrgId] = useState<string | null>(null);
  const orgListTablePropsRef = useRef(orgListTableProps);

  useEffect(() => {
    orgListTablePropsRef.current = orgListTableProps;
  }, [orgListTableProps]);

  // TODO: Handles global search box
  const onChangeOfSearchText = useCallback(async (searchTerm) => {
    if (orgListTablePropsRef?.current) {
      await layoutCallback(1, orgListTablePropsRef?.current?.pageSize, orgListTablePropsRef?.current?.view, orgListTablePropsRef?.current?.sortBy, orgListTablePropsRef?.current?.order, searchTerm)
    }
  }, [orgListTablePropsRef?.current, orgs]);

  const searchBoxConfiguration = useMemo(() => {
    return {
      searchFieldId: 'manage-org-search-box',
      searchFieldName: 'manage-org-search-box',
      handleSearch: onChangeOfSearchText,
    };
  }, [onChangeOfSearchText]);

  const handleNameClick = async (event, rowData) => {
    navigate('/org', { state: { orgId: rowData.id, mode: 'View', rowData: rowData } });
  };

  // TODO: Delete Handlers
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const orgDeletionSuccessToast = useCallback((): Id => {
    return toast.success('Organisation deleted.');
  }, []);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const orgDeletionFailureToast = useCallback((message: string): Id => {
    return toast.warn(message ?? 'Something went wrong.');
  }, []);

  const onDeleteIconClick = useCallback(async (id: string): Promise<void> => {
    setSelectedOrgId(id);
    setIsDeleteDialogOpen(true);
  }, []);

  const handleDeleteDialogClose = useCallback(() => {
    setIsDeleteDialogOpen(false);
    setSelectedOrgId(null);
  }, []);

  const handleDeleteOrg = useCallback(async () => {
    handleDeleteDialogClose();
    const { data, status } = await dispatch(deleteOrg({
      orgId: selectedOrgId
    }));
    if (status === 200 && data?.message === 'Success') {
      orgDeletionSuccessToast()
      layoutCallback(1, orgListTablePropsRef?.current?.pageSize, orgListTablePropsRef?.current?.view, orgListTablePropsRef?.current?.sortBy, orgListTablePropsRef?.current?.order, null);
    } else {
      orgDeletionFailureToast(data?.message)
    }
    handleDeleteDialogClose();
  }, [dispatch, selectedOrgId, handleDeleteDialogClose, orgListTablePropsRef?.current]);

  // TODO: Adds action btns in header and their callbacks
  const headerActionBtns = renderHeaderActionButtons({
    actionBtns:
      [
        {
          id: 'add_new_btn',
          isAccessible: access?.organisation?.add(),
          headerActionBtnClick: (): void => {
            navigate('/org', { state: { mode: 'Add' } })
          }
        },
        {
          id: 'export_csv_btn',
          isAccessible: access?.organisation?.export(),
          headerActionBtnClick: (event): void => {
            setExportAnchorEl(event.currentTarget);
          }
        }
      ]
  })

  // TODO: Edit Button click
  const onEditIconClick = (rowData) => {
    navigate('/org', { state: { orgId: rowData.id, mode: 'Edit', rowData: rowData } })
  }

  // White Pencil Icon ia not actionable it's just added to make the icon alignment better on UI.
  const actionIconsList = useMemo(() => {
    return [
    {icon: 'pencilWhiteIcon',isAccessible: access?.organisation?.edit()},
    { icon: 'pencilIcon', isAccessible: access?.organisation?.edit(), action: (_, rowData) => onEditIconClick(rowData) },
    { icon: 'deleteIcon', isAccessible: access?.organisation?.delete(), action: (_, rowData) => onDeleteIconClick(rowData?.id) }];
  }, [onDeleteIconClick]);


  // TODO: Table Layout Callbacks
  const mobileViewConfiguration = useMemo(() => {
    return {
      headerDataConfig: {
        headerLeftDataConfig: ['orgName', 'type'],
        headerRightDataConfig: {
          actionIconsComponent: false,
          statusIconComponent: true,
        },
      },
      statusIconKey: 'status',
      contentDataConfig: [
        [
          {
            key: 'chargerCount',
            iconClassName: 'chargers__icon',
          },
          {
            key: 'userCount',
            iconClassName: 'customer__management__icon',
          },
        ],
      ],
    };
  }, []);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const getTableRows = useCallback((): IManageOrg[] => {
    return orgs !== null && orgs?.length > 0 ? orgs : [];
  }, [orgs]);

  const orgColumns = useMemo(() => {
    return [
      {
        field: 'orgName',
        // flex: 1,
        maxWidth: 500,
        headerName: 'Name',
        minWidth: 150,
        hideable:false,
        renderCell: (params: GridRenderCellParams<GridValidRowModel>): JSX.Element => {
          const rowData = params?.row;
          return (
            <div
              className="individual__org__text"
              onClick={(e) => {
                e?.stopPropagation()
                handleNameClick(e, params.row);
              }
              }
            >
              {rowData?.orgName}
            </div>
          );
        },
      },
      {
        field: 'type',
        headerName: 'Type',
        minWidth: 150,
        editable: false,
        flex: 1,
      },
      {
        field: 'chargerCount',
        headerName: 'Access to Chargers',
        flex: 1,
        sortable: true,
        minWidth: 100,
        headerAlign: 'right',
        align: 'right',
        renderCell: (params: GridRenderCellParams<GridValidRowModel>): JSX.Element => {
          const rowData = params?.row;
          return (
            <div
              className="individual__org__text"
              onClick={(e) => {
                e?.stopPropagation()
                handleNameClick(e, params.row);
              }
              }
            >
              {formatNumberWithCommas(rowData?.chargerCount)}
            </div>
          );
        },
      },
      {
        field: 'userCount',
        headerName: 'Users',
        flex: 0.5,
        sortable: true,
        minWidth: 50,
        headerAlign: 'right',
        align: 'right',
        renderCell: (params: GridRenderCellParams<GridValidRowModel>): JSX.Element => {
          const rowData = params?.row;
          return (
            <div
              className="individual__org__text"
              onClick={(e) => {
                e?.stopPropagation()
                setRedirectedStateForUserFilters(true, rowData?.id)(dispatch).then((res) => {
                  if (res === 'Action dispatched successfully') {
                    navigate('/manage-users');
                  }
                })
              }
              }
            >
              {formatNumberWithCommas(rowData?.userCount)}
            </div>
          );
        },
      },
      {
        field: 'notes',
        headerName: 'Notes',
        minWidth: 100,
        flex: 1,
        headerAlign: 'left',
        sortable: false,
        renderCell: (params: GridRenderCellParams<GridValidRowModel>): JSX.Element => {
          const rowData = params?.row;
          return <CustomTooltipWithLabel labelId='org__dashboard-notes' label={rowData?.notes} />
        },
      },
      {
        field: 'Action',
        headerName: 'Actions',
        flex: 1,
        sortable: false,
        minWidth: 100,
        renderCell: (params: GridRenderCellParams<GridValidRowModel, Date>): JSX.Element => {
          const rowData = params?.row;
          const actionList = rowData?.type === 'Customer' ? actionIconsList?.filter((item) => item.icon !== 'pencilIcon') : actionIconsList?.filter((item) => item.icon !== 'pencilWhiteIcon')
          return (
            <div className="charging__station__action__icons__wrap">
              <ActionIcons actionIconsList={actionList} data={rowData} />
            </div>
          );
        },
      },
    ];
  }, []);

  const layoutCallback = useCallback(async (pageNumber: number, pageSize: number, view: string, sortField: string, sortOrder: string, searchTerm) => {
    const tableProps: IListTableProps = cloneDeep(orgListTablePropsRef?.current)
    await dispatch(fetchOrgsData({
      sortBy: sortField || orgListTablePropsRef?.current?.sortBy,
      order: sortOrder || orgListTablePropsRef?.current?.order,
      pageSize,
      pageNumber,
      searchTerm: searchTerm != null && searchTerm != undefined ? searchTerm : orgListTablePropsRef?.current?.searchTerm
    }));
    if (tableProps) {
      tableProps.view = view || 'list'
      tableProps.sortBy = sortField || orgListTablePropsRef?.current?.sortBy;
      tableProps.order = sortOrder || orgListTablePropsRef?.current?.order;
      tableProps.pageNumber = pageNumber;
      tableProps.pageSize = pageSize;
      if (searchTerm != null && searchTerm != undefined) {
        tableProps.searchTerm = searchTerm;
      }
    }
    await dispatch(setTableProps(tableProps, 'orgsList'))
  }, [orgListTablePropsRef?.current, dispatch]);

  // Handler For Column Prefrence Chnages
  const handleColumnPrefrenceChange = useCallback(async (operation,data) => {
    await dispatch(setTableColumnPrefrences('org',operation,data))
 },[])

  // TODO: Redux state Clean up
  const orgCleanUpStates = useCallback(() => {
    // : Partial<UserAction>
    const action = {
      type: userTypes.CLEAR_ORG_LIST_DATA,
    }
    dispatch(action);
  }, [])

  useEffect(() => {
    window.addEventListener('beforeunload', orgCleanUpStates);
    return (): void => {
      orgCleanUpStates()
      window.removeEventListener('beforeunload', orgCleanUpStates);
    }
  }, [])

  // ***********************************************************
  // TODO: Export Handlers
  const handleClose = (): void => {
    setExportAnchorEl(null);
  };

  const fileDownloadSuccessToast = useCallback((): Id => {
    setExportingFile(false);
    return toast.success('File downloaded.');
  }, []);

  const fileDownloadFailureToast = useCallback((): Id => {
    setExportingFile(false);
    return toast.warn('Something went wrong. Please export again.');
  }, []);


  const onExportBtnClick = useCallback(async (fileType: 'csv' | 'xlsx'): Promise<void> => {
    if (exportingFile) {
      // If already exporting, prevent another export
      return;
    }
    try {
      const response = await dispatch(exportToCsvForOrgList({
        fileType: fileType,
      }));
      if (response?.status === 202 || response?.status === 200) {
        downloadFile({ url: response?.data?.url });
        fileDownloadSuccessToast()
      } else {
        fileDownloadFailureToast();
        setExportAnchorEl(null);
      }
    } finally {
      // Ensure that exporting status is updated even if there's an error
      setExportingFile(false);
      setExportAnchorEl(null);

    }
  }, [exportingFile, fileDownloadSuccessToast, fileDownloadFailureToast])

  return (
    <>
      <TableDashboard
        tableId="manage-org-table"
        tableClassName="manage__org__table"
        header={'Organisations'}
        searchBoxIncluded={true}
        searchBoxConfiguration={searchBoxConfiguration}
        gridColumns={orgColumns}
        tableRows={getTableRows()}
        mobileViewConfiguration={mobileViewConfiguration}
        layoutView={'list'}
        headerActionBtns={headerActionBtns}
        listLayoutCallBack={layoutCallback}
        totalCount={orgsCount}
        showSkeleton={!orgs}
        totalCountText={orgsCount > 1 ? 'organisations' : 'organisation'}
        showLoader={loader}
        customNoDataComponent={NoDataComponentOrgs}
        pageNumber={orgListTableProps?.pageNumber}
        tablePageSize={orgListTableProps?.pageSize}
        sortField={orgListTableProps?.sortBy}
        sortOrder={orgListTableProps?.order}
        columnPrefrences={screenTableColumnPrefrences?.find((item) => item.screen === 'org')}
        handleColumnPrefrenceChange={handleColumnPrefrenceChange}

      />
      <Menu
        id="export-option-dropdown"
        anchorEl={exportAnchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        className='export__option__dropdown'
        sx={{ top: '30px' }}
        open={Boolean(exportAnchorEl)}
        onClose={handleClose}

      >
        <MenuItem>
          <div className='export__btn-option' onClick={(e) => {
            e?.stopPropagation();
            onExportBtnClick('csv')
          }}>
            To CSV
          </div>
        </MenuItem>
        <MenuItem>

          <div className='export__btn-option' onClick={(e) => {
            e?.stopPropagation();
            onExportBtnClick('xlsx')
          }}>
            To Excel
          </div>
        </MenuItem>
      </Menu>

      <CustomDialog
        dialogConfig={{
          dialogDescription: 'Are you sure you want to delete this organisation?',
          dialogCancelTitle: 'Cancel',
          dialogOkTitle: 'Delete',//'Delete',
        }}
        show={isDeleteDialogOpen}
        handleClose={handleDeleteDialogClose}
        handleSubmit={handleDeleteOrg}
      />
    </>
  );
})

export default OrgManagementDashboard;
