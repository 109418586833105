/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react'
import ChargerPanelRemoteCommandsComponent from './ChargerPanelRemoteCommands'
import { useAppSelector } from '../../../rmsReduxStore/rmsReduxHooks'
import { getReferenceDataReducer } from '../../../rmsReduxStore/reduxExports'

interface ChargerPanelProps { contentHeight: number }

const ChargerPanelRemoteCommandsDashboard = React.memo(
  
  function ChargerPanelRemoteCommandsDashboard({ contentHeight }: ChargerPanelProps) {
    const {allowedCommandsForRoleReferenceData} = useAppSelector(getReferenceDataReducer)
   const remoteCommandsNavList = [
      { buttonText: 'Soft Reset', buttonIconClassName: 'panel_soft_reset_icon', buttonId: 'soft_reset' },
      { buttonText: 'Hard Reset', buttonIconClassName: 'panel_hard_reset_icon', buttonId: 'hard_reset'  },
      { buttonText: 'Enable System', buttonIconClassName: 'panel_enable_system_icon', buttonId: 'enable_system' },
      { buttonText: 'Disable System', buttonIconClassName: 'panel_disable_system_icon', buttonId: 'disable_system' },
      { buttonText: 'Enable Connector', buttonIconClassName: 'panel_enable_connector_icon', buttonId: 'enable_connector' },
      { buttonText: 'Disable Connector', buttonIconClassName: 'panel_disable_connector_icon', buttonId: 'disable_connector' },
      { buttonText: 'FTP software upgrade', buttonIconClassName: 'panel_ftp_software_connector_icon', buttonId: 'ftp_software_upgrade'},
      { buttonText: 'FTP Customer Logo update', buttonIconClassName: 'panel_ftp_customer_logo_update_icon', buttonId: 'ftp_customer_logo_update' },
      { buttonText: 'Calibrate Voltage', buttonIconClassName: 'panel_calibrate_voltage_icon', buttonId: 'calibrate_voltage' },
      { buttonText: 'Calibrate Display', buttonIconClassName: 'panel_calibrate_display_icon', buttonId: 'calibrate_display' }
    ]
    const allowedCommands = remoteCommandsNavList?.filter((command) => allowedCommandsForRoleReferenceData?.includes(command?.buttonId))
    return (<ChargerPanelRemoteCommandsComponent contentHeight={contentHeight} commands={allowedCommands}></ChargerPanelRemoteCommandsComponent>)
  }
)

export default ChargerPanelRemoteCommandsDashboard
