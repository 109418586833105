import { AxisLabelsFormatterContextObject, Options, OptionsChartZoomingTypeValue } from 'highcharts';
import React, { FC, useEffect, useMemo, useRef, useState } from 'react';
import { CustomHighCharts } from '../../../../globalUtils/globalExports';
import moment from 'moment';

interface LogsData {
  connectorStatusLogsData: {
    y: number;
    name: string;
    x: number;
    x2: number;
    color: string;
  }[][],
  categories: string[]
}

const ChargerStatusLogsGraph: FC<LogsData> = ({ connectorStatusLogsData , categories}) => {
  const [forceRefreshId, setForceRefreshId] = useState(new Date().getTime());
  const connectorColorsList = { C1: ' Idle', C2: 'bluecolor', C3:'Charging'}
  const elementRef = useRef(null);


  // TODO: get values for x axis
  useEffect(() => {
    const xAxisValues: string[] = []
    if (connectorStatusLogsData && connectorStatusLogsData?.length > 0) {
      connectorStatusLogsData?.forEach(data => {
        if (data && data?.length > 0) {
          data?.forEach(dataPoint => {
            xAxisValues?.push(dataPoint?.x?.toString());
            xAxisValues?.push(dataPoint?.x2?.toString());
          })
        }
      })
    }

  }, [connectorStatusLogsData])


  //TODO: Connector Options for graph
  const connectorOptions: Options = useMemo(() => {
    return {
      chart: {
        type: 'xrange',
        height:connectorStatusLogsData?.length > 2 ? 220 : 180,
        events: {
          load: function (): void {
            this.reflow();
          }
        },
        zooming: {
          key: 'ctrl',
          type: 'xy' as OptionsChartZoomingTypeValue
        },
        resetZoomButton: {
          position: {
            verticalAlign: 'bottom',
            y: 50
          },
        },
        spacingBottom: 50
      },
      title: {
        text: ''
      },
      credits: {
        enabled: false
      },
      legend: {
        enabled: false
      },
      xAxis: {
        autoRotationLimit: 40,
        type: 'datetime',
        dateTimeLabelFormats: {
          'second': '%l:%M:%S %p',
          'minute': '%l:%M:%S %p',
          'hour': '%l:%M %p',
          'day': '%b %e',
          'week': '%%pE, %bM %e',
          'month': '%b %e, y'
        },
        gridLineWidth: 0,
        lineWidth: 0,
        tickColor: 'transparent',
        labels: {
          style: {
            fontSize: '10px',
            fontFamily: 'var(--font-family-regular)',
            color: 'var(--primary-text-color)'
          },
          formatter: function (this: AxisLabelsFormatterContextObject): string {
            const timestamp = this.value as number;
            const date = new Date(timestamp);
            const formattedDate = `${moment(date).format('MMM Do')}`;
            const formattedTime = ` ${moment(date).format('LT')}`;
            return formattedDate +'<br/>'+ formattedTime;
          },
        },
      },
      tooltip: {
        enabled: true,
        useHTML: true,
        outside: true,
        style: {
          zIndex: 10
        },
        formatter: function (): string {
          const startTime = this?.point?.x ? moment(new Date(this?.point?.x)).format('llll') : '-';
          const endTime = this?.point?.x2 ? moment(new Date(this?.point?.x2)).format('llll') : '-';
          const status = this?.point?.options?.description ?? 'NA'
          return (
            `
            <div style='font-size:12px;' class="graph__tooltip__header">` +
            this.series.name + ' ' + this.point.options.name + ` 
            </div>
            <div style='font-size:10px;' class="graph__tooltip__total___content">` +
            'Status: ' +
            status + `
            </div>
            <div> <div style='font-size:10px;' class="graph__tooltip__total___content">` +
            'Start time: ' +
            startTime + `
              </div>
              <div style='font-size:10px;' class="graph__tooltip__total___content">` +
            'End time: ' +
            endTime + `
              </div>       
            </div>
            `
          );
        }
      },
      time: {
        timezone: 'Asia/Calcutta'
      },
      yAxis: {
        title: {
          text: '',
        },
        allowDecimals: false,
        startOnTick: false,
        endOnTick: false,
        categories: categories,
        gridLineWidth: 0,
        labels: {
          useHTML: true,
          style: {
            fontSize: '10px',
            fontFamily: 'var(--font-family-regular)',
            color: 'var(--primary-text-color)',
            margin:'5px'
          },
          formatter: function (): string {
            const value = this.value?.toString()?.replace(/['"]+/g, '');   
            const color = connectorColorsList[value]?.toLocaleLowerCase()
            return `<div style="z-index: 20; margin-right:0px" class="connector__type ${color}"> ${value}  </div>`;
      
          },
        },
      },
      inactiveOtherPoints: true,
      series: [
        {
          type: 'xrange',
          name: 'Connector',
          id: 'Connector',
          borderRadius:0,
          borderColor:'',
          data: connectorStatusLogsData.flatMap(innerArray => innerArray.map(obj => ({ ...obj }))),
        },
      ]
    }
  }, [connectorStatusLogsData, categories, connectorColorsList])
  

  // TODO: Resize for graph
  const updateSize = (): void => { setForceRefreshId(new Date().getTime()) }

  useEffect(() => {
    const ro = new ResizeObserver(() => { setForceRefreshId(new Date().getTime()) });
    if (elementRef && elementRef.current) {
      ro.observe(elementRef.current);
    }
    window.addEventListener('resize', updateSize);
    return () => window.removeEventListener('resize', updateSize);
  }, [elementRef])


  return (
    <div className='charger__status__history__logs__container'>
      <div className='charger__status__history__logs__header'>
        Connector Status Logs
      </div>

      <div className='charger__status__history__logs__graph'>
        <div ref={elementRef} id='charger__status__history__status__logs' className='charger__status__history__status__logs__wrap'>
          <CustomHighCharts key={'charger__status__history__status__logs-connector-one' + forceRefreshId} highChartOptions={connectorOptions} />
        </div>
      </div>
    </div>
  )
}

export default ChargerStatusLogsGraph