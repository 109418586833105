import React, { FC, useCallback, useEffect, useMemo, useState } from 'react'
import {
  CustomDatePicker,
  SelectDropDown,
  useAppDispatch,
  useAppSelector,
} from '../../../../globalUtils/globalExports'
import ChargerStatusGraph, { IHistoryGraphConnectorData } from './ChargerStatusGraph';
import ChargerStatusLogsGraph from './ChargerStatusLogsGraph';
import {
  chargerTypes,
  fetchChargerConnectorStatusLogs,
  fetchChargerGraphDetail,
  getChargerReducer,
  getFilterReducer,
  getFilterSelectedValue,
  getReferenceDataReducer,
  setFilterData,
  modifyChargerHistoryGraphData,
  fetchChargerGraphDetailBulk,
  clearChargerHistoryGraphData,
  setChargerHistoryGraphFilterHash,
} from '../../../../rmsReduxStore/reduxExports';
import {
  ChargerHistoryGraphSkeleton,
  ChargerHistoryLogsSkeleton
} from '../../../../globalUtils/SkeletonDesign/SkeletonDesign';
import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import { cloneDeep } from 'lodash';
import { IconWithTooltip } from '../../../../globalUtils/globalIcons';

interface IntervalData {
  y: number;
  name: string;
  x: number;
  x2: number;
  color: string;
  description?: string
}


// TODO: Main file for History
export default function ChargerStatusHistory(): JSX.Element {
  const { chargerHistoryGraphData, chargerConnectorStatusLogs } = useAppSelector(getChargerReducer);
  const [timeSeriesConnectorData, setTimeSeriesConnectorData] = useState<IHistoryGraphConnectorData[] | null>(null)
  const [connectorStatusLogsData, setConnectorStatusLogsData] = useState<IntervalData[][] | null>(null)
  const [categories, setCategories] = useState<string[] | null>(null)
  // TODO: Plot data points for History graph
  const calculateDataPointsForConnector = useCallback(() => {
    const graphDetails: IHistoryGraphConnectorData[] = []
    if (chargerHistoryGraphData && chargerHistoryGraphData?.data?.length > 0) {
      chargerHistoryGraphData?.data && chargerHistoryGraphData?.data?.forEach(data => {
        if (data && data?.history?.length > 0) {
          const newData: [number, number][] = data?.history?.map(detail => {
            return [new Date(detail?.dateTime)?.getTime(), detail?.value]
          })
          const singleSeriesData: IHistoryGraphConnectorData = {
            data: newData,
            connectorColor: data?.connectorColor,
            connectorName: data?.connectorName,
            parameterName: data?.parameterName,
            unit: data?.unit
          }
          graphDetails?.push(singleSeriesData);
        }
      })
    }
    setTimeSeriesConnectorData(graphDetails)
  }, [chargerHistoryGraphData])




  // TODO: Plot data points for xrange graph
  const adjustIntervals = useCallback((dataintervals: IntervalData[][], rangeStartTime: string, rangeEndTime: string): IntervalData[][] => {
    const adjustedIntervalsData: IntervalData[][] = [];
    dataintervals?.forEach(intervals => {
      const adjustedIntervals: IntervalData[] = [];
      if (intervals[0]?.x != Date.parse(rangeStartTime)) {
        adjustedIntervals.push({
          y: intervals[0]?.y,
          name: intervals[0]?.name,
          x: Date.parse(rangeStartTime),
          x2: intervals[0]?.x,
          color: 'var(--charger-status-idle-light-color)',
        })
      }
      for (let i = 0; i < intervals.length - 1; i++) {
        adjustedIntervals.push(intervals[i]);
        const currentX2 = intervals[i]?.x2;
        const nextX = intervals[i + 1]?.x;

        if (currentX2 !== nextX) {
          const insertIndex = adjustedIntervals.length;
          adjustedIntervals.splice(insertIndex, 0, {
            y: intervals[i]?.y,
            name: intervals[i]?.name,
            x: currentX2,
            x2: nextX,
            color: 'var(--charger-status-idle-light-color)',
          });
        }
      }
      adjustedIntervals.push(intervals[intervals.length - 1]);
      if (intervals[intervals.length - 1]?.x2 != Date.parse(rangeEndTime)) {
        adjustedIntervals.push({
          y: intervals[intervals.length - 1]?.y,
          name: intervals[intervals.length - 1]?.name,
          x: intervals[intervals.length - 1]?.x2,
          x2: Date.parse(rangeEndTime),
          color: 'var(--charger-status-idle-light-color)',
        })
      }
      adjustedIntervalsData.push(adjustedIntervals);
    });
    return adjustedIntervalsData;
  }, []);

  const calculateDataPointsForStatusLogs = useCallback(() => {
    const data: IntervalData[][] = [];
    const categories: string[] = [];
    if (chargerConnectorStatusLogs && chargerConnectorStatusLogs?.connectorData?.length > 0) {
      const statusLog = chargerConnectorStatusLogs?.connectorData?.map((element, index) => {
        if (element?.sessionLogs?.length === 0) {
          categories?.push('C' + (index + 1))
          return [{
            y: chargerConnectorStatusLogs?.connectorData?.length - index - 1,
            name: element?.connectorName,
            x: Date.parse(chargerConnectorStatusLogs?.startDateTime),
            x2: Date.parse(chargerConnectorStatusLogs?.endDateTime),
            color: 'var(--charger-status-idle-light-color)'
          }]
        }
        const log = element?.sessionLogs?.map((sessionLog) => {
          return {
            y: chargerConnectorStatusLogs?.connectorData?.length - index - 1,
            name: element?.connectorName,
            x: Date.parse(sessionLog?.startedAt),
            x2: Date.parse(sessionLog?.finishedAt),
            description: sessionLog?.state,
            color: sessionLog?.colorCode ?? 'var(--charger-status-idle-light-color)'
          }
        })
        categories?.push('C' + (index + 1))
        return log
      });
      const modifiedData = adjustIntervals(statusLog, chargerConnectorStatusLogs?.startDateTime, chargerConnectorStatusLogs?.endDateTime)
      data?.push(...modifiedData)
    }
    setCategories(categories?.reverse())
    setConnectorStatusLogsData(data)
  }, [chargerConnectorStatusLogs])


  useEffect(() => {
    calculateDataPointsForStatusLogs()
  }, [chargerConnectorStatusLogs])

  useEffect(() => {
    calculateDataPointsForConnector();
  }, [chargerHistoryGraphData])

  return (
    <div className='charger__status__history__dashboard'>
      {chargerHistoryGraphData && timeSeriesConnectorData ? <><ChargerStatusHistoryHeader /><ChargerStatusGraph
        timeSeriesConnectorData={timeSeriesConnectorData} startDateTime={chargerHistoryGraphData?.startDateTime} endDateTime={chargerHistoryGraphData?.endDateTime} /> </> :
        <ChargerHistoryGraphSkeleton />}
      {chargerConnectorStatusLogs && connectorStatusLogsData && categories ?
        <ChargerStatusLogsGraph connectorStatusLogsData={connectorStatusLogsData} categories={categories} /> : <ChargerHistoryLogsSkeleton />}
    </div>
  )
}

// TODO: Header with dropdowns 
const ChargerStatusHistoryHeader: FC = () => {
  const location = useLocation();
  const dispatch = useAppDispatch();
  const { chargerHistoryTypeReferenceData, chargerHistoryIntervalReferenceData } = useAppSelector(getReferenceDataReducer);
  const { chargerHistoryStartDate, chargerHistoryEndDate } = useAppSelector(getChargerReducer);
  const { screenFilters } = useAppSelector(getFilterReducer)
  const historyGraphInfoContent = 
  <div className="tooltip_container">
    <ul>
      <li>Current, Temperature and Voltage parameters are plotted on the left y-axis.</li>
      <li>Alarm and Gun Temperature Derating flag parameters are plotted on the right y-axis.</li>
    </ul>
  </div>


  const onDateChange = useCallback(async (dates): Promise<void> => {
    const [start, end] = dates;
    if (start && end) {
      const differenceInMilliseconds = end.getTime() - start.getTime();
      const differenceInDays = differenceInMilliseconds / (1000 * 60 * 60 * 24);
      if (differenceInDays > 30) {
        toast.error('Date range exceeded more than 30 days.');
        return
      }
    }
    //: Partial<ChargerAction>
    const action = {
      type: chargerTypes.SET_CHARGER_HISTORY_DATES,
      chargerHistoryStartDate: start,
      chargerHistoryEndDate: end
    }
    await dispatch(action);

    if (start && end) {
      const customIntervalOption = chargerHistoryIntervalReferenceData?.find((item) => item?.id === 'custom')
      await handleGraphDropdownChange(customIntervalOption, 'historyInterval', start, end)
    }

  }, [chargerHistoryIntervalReferenceData])

  // TODO: Handle filter Value
  const handleGraphDropdownChange = useCallback(async (val, filterKey, start?: Date | null, end?: Date | null, actionMeta?) => {
    let valueToSet = val
    const values = val && val?.id
    // Don't allow user to deselect all options from History Type
    if (filterKey === 'historyType' && val && val?.length === 0) { 
      toast.error('Atleast one parameter should be selected.'); 
      // set the previos value of filter as it has chnaged locally in the dropdown component
      // await setFilterData(getFilterSelectedValue('historyType', 'chargerPanel', false), filterKey, 'chargerPanel', 'SET', false, true)(dispatch)
      return
    }

    // Don't allow user to deselect all options from Connector Type
    if (filterKey === 'connectorId' && val && val?.length === 0) { 
      toast.error('Atleast one connector should be selected.');       // set the previos value of filter as it has chnaged locally in the dropdown component
      // set the previos value of filter as it has chnaged locally in the dropdown component
      // await setFilterData(getFilterSelectedValue('connectorId', 'chargerPanel', false), filterKey, 'chargerPanel', 'SET', false, true)(dispatch)
      return
    }

    // If History Type changes automatically change the stat type to respective default option
    if (filterKey === 'historyType') {
      const defaultStatType = chargerHistoryTypeReferenceData?.find(item => item?.id === val?.[0]?.id)?.statType?.find(item => item.defaultOption)
      await setFilterData(defaultStatType, 'statType', 'chargerPanel', 'SET', false)(dispatch);
    }

    // If History Interval changes automatically change the stat interval to respective default option
    if (filterKey === 'historyInterval') {
      const defaultStatInterval = chargerHistoryIntervalReferenceData?.find(item => item?.id === val?.id)?.statInterval?.find(item => item?.defaultOption)
      await setFilterData(defaultStatInterval, 'statInterval', 'chargerPanel', 'SET', false)(dispatch);
    }

    // If any History Type or ConnectorType option is de-selected remove it's data from the graph 
    if ((filterKey === 'historyType' || filterKey === 'connectorId') && actionMeta && (actionMeta?.action === 'deselect-option' || actionMeta?.action === 'remove-value')) {
      const removedOption = actionMeta?.action === 'deselect-option' ? actionMeta?.option : actionMeta?.removedValue
      const removeKey = filterKey === 'historyType' ? removedOption?.id : removedOption?.name
      const removeKeyType = filterKey === 'historyType' ? 'parameter' : 'connector'
      dispatch(setChargerHistoryGraphFilterHash('REMOVE', null, null, removedOption?.id, removeKeyType))
      const data = modifyChargerHistoryGraphData(null, 'REMOVE', removeKey, removeKeyType)
      const action = {
        type: chargerTypes.FETCH_CHARGER_GRAPH_DETAIL,
        chargerHistoryGraphData: data
      }
      dispatch(action)
      await setFilterData(valueToSet, filterKey, 'chargerPanel', 'SET', false, true)(dispatch)
      return
    }

    if (filterKey === 'historyInterval' && val?.id === 'custom' && !start && !end) {
      valueToSet = [val, new Date(), new Date()] ?? []
    }

    if (filterKey === 'historyInterval' && values === 'custom' && start && end) {
      valueToSet = [val,start,end ] ?? []
    }

    const res = await setFilterData(valueToSet, filterKey, 'chargerPanel', 'SET', false, true)(dispatch)
    if (res?.message === 'Action dispatched successfully') {
      // Don't call the API if Custom is selected in History Interval but date range was not selected
      if (filterKey === 'historyInterval' && val?.id === 'custom' && !start && !end) {
        return
      }
      // Call connector logs API only if History Interval is changed
      if (filterKey === 'historyInterval') {
        dispatch(fetchChargerConnectorStatusLogs({
          chargerId: location?.state?.chargerId,
          filters: res?.filters?.filter((item) => item?.key === 'historyInterval')
        }))
      }

      /* On Change of any of historyInterval, statType or statInterval 
       call the API for combination of selected history Type and connector Type */
      if (filterKey === 'historyInterval' || filterKey === 'statType' || filterKey === 'statInterval') {
        await dispatch(clearChargerHistoryGraphData())
        fetchChargerGraphDetailBulk({ chargerId: location?.state?.chargerId })(dispatch)
        return
      }

      if (filterKey === 'historyType') {
        const selectedFilters = res?.filters
        const remainingFilters = selectedFilters?.filter?.((filter) => filter?.key !== 'connectorId' && filter.key !== 'historyType')
        const selectedConnectors = selectedFilters?.find((filter) => filter?.key === 'connectorId')
        const selectedHistoryType = selectedFilters?.find((filter) => filter?.key === 'historyType')
        if (selectedHistoryType) {
          selectedHistoryType.values = selectedHistoryType?.values?.slice(-1)
          selectedConnectors?.values?.forEach(connector => {
            let filtersToUpdate = cloneDeep(remainingFilters)
            filtersToUpdate = filtersToUpdate?.concat([selectedHistoryType, { key: 'connectorId', values: [connector] }])
            const filterHash = dispatch(setChargerHistoryGraphFilterHash('SET', selectedHistoryType?.values?.[0], connector))
            dispatch(fetchChargerGraphDetail({
              chargerId: location?.state?.chargerId,
              filterHash: filterHash,
              filters: filtersToUpdate
            }))
          });
        }
      }

      if (filterKey === 'connectorId') {
        const selectedFilters = res?.filters
        const remainingFilters = selectedFilters?.filter?.((filter) => filter?.key !== 'connectorId' && filter.key !== 'historyType')
        const selectedConnectorType = selectedFilters?.find((filter) => filter?.key === 'connectorId')
        const selectedHistoryTypes = selectedFilters?.find((filter) => filter?.key === 'historyType')
        if (selectedConnectorType) {
          selectedConnectorType.values = selectedConnectorType?.values?.slice(-1)
          selectedHistoryTypes?.values?.forEach(historyType => {
            let filtersToUpdate = cloneDeep(remainingFilters)
            filtersToUpdate = filtersToUpdate?.concat([{ key: 'historyType', values: [historyType] }, selectedConnectorType])
            const filterHash = dispatch(setChargerHistoryGraphFilterHash('SET', historyType, selectedConnectorType?.values?.[0]))
            dispatch(fetchChargerGraphDetail({
              chargerId: location?.state?.chargerId,
              filterHash: filterHash,
              filters: filtersToUpdate
            }))
          })
        }
      }
    }
  }, [chargerHistoryIntervalReferenceData, chargerHistoryTypeReferenceData, screenFilters])


  const graphFilterProps = useMemo(() => {
    const selectedHistoryTypeValue = getFilterSelectedValue('historyType', 'chargerPanel', false)?.[0] as IChargerHistoryTypeRefData;
    const selectedHistoryIntervalValue = getFilterSelectedValue('historyInterval', 'chargerPanel', false)?.[0] as IChargerHistoryIntervalRefData;

    const statOptions = selectedHistoryTypeValue?.statType ?? [];
    const statIntervalOptions = selectedHistoryIntervalValue?.statInterval ?? [];
    return [
      {
        selectValue: getFilterSelectedValue('statInterval', 'chargerPanel', false),
        selectOptions: statIntervalOptions ?? [],
        handleSelectOptionChange: (val): void => {
          handleGraphDropdownChange(val, 'statInterval')
        },
        selectDropDownId: 'charger__stat__interval__dropdown',
        dropDownClassName: 'charger__stat__interval__dropdown',
        isMultiSelect: false
      },
      {
        selectValue: getFilterSelectedValue('statType', 'chargerPanel', false),
        selectOptions: statOptions ?? [],
        handleSelectOptionChange: (val): void => {
          handleGraphDropdownChange(val, 'statType')
        },
        selectDropDownId: 'charger__stat__type__dropdown ',
        dropDownClassName: 'charger__stat__type__dropdown',
        isMultiSelect: false
      },
      {
        selectValue: getFilterSelectedValue('historyInterval', 'chargerPanel', false)?.[0],
        selectOptions: chargerHistoryIntervalReferenceData ?? [],
        handleSelectOptionChange: (val): void => {
          if (val?.id !== 'custom') {
            const action = {
              type: chargerTypes.SET_CHARGER_HISTORY_DATES,
              chargerHistoryStartDate: new Date(),
              chargerHistoryEndDate: null
            }
            dispatch(action);
          }
          handleGraphDropdownChange(val, 'historyInterval')
        },
        selectDropDownId: 'charger__history__interval__dropdown',
        dropDownClassName: 'charger__history__interval__dropdown',
        isMultiSelect: false
      },
    ]
  }, [chargerHistoryTypeReferenceData, chargerHistoryIntervalReferenceData, screenFilters, handleGraphDropdownChange])

  return (
    <div className='charger__status__history__header__wrap'>
      <div className='charger__status__history__header__wrap-top'>
        <div className='charger__status__history__header__wrap-left'>
          <div className='charger__status__history__header-text'>
            History
          </div>
          <IconWithTooltip iconClassName='rms_info_icon-black' toolTipText={historyGraphInfoContent} containerClassName='history__graph__info' />
          <SelectDropDown
            selectValue={getFilterSelectedValue('historyType', 'chargerPanel', false)}
            selectOptions={chargerHistoryTypeReferenceData ?? []}
            handleSelectOptionChange={(val, actionMeta): void => {
              handleGraphDropdownChange(val, 'historyType', null, null, actionMeta)
            }}
            selectDropDownId='charger__history__type__dropdown'
            dropDownClassName='charger__history__type__dropdown'
            isMultiSelect={true}
            isClearable={false}
          />
          <SelectDropDown
            selectValue={getFilterSelectedValue('connectorId', 'chargerPanel', false)}
            selectOptions={chargerHistoryTypeReferenceData?.find((item) => item?.id === (getFilterSelectedValue('historyType', 'chargerPanel', false)?.[0] as IChargerHistoryTypeRefData)?.id)?.connectorType ?? []}
            handleSelectOptionChange={(val, actionMeta): void => {
              handleGraphDropdownChange(val, 'connectorId', null, null, actionMeta)
            }}
            selectDropDownId='charger__connector__type__dropdown'
            dropDownClassName='charger__connector__type__dropdown'
            isMultiSelect={true}
            isClearable={false}
          />

        </div>
        <div className='charger__status__history__header__wrap-right'>
          <span className='charger__status__history__header-text reduced-font-size'>
            Interval
          </span>
          {graphFilterProps && graphFilterProps?.length > 0 && graphFilterProps?.map((item, index) => {
            return (
              <>
                {item.selectDropDownId === 'charger__history__interval__dropdown' && <div key={index} className='charger_stat-type-break'></div>}
                <SelectDropDown
                  key={'dropdown' + index}
                  selectValue={item?.selectValue}
                  selectOptions={item?.selectOptions}
                  handleSelectOptionChange={item?.handleSelectOptionChange}
                  selectDropDownId={item?.selectDropDownId}
                  dropDownClassName={item?.dropDownClassName}
                  isMultiSelect={item?.isMultiSelect}
                />
              </>
            )
          }
          )}
        </div>

      </div>
      {getFilterSelectedValue('historyInterval', 'chargerPanel', false)?.[0]?.id === 'custom' &&
        <CustomDatePicker startDate={chargerHistoryStartDate ? new Date(chargerHistoryStartDate) : null} endDate={chargerHistoryEndDate ? new Date(chargerHistoryEndDate) : null} selectsRange={true} onChange={onDateChange} />}
    </div>
  )
}


