import { cloneDeep, isEqual } from 'lodash';
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import CustomForm, { ICustomFormProps, IFormContentProp } from '../../../globalUtils/CustomForm/CustomForm';
import {
  CustomButtonGroup,
  CustomSwitch,
  DashboardHeader,
  DashboardLoader,
  useAppDispatch,
  useAppSelector,
  useEffectOnce,
} from '../../../globalUtils/globalExports';
import { getReferenceDataReducer, fetchCustomerReferenceData, updateCharger, createCharger, fetchChargerDetail, getChargerReducer, chargerTypes, fetchChargerStationReferenceData } from '../../../rmsReduxStore/reduxExports';
import { IBreadCrumb, createBreadCrumbProps, removeAllFromReferenceData } from '../../../globalUtils/globalHooks';
import { Id, toast } from 'react-toastify';
import { useLocation, useNavigate } from 'react-router-dom';
import { AxiosResponse } from 'axios';
import CustomBreadCrumbs, { BreadCrumbObject } from '../../../globalUtils/CustomBreadCrumbs/CustomBreadCrumbs';
import './AddCharger.css';
import { IconWithTooltip } from '../../../globalUtils/globalIcons';
import access from '../../../auth/service/AccessControl';

interface IChargerForm {
  macId: string;
  model: string;
  customer: string;
  chargingStation:string,
  visibleId: string;
  latitude: string;
  longitude: string;
  remarks: string;
  visibility: string;
}

const AddCharger: FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { customerReferenceData,chargingStationReferenceData } = useAppSelector(getReferenceDataReducer);
  const { chargerDetail } = useAppSelector(getChargerReducer)
  const initialChargerFormData: IChargerForm = {
    macId: '',
    model: '',
    customer: location?.state?.id ?? '',
    chargingStation:location?.state?.stationId ?? '',
    visibleId: '',
    latitude: '',
    longitude: '',
    remarks: '',
    visibility: ''

  };
  const initialStateData = {
    macIdValue: '',
    chargerModelValue: null,
    customerValue: location?.state?.name ? { name: location?.state?.name, id: location?.state?.id, value: location?.state?.id, label: location?.state?.name } : null,
    chargingStationValue:location?.state?.stationName ? { name: location?.state?.stationName , id: location?.state?.stationId, value: location?.state?.stationId, label: location?.state?.stationName  } : null,
    chargerSerialNumber: '',
    latitude: '',
    longitude: '',
    remarks: '',
    visibility: '',
  }

  const [addChargerFormData, setAddChargerFormData] = useState(initialChargerFormData);
  const [addChargerStateData, setAddChargerStateData] = useState(initialStateData);

  const [initialChargerData,setInitialChargerData]= useState(initialChargerFormData);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [mode, setMode] = useState(location?.state?.mode ?? 'Add')
  const [showLoader, setShowLoader] = useState(false);
  const [chargingStationOptions,setChargingStationOptions] = useState< IGeneralReferenceData[]|null>(chargingStationReferenceData)
  
  useEffectOnce(() => {
    if (location?.state?.rowData) {
      dispatch(fetchChargerDetail({ chargerId: location?.state?.rowData?.id }))
    }
    dispatch(fetchCustomerReferenceData());
    dispatch(fetchChargerStationReferenceData());

  });

  useEffectOnce(()=>{
    if(location?.state?.from=='/customer'){
      const options =chargingStationReferenceData?.filter((item) => item?.customerId === location?.state?.id);
      setChargingStationOptions( options || [])
    }
  })



  useEffect(() => {
    loadInitialData()
  }, [chargerDetail])

  const loadInitialData = () => {
    if (chargerDetail) {
      setAddChargerFormData({
        ...addChargerFormData,
        macId: chargerDetail?.macId,
        customer: chargerDetail?.customerId,
        chargingStation:chargerDetail?.chargingStationId,
        visibleId: chargerDetail?.visibleId,
        latitude: chargerDetail?.latitude,
        longitude: chargerDetail?.longitude,
        remarks: chargerDetail?.remarks,
        visibility: chargerDetail?.visibility
      })
      setAddChargerStateData({
        ...addChargerStateData,
        macIdValue: chargerDetail?.macId,
        chargerSerialNumber: chargerDetail?.visibleId,
        latitude: chargerDetail?.latitude,
        longitude: chargerDetail?.longitude,
        remarks: chargerDetail?.remarks,
        visibility: chargerDetail?.visibility,
        customerValue: {
          id: chargerDetail?.customerId,
          value: chargerDetail?.customerName,
          label: chargerDetail?.customerName,
          name: chargerDetail?.customerName
        },
        chargingStationValue:{
          id: chargerDetail?.chargingStationId,
          value: chargerDetail?.chargingStationId,
          label: chargerDetail?.chargingStationName,
          name: chargerDetail?.chargingStationName
        }

      })
      setInitialChargerData({
        ...initialChargerData,
        macId: chargerDetail?.macId,
        customer: chargerDetail?.customerId,
        chargingStation:chargerDetail?.chargingStationId,
        visibleId: chargerDetail?.visibleId,
        latitude: chargerDetail?.latitude,
        longitude: chargerDetail?.longitude,
        remarks: chargerDetail?.remarks,
        visibility: chargerDetail?.visibility
      })
      //Updating the Initial Options of charging stations as per the customer selected
      const options = chargingStationReferenceData?.filter((item) => item?.customerId === chargerDetail?.customerId);
      setChargingStationOptions( options || [])
    }
  }

  const handleFormDataChange = useCallback((val, formKey, stateKey) => {
    const stateData = cloneDeep(addChargerStateData);
    const formData = cloneDeep(addChargerFormData);
    if(formKey==='customer'){
      const options =chargingStationReferenceData?.filter((item) => item?.customerId === val?.id);
      setChargingStationOptions( options || [])
      stateData['chargingStationValue'] = null
      formData['chargingStation'] = ''
    }
    if (val?.id) {
      formData[formKey] = val?.id;
    } else {
      formData[formKey] = val;
    }


    stateData[stateKey] = val;
    setAddChargerStateData(stateData);
    setAddChargerFormData(formData);

  }, [addChargerStateData, addChargerFormData,chargingStationReferenceData]);

  const disableButton = useCallback(() => {
    if (mode === 'Edit' && isEqual(addChargerFormData, initialChargerData)) {
      return true
    }
    return (!(addChargerFormData?.macId?.length > 0 && addChargerFormData?.visibleId?.length > 0 && addChargerFormData?.latitude?.length > 0 && addChargerFormData?.longitude?.length > 0 && addChargerFormData?.customer?.length > 0 && addChargerFormData?.chargingStation?.length > 0))
  }, [addChargerFormData,initialChargerData,mode])

  const chargerAddedToast = (): Id => toast.success(`Charger ${mode || 'Add'}ed.`);
  const duplicateChargerToast = (): Id => toast.warn('Charger with same ID exists.');
  const somethingWentWrongToast = (): Id => toast.warn('Something went wrong.');
  
  // const stationId=location?.state?.id
  const btnsList = useMemo(() => {
    return [
      {
        buttonText: mode === 'Edit' ? 'Update' : 'Add',
        buttonId: 'add',
        btnClassName: disableButton() ? 'primary__btn disabled' : 'primary__btn',
        handleClick: (): void => {
          handleSubmit?.(mode)
        },
        isDisabled: disableButton(),
        buttonVariant: 'filled',
      },
      {
        buttonText: 'Cancel',
        buttonId: 'cancel',
        btnClassName: 'secondary__btn',
        handleClick: (): void => {
          if (location?.state?.from === '/customer')
            navigate(location?.state?.from, { state: { ...location?.state, mode: location?.state?.customerMode } })
          else if(location?.state?.from === '/station') 
             navigate(location?.state?.from, { state: { ...location?.state, mode: location?.state?.chargingStationMode,rowData: location?.state?.stationData} })
          else
            navigate('/manage-chargers');
        },
        isDisabled: false,//disableCancelButton(),
        buttonVariant: 'outlined',
      },
    ];
  }, [addChargerFormData, disableButton, mode, addChargerStateData]);

  const handleSubmit = useCallback(async (mode) => {
    // Validate latitude here
    const latitudeValue = parseFloat(addChargerStateData.latitude);
    if (isNaN(latitudeValue) || latitudeValue < -90 || latitudeValue > 90) {
      toast.error('Latitude should be in range of [-90,90]');
      return;
    }
    // Validate longitude here
    const longitudeValue = parseFloat(addChargerStateData.longitude);
    if (isNaN(longitudeValue) || longitudeValue < -180 || longitudeValue >= 180) {
      toast.error('Longitude should be in range of [-180,180)');
      return;
    }
    setShowLoader(true)

    let response: AxiosResponse
    if (mode === 'Edit') {
      response = await dispatch(updateCharger({ ...addChargerFormData, chargerId: location?.state?.rowData?.id }))

    } else {
      response = await dispatch(createCharger(addChargerFormData));
    }
    if (response?.status === 200 || response?.status === 202) {
      setShowLoader(false)
      chargerAddedToast();
      setTimeout(() => {
        if (location?.state?.from === '/customer') 
        navigate(location?.state?.from, { state: { ...location?.state, mode: location?.state?.customerMode } })
        else if(location?.state?.from === '/station') 
        navigate(location?.state?.from, { state: { ...location?.state, mode: location?.state?.chargingStationMode,rowData: location?.state?.stationData } })        
        else navigate('/manage-chargers');
      }, 1000)
    }
    else if (response?.data === 'Charger with same Id exists') {
      setShowLoader(false)
      duplicateChargerToast();
    } else {
      setShowLoader(false)
      somethingWentWrongToast();
    }
  }, [addChargerStateData, addChargerFormData])

  const keyPressValidationLatLong = (event): void => {
    const { key, target } = event;

    // Allow dot (.) for decimal values
    if (key === '.' && target?.value?.includes('.')) event.preventDefault();
    if (key === '-' && target?.selectionStart === 0 && target?.selectionEnd === 0 && !target?.value?.includes('-')) {
      // Allow minus sign (-) only if it's the first character and the input field is empty
      // This condition checks if the cursor is at the beginning of the input field
      // and if the input field does not already contain a minus sign
      return;
    }
    // Check for numeric keys, backspace, and allow CMD+V (for Mac) or CTRL+V (for Windows/Linux)
    if (key === ' ' || (isNaN(Number(key)) && key !== '.' && key !== 'Backspace' && !(event.metaKey || event.ctrlKey && key.toLowerCase() === 'v'))) {
      event.preventDefault();
    }
  };

  function checkValidLatLong(value: string): string {
    // Regular expression to match a decimal number
    return /^[-+]?\d*\.?\d+$/.test(value + '0') ? value : '';
  }

  //TODO: Key Press Validation for MAC Address
  const keyPressValidationForMACAddress = (event): void => {
    const keyPressed = event.key;
    // Allow Ctrl+C (copy) and Ctrl+V (paste) and Backspace
    if (keyPressed === 'Backspace' || ((event.ctrlKey || event.metaKey) && (keyPressed.toLowerCase() === 'c' || keyPressed.toLowerCase() === 'v'))) return;
    // Check Only HEx and allow - and : 
    if (!(/^[0-9a-fA-F\-:]+$/.test(keyPressed))) event.preventDefault();
  }

  function checkValidMAC(value: string): string {
    // Regular expression to validate a MAC address
    return /^[0-9a-fA-F\-:]+$/.test(value) ? value : ''
  }

  const formArray: IFormContentProp[] = [
    {
      rowCountClassName: 'grid-3',
      formObject: [
        {
          formLabel: 'Charger Serial Number',
          isRequired: true,
          objectType: 'input',
          selectDropdownProps: null,
          inputProps: {
            inputPlaceHolder: mode !== 'View' ? 'Enter Serial Number' : '',
            handleInputChange: (event): void => {
              handleFormDataChange(event?.target?.value, 'visibleId', 'chargerSerialNumber');
            },
            inputValue: addChargerStateData?.chargerSerialNumber,
            inputType: 'string',
            inputFieldId: 'adding_charger_serial_name',
            isRequired: false,
            isDisabled: mode !== 'Add',
            autoFocus: true,
          },
          textAreaProps: null,
        },
        {
          formLabel: 'MAC Address',
          isRequired: true,
          objectType: 'input',
          selectDropdownProps: null,
          inputProps: {
            inputPlaceHolder: mode !== 'View' ? 'Enter MAC Address' : '',
            handleInputChange: (event): void => {
              handleFormDataChange(checkValidMAC(event?.target?.value), 'macId', 'macIdValue');
            },
            inputValue: addChargerStateData?.macIdValue,
            inputType: 'string',
            inputFieldId: 'add_charger_mac_address',
            handleKeyPress: keyPressValidationForMACAddress,
            isRequired: false,
            isDisabled: mode === 'Edit' ? !(chargerDetail?.isStationEditable) : mode !== 'Add'
          },
          textAreaProps: null,
        },
        {
          formLabel: 'Customer',
          isRequired: true,
          objectType: 'dropdown',
          selectDropdownProps: {
            selectValue: addChargerStateData?.customerValue,
            selectOptions: removeAllFromReferenceData(customerReferenceData) as IGeneralReferenceData[] || [],
            handleSelectOptionChange: (val): void => {
              handleFormDataChange(val, 'customer', 'customerValue');
            },
            selectDropDownId: 'charging-model-dropdown',
            selectDropDownName: 'charging-model-dropdown',
            isDisabled: mode === 'Edit' ? !chargerDetail?.isCustomerEditable : ( mode !== 'Add' || location?.state?.name )
          },
          inputProps: null,
          textAreaProps: null,
        },
      ],
    },
    {
      rowCountClassName: 'grid-3',
      formObject: [
        {
          formLabel: 'Latitude',
          isRequired: true,
          objectType: 'input',
          selectDropdownProps: null,
          inputProps: {
            inputPlaceHolder: mode !== 'View' ? 'Enter latitude' : '',
            handleInputChange: (event): void => {
              handleFormDataChange(checkValidLatLong(event?.target?.value), 'latitude', 'latitude');
            },
            inputValue: addChargerStateData?.latitude,
            inputFieldId: 'add_charger_latitude',
            isRequired: true,
            handleKeyPress: keyPressValidationLatLong,
            isDisabled: mode === 'View'
          },
          textAreaProps: null,
        },
        {
          formLabel: 'Longitude',
          isRequired: true,
          objectType: 'input',
          selectDropdownProps: null,
          inputProps: {
            inputPlaceHolder: mode !== 'View' ? 'Enter longitude' : '',
            handleInputChange: (event): void => {
              handleFormDataChange(checkValidLatLong(event?.target?.value), 'longitude', 'longitude');
            },
            inputValue: addChargerStateData?.longitude,
            inputFieldId: 'adding_station',
            isRequired: true,
            handleKeyPress: keyPressValidationLatLong,
            isDisabled: mode === 'View'
          },
          textAreaProps: null,
        },
        {
          formLabel: 'Charging Station',
          isRequired: true,
          objectType: 'dropdown',
          selectDropdownProps: {
            selectValue: addChargerStateData?.chargingStationValue,
            selectOptions: (addChargerFormData?.customer?.length>0 ? chargingStationOptions || [] : [] ),
            handleSelectOptionChange: (val): void => {
              handleFormDataChange(val, 'chargingStation', 'chargingStationValue');
            },
            selectDropDownId: 'charging-model-dropdown',
            selectDropDownName: 'charging-model-dropdown',
            isDisabled: mode === 'Edit' ? !(chargerDetail?.isCustomerEditable || chargerDetail?.isStationEditable) : (mode !== 'Add' || location?.state?.stationName)
          },
          inputProps: null,
          textAreaProps: null,
        },
      ],
    },
    {
      rowCountClassName: '',
      formObject: [
        {
          formLabel: 'Notes',
          isRequired: false,
          objectType: 'text-area',
          selectDropdownProps: null,
          inputProps: null,
          textAreaProps: {
            inputPlaceHolder: mode !== 'View' ? 'Type here' : '',
            handleInputChange: (event): void => {
              handleFormDataChange(event?.target?.value, 'remarks', 'remarks');
            },
            isRequired: false,
            inputValue: addChargerStateData?.remarks,
            inputFieldId: 'adding_station',
            isDisabled: mode === 'View'
          },
        },
      ],
    },
  ];

  // TODO: BreadCrumb navigation Props
  const createBreadCrumbsNavigation = useCallback((): BreadCrumbObject[] => {
    let breadCrumbArray: IBreadCrumb[] = [];
    if (location?.state?.from ==='/customer') {
      const customerListNavigation: IBreadCrumb[] = [
        {
          objectType: 'link',
          id: 'customers',
        },
        {
          objectType: 'link',
          id: 'editCustomer',
          linkBtnState: { ...location?.state, mode: location?.state?.customerMode },
        }
      ]
      breadCrumbArray = breadCrumbArray?.concat(customerListNavigation)
    }
    else if(location?.state?.from ==='/station'){
      const chargingStationListNavigation: IBreadCrumb[] = [
        {
          objectType: 'link',
          id: 'chargingStation',
        },
        {
          objectType: 'link',
          id: 'editChargingStation',
          linkBtnState: { ...location?.state, mode: location?.state?.chargingStationMode,rowData: location?.state?.stationData },
        }
      ]
      breadCrumbArray = breadCrumbArray?.concat(chargingStationListNavigation)
    }
    else {
      const chargerNavigation: IBreadCrumb[] = [{ objectType: 'link', id: 'chargers' }]
      breadCrumbArray = breadCrumbArray?.concat(chargerNavigation)
    }
    breadCrumbArray.push({
      objectType: 'text',
      id: 'text',
      text: mode != 'Add' ? location?.state?.rowData?.visibleId : 'Add Charger'
    })
    return createBreadCrumbProps({ breadCrumbProps: breadCrumbArray })
  }, [location?.state])


  const customFormProps: ICustomFormProps = useMemo(() => {
    return {
      formContainerClassName: 'add__charger__wrap',
      formArray: formArray,
    };
  }, [formArray, btnsList, mode])

  // TODO: Clear all redux states on unmount
  useEffect(() => {
    return (): void => {
      // Partial<ChargerAction> 
      const chargerAction = {
        type: chargerTypes.CLEAR_CHARGER_DETAIL,
      }
      dispatch(chargerAction);
    }
  }, [])
  const toolTipText = addChargerFormData?.visibility === 'Enabled' ? 'Active chargers would be listed.' : 'Inactive chargers would not be listed. ';
  return (
    <>
      <DashboardLoader showLoader={showLoader} />
      <div className='charger__form__wrap'>
        <CustomBreadCrumbs
          containerId={'add-charger-breadcrumb'}
          containerClassName={'add-charger-breadcrumb'}
          breadCrumbs={createBreadCrumbsNavigation()}
        />
        <div className='charger__form__wrap-header'>
          <DashboardHeader
            header={mode && mode !== 'Add' ? location?.state?.rowData?.visibleId : 'Add Charger'}
            headerClassName='charger__form__wrap-header-text'
          />
          {mode !== 'Add' && access?.hasChargerVisibilityEditAccess() && <><IconWithTooltip iconClassName='rms_info_icon-black' toolTipText={toolTipText} containerClassName='charger__form_header-status-info' />
            <CustomSwitch
              switchId='charger__form__status-switch'
              switchClassName='charger__form__status-switch'
              checked={addChargerFormData?.visibility === 'Enabled'}
              //switchLabelName='Active'
              disableSwitchLabelName={addChargerFormData?.visibility === 'Disabled' ? 'Disabled' : 'Enabled'}
              handleClick={() => { handleFormDataChange(addChargerFormData?.visibility === 'Enabled' ? 'Disabled' : 'Enabled', 'visibility', 'visibility') }}
              isDisabled={mode === 'View'}
            />
          </>
          }
        </div>
        <div className='charger__form__wrap-content'>
          <CustomForm {...customFormProps} />
        </div>
        <div className='charger__form__wrap-footer'>
          <CustomButtonGroup
            buttonsList={mode === 'View' ? btnsList?.filter((button) => button?.buttonId === 'cancel') : btnsList}
            buttonGroupClassName='button__group__footer' />
        </div>
      </div>


    </>
  );
};

export default AddCharger;