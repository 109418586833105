import { type GridRenderCellParams, GridValidRowModel } from '@mui/x-data-grid-pro';
import { cloneDeep } from 'lodash';
import React, { ReactNode, memo, useCallback, useEffect, useMemo, useRef} from 'react';
import {
  alarmCardMapper,
  CustomStatus,
  CustomTooltipWithLabel,
  FilterProp,
  formatRelativeTimeForTable,
  LinkButton,
  TableDashboard,
  useAppDispatch,
  useAppSelector,
} from '../../../globalUtils/globalExports';
import {
  chargerTypes,
  fetchCustomerChargersData,
  getCustomerReducer,
  getCurrentFiltersValues,
  getFilterReducer,
  getFilterSelectedValue,
  getReferenceDataReducer,
  setFilterData,
  customerTypes,
  setCustomerTableProps,
} from '../../../rmsReduxStore/reduxExports';
import '../../chargers/chargers.css';
import { IconWithTooltip } from '../../../globalUtils/globalIcons';
import { NoDataComponentChargers } from '../../../globalUtils/TableDashboard/TableComponents';

interface ChargerListingProps {
  customerId: string | null
}

const ChargerListing = memo(function ChargerListing(props: ChargerListingProps) {
  const { customerId } = props
  const dispatch = useAppDispatch();
  const { chargers, chargerCount, loader, customerChargerListTableProps, } = useAppSelector(getCustomerReducer);
  const {
    chargerStatusReferenceData,
    chargerLocationReferenceData,
    softwareVersionReferenceData,
    alarmSeverityReferenceData
  } = useAppSelector(getReferenceDataReducer);
  const { screenFilters } = useAppSelector(getFilterReducer)
  const chargerListTablePropsRef = useRef(customerChargerListTableProps);
  const chargersRef = useRef(chargers);

  // ********************************************
  // TODO: Callback wasn't taking the updated value
  useEffect(() => {
    chargerListTablePropsRef.current = customerChargerListTableProps;
  }, [customerChargerListTableProps]);

  useEffect(() => {
    chargersRef.current = chargers;
  }, [chargers]);

  // TODO: Handles global search box
  // In config - pass id, classname and onChange
  // In onChange - just add a callback rest is handled in search box component
  const onChangeOfSearchText = useCallback(async (val) => {
    if (chargerListTablePropsRef?.current) {
      await layoutCallback(1, chargerListTablePropsRef?.current?.pageSize, chargerListTablePropsRef?.current?.view, chargerListTablePropsRef?.current?.sortBy, chargerListTablePropsRef?.current?.order, null, val)
    }
  }, [chargerListTablePropsRef?.current, chargersRef?.current]);

  const searchBoxConfiguration = useMemo(() => {
    return {
      searchFieldId: 'manage-chargers-search-box',
      searchFieldName: 'manage-chargers-search-box',
      handleSearch: onChangeOfSearchText,
    };
  }, [onChangeOfSearchText, chargerListTablePropsRef?.current]);

  // TODO: Table Layout Callbacks
  const mobileViewConfiguration = useMemo(() => {
    return {
      headerDataConfig: {
        headerLeftDataConfig: ['visibleId', 'model'],
        headerRightDataConfig: {
          actionIconsComponent: false,
          statusIconComponent: true,
        },
      },
      statusIconKey: 'status',
      statusIconMapper: {
        'Online': 'status__online__charger__icon',
        'Offline': 'status__offline__charger__icon'
      },
      statusIconBackgroundColorMapper: {
        'Online': 'var(--green-color)',
        'Offline': 'var(--grey-color)'
      },
      contentDataConfig: [
        [
          {
            key: 'softwareVersion',
            iconClassName: 'chargers__icon',
          },
          {
            key: 'customerName',
            iconClassName: 'customer__management__icon',
          },
        ],
        [
          {
            key: 'chargerSwVersion',
            iconClassName: 'alarm__management__icon  ',
          },
          {
            key: 'connectorType',
            iconClassName: 'navbar__logout__icon  ',
          },
        ],
      ],
    };
  }, []);

  const getTableRows = useCallback((): ICharger[] => {
    return chargers !== null && chargers?.length > 0 ? chargers : [];
  }, [chargers]);

  const chargerColumns = useMemo(() => {
    return [
      {
        field: 'visibleId',
        flex: 1,
        headerName: 'Charger',
        minWidth: 150,
        renderCell: (params: GridRenderCellParams<GridValidRowModel>): JSX.Element => {
          const rowData = params?.row;
          return (
            <div
              className="individual__charger__wrap"
              onClick={(e) => {
                e?.stopPropagation()
                const action = {
                  type: chargerTypes.SET_CHARGER_REDUCER_STATE,
                  isChargerTablePropsRetained: true
                }
                dispatch(action)
              }
              }
            >
              <LinkButton
                linkBtnId={rowData?.visibleId}
                link={'/charger?chargerId=' + rowData.visibleId}
                btnClassName="individual__charger__text"
                btnText={rowData?.visibleId}
                linkBtnState={{ chargerId: rowData?.id, charger: rowData }}
              />
            </div>
          );
        },
      },
      {
        field: 'model',
        headerName: 'Model',
        minWidth: 150,
        editable: false,
        flex: 1,
      },
      {
        field: 'status',
        headerName: 'Status',
        minWidth: 90,
        flex: 0.8,
        editable: false,
        renderCell: (params: GridRenderCellParams<GridValidRowModel, Date>): JSX.Element => {
          return <CustomStatus
            statusText={params?.row?.status}
            statusIconClassName={params?.row?.status === 'Online' ? 'status__online__charger__icon' : 'status__offline__charger__icon'}
            backgroundColor={params?.row?.status === 'Online' ? 'var(--green-color)' : 'var(--grey-color)'} />;
        },
      },
      {
        field: 'customerName',
        headerName: 'Customer',
        flex: 1,
        sortable: true,
        minWidth: 100,
      },
      {
        field: 'city',
        headerName: 'Location',
        flex: 1,
        sortable: true,
        minWidth: 120,
      },
      {
        field: 'softwareVersion',
        headerName: 'Software Version',
        flex: 1,
        sortable: true,
        minWidth: 160,
      },
      {
        field: 'connectorTypes',
        headerName: 'Connectors',
        flex: 1,
        sortable: true,
        minWidth: 100,
        renderCell: (params: GridRenderCellParams<GridValidRowModel, Date>): JSX.Element => {
          const rowData = params?.row;
          const connectorTypes = rowData?.connectorTypes;
          const labelId = params?.row?.id
          return (
            <CustomTooltipWithLabel label={connectorTypes} labelId={labelId} />
          );
        },
      },
      {
        field: 'lastPingTime',
        headerName: 'Last Ping Time',
        flex: 1,
        sortable: true,
        minWidth: 80,
        renderCell: (params: GridRenderCellParams<GridValidRowModel>): ReactNode => {
          const rowData = params?.row;
          return rowData?.lastPingTime ? formatRelativeTimeForTable(new Date(rowData?.lastPingTime)) : '-';
        },
      },
      {
        field: 'alarmStatus',
        headerName: 'Alarm',
        flex: 1,
        headerAlign: 'center',
        align: 'center',
        sortable: true,
        minWidth: 80,
        maxWidth: 80,
        renderCell: (params: GridRenderCellParams<GridValidRowModel, Date>): JSX.Element => {
          const rowData = params?.row;
          const severityIcon = rowData?.alarmStatus === 'No Alarm' ? 'TOTAL' : rowData?.alarmStatus?.toUpperCase();
          const tooltipText = rowData?.alarmStatus === 'No Alarm' ? 'No Alarm' : alarmCardMapper[severityIcon]?.title;
          return (
            <div className="charger__connector__type__cell">
              <IconWithTooltip iconClassName={rowData?.alarmStatus === 'No Alarm' ? 'no_alarm_icon' : alarmCardMapper[severityIcon].alarmSummaryIcon}
                toolTipText={tooltipText} />

            </div>
          );
        },
      },
    ];
  }, []);

  const layoutCallback = useCallback(async (pageNumber: number, pageSize: number, view: string, field: string, order: string, filterData, searchTerm) => {
    const tableProps: IChargerListTableProps = cloneDeep(chargerListTablePropsRef.current)
    const filters = filterData ? filterData : getCurrentFiltersValues('customerCharger', true)
    const customerFilter: Filters = { key: 'customer', values: [customerId ?? ''] }
    filters.push(customerFilter)
    await dispatch(fetchCustomerChargersData({
      view: view ?? 'list',
      sortBy: field || 'alarmStatus',
      order: order || 'ascending',
      pageSize,
      pageNumber,
      filters: filters,
      searchTerm: searchTerm != null && searchTerm != undefined ? searchTerm : chargerListTablePropsRef?.current?.searchTerm
    }));
    if (tableProps) {
      tableProps.view = view || 'list'
      tableProps.sortBy = field || 'alarmStatus'
      tableProps.order = order || 'ascending';
      tableProps.pageNumber = pageNumber;
      tableProps.pageSize = pageSize;
      if (searchTerm != null && searchTerm != undefined) {
        tableProps.searchTerm = searchTerm;
      }
    }
    await dispatch(setCustomerTableProps(tableProps, 'chargerList'))
  }, [chargerListTablePropsRef?.current, dispatch]);


  // ********************************************
  // TODO: Filter side pop up 
  // onchange -> handles onchange of all the dropdowns, layout props -> pass the required dropdowns, footer button action
  const handleFilterDataChange = useCallback(async (val, filterKey, isGlobal) => {
    const res = await setFilterData(val, filterKey, 'customerCharger', 'SET', isGlobal ? isGlobal : false, true)(dispatch)
    if (res?.message === 'Action dispatched successfully') {
      if (chargerListTablePropsRef?.current) {
        await layoutCallback(1, chargerListTablePropsRef?.current?.pageSize, chargerListTablePropsRef?.current?.view, chargerListTablePropsRef?.current?.sortBy, chargerListTablePropsRef?.current?.order, res?.filters, chargerListTablePropsRef?.current?.searchTerm)
      }
    }
  }, [chargerListTablePropsRef?.current, dispatch, chargersRef?.current])

  const chargerFilterProps: FilterProp[] = useMemo(() => {
    return [
      {
        filterLabel: 'Location',
        filterType: 'dropdown',
        filterId: 'Location',
        filterDropdownProps: {
          selectValue: getFilterSelectedValue('location', 'customerCharger', false),
          selectOptions: chargerLocationReferenceData ?? [],
          handleSelectOptionChange: (val): void => {
            handleFilterDataChange(val, 'location', false)
          },
          selectDropDownId: 'charger-location-filter-dropdown',
          isMultiSelect: true
        }
      },
      {
        filterLabel: 'Status',
        filterType: 'dropdown',
        filterId: 'Status',
        filterDropdownProps: {
          selectValue: getFilterSelectedValue('chargerStatus', 'customerCharger', false),
          selectOptions: chargerStatusReferenceData ?? [],
          handleSelectOptionChange: (val): void => {
            handleFilterDataChange(val, 'chargerStatus', false)
          },
          selectDropDownId: 'charger-status-filter-dropdown',
          isMultiSelect: true
        }
      },
      {
        filterLabel: 'Alarm Status',
        filterType: 'dropdown',
        filterId: 'alarmStatus',
        filterDropdownProps: {
          selectValue: getFilterSelectedValue('alarmStatus', 'customerCharger', false),
          selectOptions: alarmSeverityReferenceData ?? [],
          handleSelectOptionChange: (val): void => {
            handleFilterDataChange(val, 'alarmStatus', false)
          },
          selectDropDownId: 'charger-severity-filter-dropdown',
          isMultiSelect: true
        }
      },
      {
        filterLabel: 'Software Version',
        filterType: 'dropdown',
        filterId: 'softwareVersion',
        filterDropdownProps: {
          selectValue: getFilterSelectedValue('softwareVersion', 'customerCharger', false),
          selectOptions: softwareVersionReferenceData ?? [],
          handleSelectOptionChange: (val): void => {
            handleFilterDataChange(val, 'softwareVersion', false)
          },
          selectDropDownId: 'charger-software-version-filter-dropdown',
          isMultiSelect: true
        }
      },
    ]
  }, [softwareVersionReferenceData, chargerLocationReferenceData, chargerStatusReferenceData, alarmSeverityReferenceData, screenFilters])

  // TODO: Redux state Clean up
  const chargerCleanUpStates = useCallback(() => {
    // : Partial<ChargerAction>
    const action = {
      type: customerTypes.CLEAR_CUSTOMER_CHARGER_LIST_DATA,
    }
    dispatch(action);
  }, [])


  useEffect(() => {
    window.addEventListener('beforeunload', chargerCleanUpStates);
    return (): void => {
      chargerCleanUpStates()
      window.removeEventListener('beforeunload', chargerCleanUpStates);
    }
  }, [])

  return (
    <>
      <TableDashboard
        tableId="manage-chargers-table"
        tableClassName="manage__chargers__table"
        searchBoxIncluded={true}
        searchBoxConfiguration={searchBoxConfiguration}
        gridColumns={chargerColumns}
        tableRows={getTableRows()}
        mobileViewConfiguration={mobileViewConfiguration}
        layoutView={'list'}
        listLayoutCallBack={layoutCallback}
        totalCount={chargerCount}
        showSkeleton={!chargers}
        totalCountText={'chargers'}
        showLoader={loader}
        customNoDataComponent={NoDataComponentChargers}
        pageNumber={customerChargerListTableProps?.pageNumber}
        tablePageSize={customerChargerListTableProps?.pageSize}
        excludeGlobalFilters={true}
        filterProps={chargerFilterProps}
        handleFilterDataChange={handleFilterDataChange}
        referenceDataCallbacksFilters={['location', 'status', 'softwareVersion', 'severity']}
      />
    </>
  );
})

export default ChargerListing;
