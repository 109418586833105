/* eslint-disable @typescript-eslint/no-explicit-any */
import { isAxiosError } from 'axios';
import hwaxios from '../api-service/HWAxios';
import { customerTypes } from '../reduxExports'
import { ICustomerForm } from '../../components/customerManagement/CustomerForm/CustomerForm';

const url = process.env.REACT_APP_SERVER_BASE_URL

// TODO: Customers List API
export const fetchCustomersData: any = (reqBody: unknown) => async (dispatch: (arg0: Partial<CustomerAction>) => void): Promise<void> => {
  try {
    const loaderStartAction: Partial<CustomerAction> = {
      type: customerTypes.SET_CUSTOMER_LOADER,
      loader: true
    }
    dispatch(loaderStartAction);
    const response = await hwaxios.post(url + '/customers/summary', reqBody)
    const action: Partial<CustomerAction> = {
      type: customerTypes.FETCH_CUSTOMERS_DATA,
      customers: response.data?.data?.customers,
      customerCount: response.data?.data?.customersCount,
    }
    dispatch(action)
    if (response?.status === 200) {
      const loaderEndAction: Partial<CustomerAction> = {
        type: customerTypes.SET_CUSTOMER_LOADER,
        loader: false
      }
      dispatch(loaderEndAction);
    }
  } catch (error) {
    const loaderEndAction: Partial<CustomerAction> = {
      type: customerTypes.SET_CUSTOMER_LOADER,
      loader: false
    }
    dispatch(loaderEndAction);
    console.error(error)
    throw error
  }
}

// TODO: Add customer
export const createCustomer: any = (reqBody: ICustomerForm) => async () => {
  try {
    const { data, status } = await hwaxios.post(url + '/customer', reqBody)
    return { data, status };
  } catch (error) {
    if (isAxiosError(error)) {
      if (error.response) {
        const data = error.response.data;
        const status = error.response.status;
        return { data, status };
      }
    } else {
      return {
        data: 'Something went wrong', status: 400
      }
    }
  }
}

export const updateCustomer: any = (reqBody: unknown) => async () => {
  try {
    const { data, status } = await hwaxios.put(url + '/customer', reqBody)
    return { data, status };
  } catch (error) {
    if (isAxiosError(error)) {
      if (error.response) {
        const data = error.response.data;
        const status = error.response.status;
        return { data, status };
      }
    } else {
      return {
        data: 'Something went wrong', status: 400
      }
    }
  }
}

export const createChargingStation: any = (reqBody: ICustomerChargingStation) => async () => {
  try {
    const { data, status } = await hwaxios.post(url + '/charging-station', reqBody)
    return { data, status };
  } catch (error) {
    if (isAxiosError(error)) {
      if (error.response) {
        const data = error.response.data;
        const status = error.response.status;
        return { data, status };
      }
    } else {
      return {
        data: 'Something went wrong', status: 400
      }
    }
  }
}

export const fetchUpdateHistory: any = (reqBody: unknown) => async (dispatch: (arg0: Partial<CustomerAction>) => void): Promise<void> => {
  try {
    const loaderStartAction: Partial<CustomerAction> = {
      type: customerTypes.SET_CUSTOMER_LOADER,
      loader: true
    }
    dispatch(loaderStartAction);
    const response = await hwaxios.post(url + '/customer/history', reqBody)
    const action: Partial<CustomerAction> = {
      type: customerTypes.FETCH_HISTORY_DATA,
      historyRecords: response?.data?.data?.history,
      historyRecordCount: response?.data?.data?.historyCount,
    }
    dispatch(action)
    if (response?.status === 200) {
      const loaderEndAction: Partial<CustomerAction> = {
        type: customerTypes.SET_CUSTOMER_LOADER,
        loader: false
      }
      dispatch(loaderEndAction);
    }
  } catch (error) {
    const loaderEndAction: Partial<CustomerAction> = {
      type: customerTypes.SET_CUSTOMER_LOADER,
      loader: false
    }
    dispatch(loaderEndAction);
    console.error(error)
    throw error
  }
}

// TODO: Delete customer with customerId=?
export const deleteCustomer: any = (reqBody: unknown) => async () => {
  try {
    const { data, status } = await hwaxios.delete(url + '/customer', { data: reqBody })
    return { data, status };
  } catch (error) {
    if (isAxiosError(error)) {
      if (error.response) {
        const data = error.response.data;
        const status = error.response.status;
        return { data, status };
      }
    } else {
      return {
        data: 'Something went wrong', status: 400
      }
    }
  }
}

// TODO: Individual Customer Details API
export const fetchIndividualCustomerDetail: any = (reqParams: unknown) => async (dispatch: (arg0: Partial<CustomerAction>) => void): Promise<void> => {
  try {
    const loaderStartAction: Partial<CustomerAction> = {
      type: customerTypes.SET_CUSTOMER_DETAIL_LOADER,
      individualCustomerDetailLoader: true
    }
    dispatch(loaderStartAction);
    const response = await hwaxios.get(url + '/customer', { params: reqParams })
    const action: Partial<CustomerAction> = {
      type: customerTypes.FETCH_CUSTOMER_DETAIL_DATA,
      individualCustomerDetail: response?.data?.data?.customer
    }
    dispatch(action)
    if (response?.status === 200) {
      const loaderEndAction: Partial<CustomerAction> = {
        type: customerTypes.SET_CUSTOMER_DETAIL_LOADER,
        individualCustomerDetailLoader: false
      }
      dispatch(loaderEndAction);
    }
  } catch (error) {
    const loaderEndAction: Partial<CustomerAction> = {
      type: customerTypes.SET_CUSTOMER_DETAIL_LOADER,
      individualCustomerDetailLoader: false
    }
    dispatch(loaderEndAction);
    console.error(error)
    throw error
  }
}


// TODO: Export to CSV/XLX 
export const exportToCsvForCustomerList: any = (reqParams: unknown) => async () => {
  try {
    const { data, status } = await hwaxios.post(url + '/customers/summary/export', reqParams)
    return { data, status };
  } catch (error) {
    if (isAxiosError(error)) {
      if (error.response) {
        const data = error.response.data;
        const status = error.response.status;
        return { data, status };
      }
    } else {
      return {
        data: 'Something went wrong', status: 400
      }
    }
  }
}


//TODO: 
export const setCustomerTableProps: any = (data, screen) => (dispatch: (arg0: Partial<CustomerAction>) => void) => {
  if (screen === 'customersList') {
    const action = {
      type: customerTypes.SET_CUSTOMER_LIST_TABLE_PROPS,
      customerListTableProps: data,
    };
    dispatch(action);
  } else if (screen === 'chargerList') {
    const action: Partial<CustomerAction> = {
      type: customerTypes.SET_CUSTOMER_CHARGER_LIST_TABLE_PROPS,
      customerChargerListTableProps: data,
    };
    dispatch(action);
  } else if (screen === 'editHistoryList') {
    const action: Partial<CustomerAction> = {
      type: customerTypes.SET_CUSTOMER_UPDATE_HISTORY_LIST_TABLE_PROPS,
      historyRecordTableProps: data,
    };
    dispatch(action);
  }
}

export const fetchCustomerChargersData: any = (reqBody: unknown) => async (dispatch: (arg0: Partial<CustomerAction>) => void): Promise<void> => {
  try {
    const loaderStartAction: Partial<CustomerAction> = {
      type: customerTypes.SET_CUSTOMER_LOADER,
      loader: true
    }
    dispatch(loaderStartAction);
    const response = await hwaxios.post(url + '/chargers', reqBody)
    const action: Partial<ChargerAction> = {
      type: customerTypes.FETCH_CUSTOMER_CHARGERS_DATA,
      chargers: response.data?.data?.chargers,
      chargerCount: response.data?.data?.chargerCount,
    }
    dispatch(action)
    if (response?.status === 200) {
      const loaderEndAction: Partial<CustomerAction> = {
        type: customerTypes.SET_CUSTOMER_LOADER,
        loader: false
      }
      dispatch(loaderEndAction);
    }

  } catch (error) {
    console.error(error)
    const loaderEndAction: Partial<CustomerAction> = {
      type: customerTypes.SET_CUSTOMER_LOADER,
      loader: false
    }
    dispatch(loaderEndAction);
    throw error
  }
}




