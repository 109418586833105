import { cloneDeep, isEqual } from 'lodash';
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import {
  DashboardHeader,
  useAppDispatch,
  useAppSelector,
  useEffectOnce,
  CustomRadioForm, CustomForm, CustomButtonGroup, FilterProp, CustomBreadCrumbs, DashboardLoader
} from '../../../globalUtils/globalExports';
import { createOrg, updateOrg, fetchChargerLocationReferenceData, fetchChargersByCustomers, fetchCustomerReferenceData, fetchIndividualOrgDetail, fetchOrgTypeReferenceData, getReferenceDataReducer, getUserReducer, userTypes } from '../../../rmsReduxStore/reduxExports';
import { useNavigate } from 'react-router-dom';
import { ICustomFormProps, IFormContentProp } from '../../../globalUtils/CustomForm/CustomForm';
import { useLocation } from 'react-router-dom';
import { Id, toast } from 'react-toastify';
import { checkCharactersPaste, checkPINCodePaste, createBreadCrumbProps, keyPressValidationForCharacters, keyPressValidationForPINCode } from '../../../globalUtils/globalHooks';
import './OrgForm.css'
import { AxiosResponse } from 'axios';
import { Icon } from '../../../globalUtils/globalIcons';
import access from '../../../auth/service/AccessControl';

interface OrgFormData {
  name: string
  orgType: IGeneralReferenceData | null
  addressLine1: string
  addressLine2: string
  pinCode: string
  city: string
  state: string
  chargerIds: string[]
  notes: string
}

const OrgForm: FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { customerReferenceData, chargerLocationReferenceData, orgTypeReferenceData } = useAppSelector(getReferenceDataReducer);
  const { chargerListByCustomer, individualOrgDetail } = useAppSelector(getUserReducer)

  const initialData: OrgFormData = {
    name: '',
    orgType: null,
    addressLine1: '',
    addressLine2: '',
    pinCode: '',
    city: '',
    state: '',
    chargerIds: [],
    notes: ''
  }

  const [orgFormData, setOrgFormData] = useState(initialData);
  const [initialOrgFormData, setInitialOrgFormData] = useState(initialData)
  const [mode, setMode] = useState(location?.state?.mode ?? 'View')
  const [selectedChargers, setSelectedChargers] = useState<string[]>();   // undefined when the OrgForm is opened
  const [selectedRadioOption, setSelectedRadioOption] = useState('SelectChargers');
  const [chargerCheckboxStates, setChargerCheckboxStates] = useState<string[]>([]);
  const [chargersChipValues, setChargersChipValues] = useState<string[]>([]);
  const [isSelectAllChargersChecked, setIsSelectAllChargersChecked] = useState(false)
  const [initialChargerByCustomers, setInitialChargerByCustomer] = useState<IChargerByCustomer[] | null>([])
  const [showLoader, setShowLoader] = useState(false);
  const [filters, setFilters] = useState<InnerFilterState[]>(
    [{
      key: 'customer',
      value: null
    }, {
      key: 'location',
      value: null
    }])

  useEffectOnce(() => {
    if (location?.state?.rowData) {
      setOrgFormData({
        ...orgFormData,
        name: location?.state?.rowData?.name,
      })
    }
    setMode(location?.state?.mode || 'View')
    chargerListCallback?.()
    dispatch(fetchCustomerReferenceData());
    dispatch(fetchChargerLocationReferenceData());
    dispatch(fetchOrgTypeReferenceData());
    setInitialChargerByCustomer(chargerListByCustomer)
    if (mode && location?.state?.orgId) {
      dispatch(fetchIndividualOrgDetail({ orgId: location?.state?.orgId }))
    }
  });

  useEffect(() => {
    loadInitialData()
  }, [individualOrgDetail])

  const loadInitialData = () => {
    if (individualOrgDetail) {
      const orgDetail = {
        name: individualOrgDetail?.name,
        addressLine1: individualOrgDetail?.addressLine1,
        addressLine2: individualOrgDetail?.addressLine2,
        pinCode: individualOrgDetail?.pinCode,
        city: individualOrgDetail?.city,
        state: individualOrgDetail?.state,
        orgType: {
          id: individualOrgDetail?.type,
          value: individualOrgDetail?.type,
          label: individualOrgDetail?.type,
          name: individualOrgDetail?.type,
        },
        chargerIds: individualOrgDetail?.chargerIds,
        notes: individualOrgDetail?.notes
      }
      setOrgFormData({
        ...orgFormData,
        ...orgDetail
      });
      setInitialOrgFormData({
        ...orgFormData,
        ...orgDetail
      })
      setSelectedRadioOption(individualOrgDetail?.chargerSelectMode ?? 'SelectChargers')
      setChargerCheckboxStates(individualOrgDetail?.chargerIds)
      setSelectedChargers(individualOrgDetail?.chargerIds);
      setChargersChipValues(individualOrgDetail?.chargerIds?.length == dataList?.filter((item) => !item.endsWith(')'))?.length ? [] : individualOrgDetail?.chargerIds);
      setIsSelectAllChargersChecked(individualOrgDetail?.chargerIds?.length == dataList?.filter((item) => !item.endsWith(')'))?.length)
    }
  }

  const chargerData = chargerListByCustomer?.map((item) => {
    return {
      header: item?.customerName,
      count: item?.chargers?.length,
      data: item?.chargers?.map((charger) => charger?.visibleId)
    }
  })

  const dataList = chargerData?.flatMap(({ header, count, data }) => [
    (header !== undefined && count !== undefined) ? `${header} (${count})` : '',
    ...data
  ]).filter(item => item !== '');

  useEffect(() => {
    setChargersChipValues(chargerCheckboxStates?.length == dataList?.filter((item) => !item.endsWith(')'))?.length ? [] : chargerCheckboxStates);
    setIsSelectAllChargersChecked(chargerCheckboxStates?.length == dataList?.filter((item) => !item.endsWith(')'))?.length)
  }, [dataList])

  const handleSelectedValues = (selectedChargers, selectedChargerChips, isChecked) => {
    setChargerCheckboxStates(selectedChargers)
    setSelectedChargers(selectedChargers);
    setChargersChipValues(selectedChargerChips);
    setIsSelectAllChargersChecked(isChecked)
  };

  useEffect(() => {
    if (selectedChargers) {
      const formData = cloneDeep(orgFormData);
      formData.chargerIds = selectedChargers;
      setOrgFormData(formData)
    }
  }, [selectedChargers]);

  const handleFormDataChange = useCallback((val, formKey) => {
    const formData = cloneDeep(orgFormData);
    // Handle later while passing as req body in add user api
    // (val?.id) ? formData[formKey] = val?.id : formData[formKey] = val; 
    formData[formKey] = val;
    setOrgFormData(formData);
  }, [orgFormData]);

  const disableSubmitButton = useCallback(() => {
    if (mode === 'Edit' && isEqual(orgFormData, initialOrgFormData)) {
      return true
    }
    return !(orgFormData?.name?.length > 0 && orgFormData?.orgType?.id /* && orgFormData?.chargerIds?.length > 0 && !(orgFormData?.chargerIds?.includes('ALL') && selectedRadioOption === 'SelectChargers') */);
  }, [orgFormData, selectedRadioOption, selectedChargers, mode, initialOrgFormData]);

  // TODO: Radio Button Configurations **********************************
  const radioButtonList = [
    { radioButtonLabel: 'All Chargers', radioButtonId: 'AllChargers', isDisabled: mode === 'View' },
    { radioButtonLabel: 'Select Chargers', radioButtonId: 'SelectChargers', isDisabled: mode === 'View' },
  ]

  const handleRadioOptionChange = (event, value) => {
    setSelectedRadioOption(value);
    if (value === 'AllChargers') setSelectedChargers(['ALL'])
    else { setSelectedChargers(chargerCheckboxStates) }
  };

  const radioButtonConfiguration = useMemo(() => {
    return {
      radionBtnGrpName: 'org__form_charger_options',
      buttonsList: radioButtonList,
      selectedRadioBtnValue: selectedRadioOption,
      handleSelectedRadioBtnChange: handleRadioOptionChange
    };
  }, [orgFormData, mode, selectedRadioOption, chargerCheckboxStates]);

  //TODO: Search Box Configuration *****
  const onSearchTextChange = useCallback((val) => {
    chargerListCallback?.(filters, val)
  }, [filters])

  const searchBoxConfiguration = useMemo(() => {
    return {
      searchFieldId: 'org__form_chargers-search-box',
      searchFieldName: 'org__form_chargers-search-box',
      isDisabled: mode === 'View',
      handleSearch: onSearchTextChange,
    };
  }, [onSearchTextChange, filters, orgFormData, mode]);

  // TODO: CHARGER LIST BY CUSTOMER CALLBACK *****
  const chargerListCallback = useCallback((filters?, searchTerm?) => {
    let filtersToBeSent: Filters[] = []
    if (filters && filters?.length > 0) {
      filtersToBeSent = filters?.map((item) => {
        return {
          key: item.key,
          values: item.value?.map((item) => item.id) || []
        }
      })
    }
    dispatch(fetchChargersByCustomers({
      filters: filters ? filtersToBeSent : [],
      searchTerm: searchTerm != null && searchTerm != undefined ? searchTerm : ''
    }));
  }, [])

  //  TODO: CHARGERS FILTERS *****
  const handleFilterDataChange = useCallback((val, filterkey) => {
    const filtersToUpdate = cloneDeep(filters);
    const selectedFilter = filtersToUpdate?.find((filter) => filter?.key === filterkey);
    if (selectedFilter) {
      selectedFilter.value = Array.isArray(val) ? val : [val]
    }
    setFilters(filtersToUpdate);
    chargerListCallback?.(filtersToUpdate, '')
  }, [filters, chargerListCallback, setFilters])

  const chargerFilterProps: FilterProp[] = useMemo(() => {
    return [
      {
        filterLabel: 'Customer',
        filterType: 'dropdown',
        filterId: 'Customer',
        filterDropdownProps: {
          selectValue: filters?.find((filter) => filter?.key === 'customer')?.value,
          selectOptions: customerReferenceData ?? [],
          handleSelectOptionChange: (val): void => {
            handleFilterDataChange(val, 'customer')
          },
          selectDropDownId: 'charger-customer-filter-dropdown',
          isMultiSelect: true,
          isDisabled: mode === 'View'
        }
      },
      {
        filterLabel: 'Location',
        filterType: 'dropdown',
        filterId: 'Location',
        filterDropdownProps: {
          selectValue: filters?.find((filter) => filter?.key === 'location')?.value,
          selectOptions: chargerLocationReferenceData ?? [],
          handleSelectOptionChange: (val): void => {
            handleFilterDataChange(val, 'location')
          },
          selectDropDownId: 'charger-location-filter-dropdown',
          isMultiSelect: true,
          isDisabled: mode === 'View'
        }
      },
    ]
  }, [chargerLocationReferenceData, customerReferenceData, filters, mode])

  const findChargerIdByVisibleId = useCallback((chargerVisibleIds) => {
    const chargeIds: string[] = [];
    initialChargerByCustomers?.forEach(customer => {
      customer?.chargers.forEach(charger => {
        if (chargerVisibleIds?.includes(charger?.visibleId)) {
          chargeIds.push(charger?.id);
        }
      });
    });
    return chargeIds;
  }, [initialChargerByCustomers])

  const handleDropDownSelectedVal = (val) => {
    if (!val || val?.id) return val;
    return { id: val, name: val, label: val, value: val }
  }

  const orgFormSuccessToast = (): Id => toast.success(`Org ${mode}ed.`);
  const orgFormErrorToast = (message): Id => toast.error(message);

  //TODO: FORM FOOTER BUTTONS ******
  const footerButtonList = useMemo(() => {
    return [
      {
        buttonText: mode === 'Edit' ? 'Update' : 'Add',
        buttonId: 'submit',
        btnClassName: disableSubmitButton() ? 'primary__btn disabled' : 'primary__btn',
        handleClick: (): void => {
          handleSubmit?.(mode)
        },
        isDisabled: disableSubmitButton(),
        buttonVariant: 'filled',
      },
      {
        buttonText: 'Cancel',
        buttonId: 'cancel',
        btnClassName: 'secondary__btn',
        handleClick: (): void => {
          navigate('/manage-org')
        },
        isDisabled: false,
        buttonVariant: 'outlined',
      },
    ];
  }, [disableSubmitButton, orgFormData, mode, selectedRadioOption]);

  // Profile Info *******************************

  const profileInfoFormArray: IFormContentProp[] = [
    {
      rowCountClassName: 'grid-3',
      formObject: [
        {
          formLabel: 'Name',
          isRequired: true,
          objectType: 'input',
          selectDropdownProps: null,
          inputProps: {
            inputPlaceHolder: 'Enter Username',
            handleInputChange: (event): void => {
              handleFormDataChange(event?.target?.value, 'name');
            },
            inputValue: orgFormData?.name,
            inputType: 'string',
            inputFieldId: 'add_user_name',
            isRequired: false,
            isDisabled: mode === 'View',
            autoFocus: true
          },
          textAreaProps: null,
        },
        {
          formLabel: 'Org Type',
          isRequired: true,
          objectType: 'dropdown',
          selectDropdownProps: {
            selectValue: handleDropDownSelectedVal(orgFormData?.orgType),
            selectOptions: orgTypeReferenceData as IGeneralReferenceData[] || [],
            handleSelectOptionChange: (val): void => {
              handleFormDataChange(val, 'orgType');
            },
            selectDropDownId: 'org-type-dropdown',
            selectDropDownName: 'org-type-dropdown',
            isDisabled: mode !== 'Add',
          },
          inputProps: null,
          textAreaProps: null,
        },
      ],
    },
    {
      rowCountClassName: 'grid',
      formObject: [
        {
          formLabel: 'Address',
          isRequired: false,
          objectType: 'input',
          selectDropdownProps: null,
          inputProps: {
            inputPlaceHolder: mode !== 'View' ? 'Enter Address Line 1' : '',
            handleInputChange: (event): void => {
              handleFormDataChange(event?.target?.value, 'addressLine1');
            },
            inputValue: orgFormData?.addressLine1,
            inputType: 'string',
            inputFieldId: 'org__form-addressLine1',
            isRequired: false,
            isDisabled: mode === 'View',
          },
          textAreaProps: null,
        }
      ]
    },
    {
      rowCountClassName: 'grid',
      formObject: [
        {
          formLabel: '',
          isRequired: false,
          objectType: 'input',
          selectDropdownProps: null,
          inputProps: {
            inputPlaceHolder: mode !== 'View' ? 'Enter Address Line 2' : '',
            handleInputChange: (event): void => {
              handleFormDataChange(event?.target?.value, 'addressLine2');
            },
            inputValue: orgFormData?.addressLine2,
            inputType: 'string',
            inputFieldId: 'org__form-addressLine2',
            isRequired: false,
            isDisabled: mode === 'View',
          },
          textAreaProps: null,
        }
      ]
    },
    {
      rowCountClassName: 'grid-4',
      formObject: [
        {
          formLabel: 'City',
          isRequired: false,
          objectType: 'input',
          selectDropdownProps: null,
          inputProps: {
            inputPlaceHolder: mode !== 'View' ? 'Enter City' : '',
            handleInputChange: (event): void => {
              handleFormDataChange(checkCharactersPaste(event?.target?.value), 'city');
            },
            inputValue: orgFormData?.city,
            inputType: 'string',
            inputFieldId: 'org__form-city',
            isRequired: false,
            isDisabled: mode === 'View',
            handleKeyPress: keyPressValidationForCharacters
          },
          textAreaProps: null,
        },
        {
          formLabel: 'State',
          isRequired: false,
          objectType: 'input',
          selectDropdownProps: null,
          inputProps: {
            inputPlaceHolder: mode !== 'View' ? 'Enter State' : '',
            handleInputChange: (event): void => {
              handleFormDataChange(checkCharactersPaste(event?.target?.value), 'state');
            },
            inputValue: orgFormData?.state,
            inputType: 'string',
            inputFieldId: 'org__form-state',
            isRequired: false,
            isDisabled: mode === 'View',
            handleKeyPress: keyPressValidationForCharacters,
          },
          textAreaProps: null,
        },
        {
          formLabel: 'PIN Code',
          isRequired: false,
          objectType: 'input',
          selectDropdownProps: null,
          inputProps: {
            inputPlaceHolder: mode !== 'View' ? 'Enter PIN Code' : '',
            handleInputChange: (event): void => {
              handleFormDataChange(checkPINCodePaste(event), 'pinCode');
            },
            inputValue: orgFormData?.pinCode,
            inputType: 'string',
            inputFieldId: 'org__form-pinCode',
            isRequired: false,
            isDisabled: mode === 'View',
            handleKeyPress: keyPressValidationForPINCode,
          },
          textAreaProps: null,
        },

      ]
    },
    {
      rowCountClassName: '',
      formObject: [
        {
          formLabel: 'Notes',
          isRequired: false,
          objectType: 'text-area',
          selectDropdownProps: null,
          inputProps: null,
          textAreaProps: {
            inputPlaceHolder: mode !== 'View' ? 'Type here' : '',
            handleInputChange: (event): void => {
              handleFormDataChange(event?.target?.value, 'notes');
            },
            isRequired: false,
            inputValue: orgFormData?.notes,
            inputFieldId: 'org__form-notes',
            isDisabled: mode === 'View'
          },
        },
      ],
    },
  ];

  const profileInfoFormProps: ICustomFormProps = useMemo(() => {
    return {
      formContainerClassName: 'org__form__profile__info',
      subheading: 'Profile',
      formArray: profileInfoFormArray
    };
  }, [orgFormData, orgTypeReferenceData, mode])

  // TODO: BreadCrumb navigation Props
  const breadCrumbs = createBreadCrumbProps({
    breadCrumbProps:
      [
        {
          objectType: 'link',
          id: 'organisations',
        },
        {
          objectType: 'text',
          id: 'text',
          text: mode !== 'Add' ? orgFormData?.name : `${mode} Organisation`
        }
      ]
  })


  // TODO: FORM HEADER TEXT *****
  const setHeaderText = useCallback(() => {
    return mode !== 'Add' ? orgFormData?.name : `${mode} Organisation`
  }, [mode, orgFormData])

  const handleSubmit = useCallback(async (mode) => {
    setShowLoader(true)
    if (orgFormData?.chargerIds?.length > 0 && !orgFormData?.chargerIds?.includes('ALL')) {
      orgFormData.chargerIds = findChargerIdByVisibleId(orgFormData?.chargerIds)
    }
    const reqBody = {
      id: mode === 'Edit' ? location?.state?.orgId : null,
      name: orgFormData?.name,
      type: orgFormData?.orgType?.name,
      addressLine1: orgFormData?.addressLine1,
      addressLine2: orgFormData?.addressLine2,
      pinCode: orgFormData?.pinCode,
      city: orgFormData?.city,
      state: orgFormData?.state,
      chargerIds: orgFormData?.chargerIds,
      notes: orgFormData?.notes,
      chargerSelectMode: selectedRadioOption
    }
    let response: AxiosResponse
    if (mode === 'Edit') {
      response = await dispatch(updateOrg(reqBody))
    } else {
      response = await dispatch(createOrg(reqBody))
    }

    if ((response?.status === 200 || response?.status === 202) && response?.data?.message === 'Success') {
      setShowLoader(false)
      orgFormSuccessToast();
      navigate('/manage-org')
    } else {
      setShowLoader(false)
      orgFormErrorToast(response?.data?.message);
    }
  }, [orgFormData, mode,selectedRadioOption])

  // TODO: Redux state Clean up
  const orgFormCleanUpStates = useCallback(() => {
    const action = {
      type: userTypes.CLEAR_ORG_DETAIL_DATA,
    }
    dispatch(action);
  }, [])

  useEffect(() => {
    window.addEventListener('beforeunload', orgFormCleanUpStates);
    return (): void => {
      orgFormCleanUpStates()
      window.removeEventListener('beforeunload', orgFormCleanUpStates);
    }
  }, [])

  return (
    <>
      <DashboardLoader showLoader={showLoader} />
      <div className='org__form__wrap'>
        <CustomBreadCrumbs breadCrumbs={breadCrumbs} />
        <div className='org__form__wrap-header'>
          <DashboardHeader
            header={setHeaderText()}
            headerClassName='org__form__wrap-header-text'
          />
          {mode !== 'Add' && location?.state?.rowData?.type !== 'Customer' && access?.organisation?.edit() && <Icon iconClassName={mode === 'View' ? 'rms__pencil__icon' : 'rms__view__icon'} containerClassName='org__form_header-edit' onIconClick={() => {
            if (mode === 'Edit') loadInitialData()
            setMode(mode === 'View' ? 'Edit' : 'View')
          }
          } />}
        </div>
        <div className='org__form__wrap-content'>
          <CustomForm {...profileInfoFormProps} />
          <CustomRadioForm
            key={'org__form__charger-selection ' + radioButtonConfiguration?.selectedRadioBtnValue}
            formContainerClass={'org__form__charger-selection'}
            header={'Access'}
            dataList={dataList ?? []}
            individualCheckboxStates={chargerCheckboxStates}
            selectedChipValues={chargersChipValues}
            selectAllChecked={isSelectAllChargersChecked}
            handleSelectedData={handleSelectedValues}
            filterProps={chargerFilterProps}
            radioButtonConfiguration={radioButtonConfiguration}
            searchBoxConfiguration={searchBoxConfiguration}
            disableCheckBox={mode === 'View'}
            showRadioList={selectedRadioOption === 'SelectChargers'}
          />
        </div>
        <div className='org__form__wrap-footer'>
          <CustomButtonGroup
            buttonsList={mode === 'View' ? footerButtonList?.filter((button) => button?.buttonId === 'cancel') : footerButtonList}
            buttonGroupClassName='button__group__footer'
          />
        </div>
      </div>
    </>
  );
};

export default OrgForm;
