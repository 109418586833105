/* eslint-disable @typescript-eslint/strict-boolean-expressions */
import { GridValidRowModel, type GridRenderCellParams } from '@mui/x-data-grid-pro'
import React, { useCallback, useMemo, useState, ReactNode, memo, useEffect, useRef } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import {
  CustomTooltipWithLabel,
  FilterProp,
  TableDashboard,
  downloadFile,
  renderHeaderActionButtons,
  useAppDispatch,
  useAppSelector,
} from '../../globalUtils/globalExports'
import {
  chargingStationTypes,
  exportToCsvChargingStationData,
  fetchChargingStationData,
  getChargingStationReducer,
  getCurrentFiltersValues,
  getFilterReducer,
  getFilterSelectedValue,
  getGlobalReducer,
  getReferenceDataReducer,
  setFilterData,
  setTableColumnPrefrences,
  setTableListProps
} from '../../rmsReduxStore/reduxExports'
import './chargingStation.css'
import { ActionIcons } from '../../globalUtils/globalIcons'
import { NoDataComponentChargingStations } from '../../globalUtils/TableDashboard/TableComponents'
import access from '../../auth/service/AccessControl'
import { Menu, MenuItem } from '@mui/material'
import { Id, toast } from 'react-toastify';
import { cloneDeep } from 'lodash'
import { IMapFocusedData,MapTooltipData } from '../chargers/ManageChargersDashboard'
import { createBreadCrumbProps, formatNumberWithCommas } from '../../globalUtils/globalHooks'
import { setRedirectedStateForChargerFilters } from '../../rmsReduxStore/chargersRedux/chargerCreators'


const ChargingStationDashboard = memo(function ChargingStationDashboard() {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const location = useLocation()
  const { chargingStations,loader,chargingStationsCount,chargingStationTableProps} = useAppSelector(getChargingStationReducer)
  const { screenFilters } = useAppSelector(getFilterReducer)
  const {chargerLocationReferenceData} = useAppSelector(getReferenceDataReducer)
  const { screenTableColumnPrefrences } = useAppSelector(getGlobalReducer)
  const layouts = ['list','map']
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [layoutView, setLayoutView] = useState<string>('list')
  const [exportAnchorEl, setExportAnchorEl] = useState(null);
  const [exportingFile, setExportingFile] = useState(false);
  const [mapDataPoints, setMapDataPoints] = useState<{
    latitude: number;
    longitude: number;
    status: string,
    id: string,
    tooltipData: MapTooltipData
  }[]>();
  const [mapSelectedStationDataPoint, setMapSelectedStationDataPoint] = useState<IMapFocusedData>({ latitude: 0, longitude: 0, id: '' })
  const [individualChargerMapView, setIndividualChargerMapView] = useState(location?.state?.individualChargerMapView || false);
  const [defaultMapCenter, setDefaultMapCenter] = useState(true);
  const chargingStationTablePropsRef = useRef(chargingStationTableProps);


// ********************************************
  // TODO: Callback wasn't taking the updated value
  useEffect(() => {
    chargingStationTablePropsRef.current = chargingStationTableProps;
  }, [chargingStationTableProps]);

  useEffect(() => {
    if (layoutView === 'map') {
      refreshMapDataPoints(chargingStations)
    }
  }, [chargingStations]);


 
  // TODO: SearchBoxConfiguration
  const onChangeOfSearchText = useCallback(async (val) => {
    if (chargingStationTablePropsRef?.current) {
      await layoutCallback(1, chargingStationTablePropsRef?.current?.pageSize, chargingStationTablePropsRef?.current?.view, chargingStationTablePropsRef?.current?.sortBy, chargingStationTablePropsRef?.current?.order, null, val)
    }
    // if (layoutView === 'map') {
    //   await refreshMapDataPoints(chargersRef?.current)
    // }
  }, [layoutView, chargingStationTablePropsRef?.current]);

  const searchBoxConfiguration = useMemo(() => {
    return {
      searchFieldId: 'charging-station-search-box',
      searchFieldName: 'charging-station-search-box',
      handleSearch: onChangeOfSearchText
    }
  }, [onChangeOfSearchText,layoutView,chargingStationTablePropsRef?.current])

  // TODO: Adds action btns in header and their callbacks
  const headerActionBtns = renderHeaderActionButtons({
    actionBtns:
      [
        {
          id: 'add_new_btn',
          isAccessible: access?.chargingStation?.add(),
          headerActionBtnClick: (): void => {
            navigate('/station',{ state: { mode: 'Add' } })
          }
        },
        {
          
          id: 'export_csv_btn',
          isAccessible: access?.chargingStation?.export(),
          headerActionBtnClick: (event): void => {
            setExportAnchorEl(event.currentTarget);
          }
        }
      ]
  })

   // TODO: Map Layout callbacks
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const onMapIconClick = useCallback(async (event, data) => {
    const chargingStation: IChargingStation = data;
    const mapData: IMapFocusedData = {
      latitude: Number(chargingStation?.lat) || 0,
      longitude: Number(chargingStation?.lon) || 0,
      id: chargingStation?.id
    };
    setMapSelectedStationDataPoint(mapData)
    await refreshMapDataPoints([data])
    // setHeaderIndividualChargerMapView(charger?.customerName + ', ' + charger?.city);
    setLayoutView('map');
    setIndividualChargerMapView(true);
    setDefaultMapCenter(false)
    history.pushState(null, '', '/charging-stations');
    const tableProps: IChargerListTableProps = cloneDeep(chargingStationTablePropsRef.current)
    tableProps.searchTerm = ''
    dispatch(setTableListProps(tableProps))
  }, [mapSelectedStationDataPoint, defaultMapCenter,chargingStationTablePropsRef?.current]);

  const refreshMapDataPoints = useCallback(async (stationValue) => {
    try {
      const mapDataPoint = stationValue !== null && stationValue?.length > 0 && stationValue?.map((station) => {
        return {
          latitude: parseFloat(station?.lat),
          longitude: parseFloat(station?.lon),
          status: 'Online',
          id: station?.id,
          tooltipData: {
            id: station?.id, headerText: station.chargingStationName, headerOnClick: (e): void => {
              handleNameClick(e, station);
            },
            data: [
              { icon: 'map__tooltip__customer__icon', text: `${station.customerName}` },
              // { icon: 'map__tooltip__connector__icon', text: `${charger.connectorTypes}` },
              // { icon: chargerStatusIcon, text: `${charger?.alarmStatus === 'Critical' && charger?.status === 'Online' ? 'Faulty' : charger?.status}` },
            ],
          }
        };
      });
      if (mapDataPoint) {
        const filteredDataPoints = mapDataPoint.filter(i =>
          i.latitude != null && true && !isNaN(i.latitude) && i.longitude != null && true && !isNaN(i.longitude));
        setMapDataPoints(filteredDataPoints);
      } else {
        setMapDataPoints([]); // If there are no chargers coming from server.
      }
    } catch (error) {
      console.error('Error in refreshMapDataPoints:', error);
    }
  }, [chargingStationTablePropsRef?.current, setMapDataPoints]);

 // TODO: Edit and View Button click
 const onIconClick = (rowData, mode) => {
  navigate('/station', { state: { mode: mode, rowData: rowData } })
}

const handleNameClick = async (event, rowData) => {
  navigate('/station', { state: { stationId: rowData.id, mode: 'View', rowData: rowData } });
};
const handleChargerNavigation = async (rowData, column) => {
  let response: string
  if (column === 'chargers') {
    response = await setRedirectedStateForChargerFilters(true, null, null,rowData?.id)(dispatch)
  } else {
    response = await setRedirectedStateForChargerFilters(true, 'Faulty',null ,rowData?.id)(dispatch)
  }
  if (response === 'Action dispatched successfully') {
    navigate('/manage-chargers');
  }
}

const actionIconsList = useMemo(() => {
  return [
    {
      icon: 'sheildIcon',
      isAccessible: access?.chargingStation?.view(),
      action: onMapIconClick
    },
    {
      icon: 'pencilIcon',
      isAccessible: access?.chargingStation?.edit(),
      action: (_, rowData) => onIconClick(rowData, 'Edit')
    }];
}, [chargingStationTablePropsRef?.current]);

const mobileViewConfiguration = useMemo(() => {
  return {
    headerDataConfig: {
      headerLeftDataConfig: ['id', 'chargingStationName'],
      headerRightDataConfig: {
        actionIconsComponent: true,
        statusIconComponent: false
      }
    },
    contentDataConfig: [
      [
        {
          key: 'city',
          iconClassName: 'navbar__logout__icon  '
        },

        {
          key: 'customerName',
          iconClassName: 'customer__management__icon'
        }
      ],

      [
        {
          key: 'faultyChargersCount',
          iconClassName: 'chargers__icon'
        },
        {
          key: 'chargers',
          iconClassName: 'chargers__icon'
        }
      ]
    ]
  }
}, [])

  const columns = useMemo(() => {
    return [
      {
        field: 'visibleId',
        flex: 1,
        maxWidth: 500,
        headerName: 'Station ID',        
        minWidth: 100,
        hideable: false,
        renderCell: (params: GridRenderCellParams<GridValidRowModel>): JSX.Element => {
          const rowData = params?.row;
          return (
            <div
              className="individual__station__text"
              onClick={(e) => {
                e?.stopPropagation()
                handleNameClick(e, params.row);
              }
              }
            >
              {rowData?.visibleId}
            </div>
          );
        },
      },
      {
        field: 'chargingStationName',
        flex: 1,
        headerName: 'Name',        
        minWidth: 150,
      },
      {
        field: 'customerName',
        headerName: 'Customer',
        minWidth: 180,
        flex: 1,
        editable: false
      },
      {
        field: 'city',
        headerName: 'City',
        minWidth: 110,
        flex: 1
      },
      {
        field: 'chargers',
        headerName: 'Chargers',
        sortable: true,
        minWidth: 120,
        flex: 0.9,
        type: 'number',
        renderCell: (params: GridRenderCellParams<GridValidRowModel>): JSX.Element => {
          const rowData = params?.row;
          return (
            <div
              className="chargingStation__dashboard_link-text"
              onClick={(e) => {
                e?.stopPropagation()
                handleChargerNavigation(params.row, 'chargers');
              }
              }
            >
              {formatNumberWithCommas(rowData?.chargers)}
            </div>
          );
        },
      },
      {
        field: 'faultyChargersCount',
        headerName: 'Faulty Chargers',
        flex: 0.9,
        sortable: true,
        type: 'number',
        minWidth: 100,
        renderCell: (params: GridRenderCellParams<GridValidRowModel>): JSX.Element => {
          const rowData = params?.row;
          return (
            <div
              className="chargingStation__dashboard_link-text"
              onClick={(e) => {
                e?.stopPropagation()
                handleChargerNavigation(params.row, 'faultyChargersCount');
              }
              }
            >
              {formatNumberWithCommas(rowData?.faultyChargersCount)}
            </div>
          );
        },
      },
      {
        field: 'notes',
        headerName: 'Notes',
        minWidth: 100,
        flex: 1,
        sortable: false,
        renderCell: (params: GridRenderCellParams<GridValidRowModel>): JSX.Element => {
          const rowData = params?.row;
          return <CustomTooltipWithLabel labelId='charging__station-notes' label={rowData?.notes} />
        },
      },
      {
        field: 'actions',
        headerName: 'Actions',
        flex: 1,
        headerAlign: 'center',
        minWidth: 150,
        sortable: false,
   
        renderCell: (params: GridRenderCellParams<GridValidRowModel>): ReactNode => {
          const rowData = params?.row
          return (
            <div className="charging__station__action__icons__wrap">
              <ActionIcons actionIconsList={actionIconsList} data={rowData} />
            </div>
          )
        }
      }
    ]
  }, [])

  const getTableRows = useCallback((): ICharger[] => {
    return chargingStations !== null && chargingStations?.length > 0 ? chargingStations : [];
  }, [chargingStations]);

  const getMapData = useCallback(() => {
    if (mapDataPoints) {
      return mapDataPoints ?? []
    }
  }, [mapDataPoints])

  const layoutCallback = useCallback(async (pageNumber: number, pageSize: number, view: string, field: string, order: string, filterData, searchTerm) => {
    const tableProps: IChargerListTableProps = cloneDeep(chargingStationTablePropsRef.current)
    const filters = filterData ? filterData : getCurrentFiltersValues('chargingStations')
    await dispatch(fetchChargingStationData({
      view: view ?? layoutView,
      sortBy: field || chargingStationTablePropsRef?.current?.sortBy,
      order: order || chargingStationTablePropsRef?.current?.order,
      pageSize,
      pageNumber,
      filters: filters,
      searchTerm: searchTerm != null && searchTerm != undefined ? searchTerm : chargingStationTablePropsRef?.current?.searchTerm
    }));
    if (tableProps) {
      tableProps.view = view || layoutView
      tableProps.sortBy = field || chargingStationTablePropsRef?.current?.sortBy;
      tableProps.order = order || chargingStationTablePropsRef?.current?.order;
      tableProps.pageNumber = pageNumber;
      tableProps.pageSize = pageSize;
      if (searchTerm != null && searchTerm != undefined) {
        tableProps.searchTerm = searchTerm;
      }
    }
    await dispatch(setTableListProps(tableProps))
  }, [layoutView, chargingStationTablePropsRef?.current, dispatch]);

  // Handler For Column Prefrence Chnages
  const handleColumnPrefrenceChange = useCallback(async (operation, data) => {
    await dispatch(setTableColumnPrefrences('chargingStations', operation, data))
  }, [])


  const filterProps: FilterProp[] = useMemo(() => {
    return [
      {
        filterLabel: 'Location',
        filterType: 'dropdown',
        filterId: 'Location',
        filterDropdownProps: {
          selectValue: getFilterSelectedValue('location', 'chargingStations', false),
          selectOptions: chargerLocationReferenceData ?? [],
          handleSelectOptionChange: (val): void => {
            handleFilterDataChange(val, 'location', false)
          },
          selectDropDownId: 'location-filter-dropdown',
          isMultiSelect: true
        }
      }
    ]
  }, [chargerLocationReferenceData, screenFilters, chargingStationTablePropsRef?.current, layoutView])


  // ********************************************
  // onchange -> handles onchange of all the dropdowns, layout props -> pass the required dropdowns, footer button action
  const handleFilterDataChange = useCallback(async (val, filterKey, isGlobal) => {
    const res = await setFilterData(val, filterKey, 'chargingStations', 'SET', isGlobal ? isGlobal : false)(dispatch)
    if (res?.message === 'Action dispatched successfully') {
      if (chargingStationTablePropsRef?.current) {
        await layoutCallback(1, chargingStationTablePropsRef?.current?.pageSize, chargingStationTablePropsRef?.current?.view, chargingStationTablePropsRef?.current?.sortBy, chargingStationTablePropsRef?.current?.order, res?.filters, chargingStationTablePropsRef?.current?.searchTerm)
      }
    }
  }, [chargingStationTablePropsRef?.current, dispatch, chargingStations, layoutView])


  const renderExportMenuItem = (fileType: 'csv' | 'xlsx', label: string): JSX.Element => (
    <MenuItem key={fileType}>
      <div className='export__btn-option' onClick={(e) => {
        e?.stopPropagation();
        onExportBtnClick(fileType);
      }}>
        {label}
      </div>
    </MenuItem>
  );

   // ***********************************************************
  // TODO: Export Handlers
  const handleClose = (): void => {
    setExportAnchorEl(null);
  };

  const fileDownloadSuccessToast = useCallback((): Id => {
    setExportingFile(false);
    return toast.success('File downloaded.');
  }, []);

  const fileDownloadFailureToast = useCallback((): Id => {
    setExportingFile(false);
    return toast.warn('Something went wrong. Please export again.');
  }, []);


  const onExportBtnClick = useCallback(async (fileType: 'csv' | 'xlsx'): Promise<void> => {
    if (exportingFile) {
      // If already exporting, prevent another export
      return;
    }
    try {
      const response = await dispatch(exportToCsvChargingStationData({
        fileType: fileType,
        filters: getCurrentFiltersValues('chargingStations')
      }));
      if (response?.status === 202 || response?.status === 200) {
        downloadFile({ url: response?.data?.url });
        fileDownloadSuccessToast()
      } else {
        fileDownloadFailureToast();
        setExportAnchorEl(null);
      }
    } finally {
      // Ensure that exporting status is updated even if there's an error
      setExportingFile(false);
      setExportAnchorEl(null);

    }
  }, [exportingFile, fileDownloadSuccessToast, fileDownloadFailureToast])

  const chargerCleanUpStates = useCallback(() => {
    const action = {
      type: chargingStationTypes.CLEAR_CHARGING_STATION_LIST_DATA,
    }
    dispatch(action);
  },[])
  useEffect(() => {
    window.addEventListener('beforeunload', chargerCleanUpStates);
    return (): void => {
      chargerCleanUpStates()
      window.removeEventListener('beforeunload', chargerCleanUpStates);
    }
  }, [])

   // ***************************************************************
  // TODO : Toggle between map and list view & Handling state change using the callback passed in TableDashboard
  const handleLayoutToggle = useCallback(async (newLayoutVal,newIndividualChargerMapView?) => {
    if (newLayoutVal === 'map') {
      setDefaultMapCenter(true);
      setMapSelectedStationDataPoint({ latitude: 0, longitude: 0, id: '' });
      await refreshMapDataPoints(chargingStations)
    }
    setLayoutView(newLayoutVal);
    if (newIndividualChargerMapView !== undefined && individualChargerMapView != newIndividualChargerMapView) setIndividualChargerMapView(prevState => !prevState);     // when we click on Back button in Table Dashboard, we pass newIndividualChargerMapView as false 
  }, [chargingStations])


  const layoutsConfiguration = useMemo(() => {
    return [
      {
        toolTipText: 'List View',
        onIconClick: (): void => {
          handleLayoutToggle('list')
        },
        layout: 'list',
        iconClassName: 'rms__table__dashboard-list__view__icon'
      },
      {
        toolTipText: 'Map View',
        onIconClick: (): void => {
          handleLayoutToggle('map')
        },
        layout: 'map',
        iconClassName: 'rms__table__dashboard-map__view__icon'
      }
    ]
  }, [handleLayoutToggle])

   // TODO: BreadCrumb navigation Props
   const breadCrumbs = createBreadCrumbProps({
    breadCrumbProps:
      [
        {
          objectType: 'link',
          id: 'chargingStations',
          linkBtnState: location?.state,
          handleOnClick: () => {
            setLayoutView('list')
            setIndividualChargerMapView(false)
          }
        },
        {
          objectType: 'text',
          id: 'text',
          text: 'Charging Stations'
        }
      ]
  })

  return (
    <>
    <TableDashboard
      tableId="charging-station-table"
      tableClassName="charging__station__table"
      header="Charging Stations"
      searchBoxIncluded={true}
      searchBoxConfiguration={searchBoxConfiguration}
      gridColumns={columns}
      tableRows={getTableRows()}
      mapDataPoints={getMapData()}
      mobileViewConfiguration={mobileViewConfiguration}
      layoutView={layoutView}
      layoutsEnabled={true}
      layouts={layouts}
      layoutsConfiguration={layoutsConfiguration}
      listLayoutCallBack={layoutCallback}
      headerActionBtns={headerActionBtns}
      showSkeleton={!chargingStations}
      showLoader={loader}
      totalCount={chargingStationsCount}
      mapFocusedDataPoint={mapSelectedStationDataPoint}
      usedefaultMapCenter={defaultMapCenter}
      individualChargerMapView={individualChargerMapView}
      totalCountText={'charging stations'}
      customNoDataComponent={NoDataComponentChargingStations}
      filterProps={filterProps}
      handleFilterDataChange={handleFilterDataChange}
      referenceDataCallbacksFilters={['location']}
      pageNumber={chargingStationTableProps?.pageNumber}
      tablePageSize={chargingStationTableProps?.pageSize}
      sortField={chargingStationTableProps?.sortBy}
      sortOrder={chargingStationTableProps?.order}
      columnPrefrences={screenTableColumnPrefrences?.find((item) => item.screen === 'chargingStations')}
      handleColumnPrefrenceChange={handleColumnPrefrenceChange}
      breadCrumbs={breadCrumbs}
    />
    <Menu
        id="export-option-dropdown"
        anchorEl={exportAnchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        className='export__option__dropdown'
        sx={{ top: '30px' }}
        open={Boolean(exportAnchorEl)}
        onClose={handleClose}

      >
        {renderExportMenuItem('csv', 'To CSV')}
        {renderExportMenuItem('xlsx', 'To Excel')}
      </Menu>
      </>
  )
})

export default ChargingStationDashboard
