/* eslint-disable @typescript-eslint/no-unused-vars */
import { Popover, PopoverProps, PopoverVirtualElement } from '@mui/material';
import React, { FC, useEffect, useMemo, useRef, useState, KeyboardEvent } from 'react';
import './SidePopover.css';
import { Icon } from '../globalIcons';
import { CustomButtonGroup } from '../globalExports';


interface IFormButtonType {
  buttonText: string,
  buttonId: string,
  btnClassName: string,
  handleClick: ((event: React.MouseEvent<HTMLButtonElement> | KeyboardEvent<HTMLButtonElement>) => void) | undefined,
  isDisabled: boolean,
  buttonIconClassName?: string,
  buttonVariant: string, // 'filled' or 'outlined'
  customTheme?: unknown,
}
interface SidePopoverProps {
  id: string;
  open: boolean;
  anchorEl?:
  | null
  | Element
  | (() => Element)
  | PopoverVirtualElement
  | (() => PopoverVirtualElement);
  handleClose: () => void;
  headerText: string;
  children: JSX.Element,
  btnsList?: IFormButtonType[]
  containerClassName?: string,
}

const SidePopover: FC<SidePopoverProps> = (props) => {
  const { id, open, anchorEl, handleClose, headerText, children, btnsList, containerClassName } = props;


  return (
    <Popover
      className="side__popover__container"
      id={id}
      open={open}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      marginThreshold={0}
      classes={{ paper: 'side__popover__container-paper' }}
    >
      <div className={'side__filter__wrap ' + containerClassName}>
        <div className="side__filter__wrap-header">
          <div className="side__filter__wrap-header-text">{headerText}</div>
          <div className="side__filter__wrap-icon">
            <Icon
              iconClassName="filter__cancel__icon"
              onIconClick={() => handleClose()}
            />
          </div>
        </div>

        <div className="side__filter__wrap-content"  >
          {children && children}

        </div>
        {btnsList && <div className="side__filter__wrap-footer">
          <CustomButtonGroup
            buttonsList={btnsList ?? []}
            buttonGroupClassName={'button__group__footer'}
          />
        </div>}
      </div>
    </Popover>
  );
};

export default SidePopover;
