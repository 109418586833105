import { alarmTypes } from '../reduxExports';

const alarmsInitialState: AlarmsState = {
  alarms: null,
  alarmDashboardCount: null,
  alarmsCount: 0,
  alarmHistoryTableProps: {
    sortBy: 'severity',
    order: 'ascending',
    pageSize: 15,
    pageNumber: 1,
    searchTerm: null
  },
  activeAlarmTableProps: {
    view: 'list',
    sortBy: 'severity',
    order: 'ascending',
    pageSize: 15,
    pageNumber: 1,
    searchTerm: null
  },
  alarmFiltersAppliedFromRedirection: false,
};

const alarmsReducer = (
  state: AlarmsState = alarmsInitialState,
  action: AlarmsAction
): AlarmsState => {
  switch (action.type) {
    case alarmTypes.FETCH_ALARM_DATA:
      return {
        ...state,
        alarms: action.alarms,
        alarmsCount: action.alarmsCount
      };
    case alarmTypes.EXPORT_ALARM_DATA:
      return {
        ...state,
      };
    case alarmTypes.FETCH_ALARM_COUNT_DATA:
      return {
        ...state,
        alarmDashboardCount: action?.alarmDashboardCount
      };
    case alarmTypes.SET_ALARM_HISTORY_TABLE_PROPS:
      return {
        ...state,
        alarmHistoryTableProps: action?.alarmHistoryTableProps
      };
    case alarmTypes.SET_ACTIVE_ALARM_TABLE_PROPS:
      return {
        ...state,
        activeAlarmTableProps: action?.activeAlarmTableProps
      };
    case alarmTypes.CLEAR_ALARM_DATA:
      return {
        ...state,
        // alarms: null,
        // alarmDashboardCount: null,
        // alarmsCount: 0,
        alarmHistoryTableProps: {
          sortBy: state.alarmHistoryTableProps?.sortBy || 'severity',
          order: state.alarmHistoryTableProps?.order || 'ascending',
          pageSize: state.alarmHistoryTableProps?.pageSize || 15,
          pageNumber: state.alarmHistoryTableProps?.pageNumber || 1,
          searchTerm: null
        },
        activeAlarmTableProps: {
          view: 'list',
          sortBy:state.activeAlarmTableProps?.sortBy || 'severity',
          order: state.activeAlarmTableProps?.order || 'ascending',
          pageSize: state.activeAlarmTableProps?.pageSize || 15,
          pageNumber: state.activeAlarmTableProps?.pageNumber || 1,
          searchTerm: null
        },
      };
    case alarmTypes.SET_FILTERS_APPLIED_FROM_REDIRECTION:
      return {
        ...state,
        alarmFiltersAppliedFromRedirection: action.alarmFiltersAppliedFromRedirection
      }
    default:
      return state;
  }
};

export default alarmsReducer;
