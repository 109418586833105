import { CountryCode,CountryCallingCode ,isValidPhoneNumber } from 'libphonenumber-js';
import { MuiOtpInput } from 'mui-one-time-password-input';
import React, { useCallback, useEffect, useState, type FC } from 'react';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/material.css'
import { useLocation, useNavigate } from 'react-router-dom';
import { DashboardLoader } from '../../globalUtils/globalExports';
import HWLocalStorage from '../../rmsReduxStore/api-service/HWLocalStorage';
import authService from '../service/AuthService';
import './LoginScreen.css';
import { allowedCountries, getCurrentCountryCode } from '../../globalUtils/globalHooks';

interface LoginScreenProps {
  showOtpScreen: boolean;
}

// TODO: Handles User Login and OTP
const LoginScreen: FC<LoginScreenProps> = ({ showOtpScreen }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [phoneNumber, setPhoneNumber] = useState(' ');
  const [countryCode,setCountryCode] = useState('');
  const [isValidPhone, setIsValidPhone] = useState(false);
  const [touched, setTouched] = useState(false);
  const [otp, setOtp] = useState('');
  const [errorMessage, setErrorMessage] = useState<string>();
  const [showRefreshButton, setShowRefreshButton] = useState(false);
  const [showLoginLoader, setShowLoginLoader] = useState(false)

  // otp screen handlers..
  const handleOtpChange = useCallback(
    (newValue: string): void => {
      if (errorMessage && errorMessage?.length > 0) {
        setErrorMessage('');
      }
      setOtp(newValue);
    },[errorMessage]);

  const handleOtp = useCallback(async (req) => {
    setShowLoginLoader(true);
    const res = await authService.generateOtp(req);
    if (res?.status === 200 || res?.status === 202) {
      HWLocalStorage.save('sessionId', res?.data?.sessionId);
      HWLocalStorage.save('mobileNumber', res?.data?.mobileNumber);
      HWLocalStorage.save('countryCode',res?.data?.countryCode)
      setShowLoginLoader(false);
      navigate('/otp-validation');
    } else {
      setShowLoginLoader(false);
      if (res?.data === 'User not found.') {
        setErrorMessage('This phone number is not registered with us.');
      } else if(res?.data === 'You do not have access to the RMS platform.' || res?.data === 'OTPs request limit reached, Please try again after 30 minutes.') {
        setErrorMessage(res?.data);
      } else {
        setErrorMessage('Something went wrong.');
      }
    }
  }, []);

  const handleComplete = useCallback(async (val) => {
    const req = {
      sessionId: HWLocalStorage.load('sessionId'),
      mobileNumber:  HWLocalStorage.load('mobileNumber'),
      countryCode:  HWLocalStorage.load('countryCode'),
      otp: val,
    };
    handleLogin(req);
  }, []);

  const handleClickOfGetOtpBtn = useCallback(async () => {
    const val = phoneNumber?.slice(countryCode?.length);
    const req = { mobileNumber: val,countryCode:countryCode };
    handleOtp(req);
  }, [navigate, phoneNumber]);

  // phone number screen handlers
  const onMobileNumberChange = useCallback((value: string,data:{countryCode:CountryCode,dialCode:CountryCallingCode}): void => {
    setPhoneNumber(value);
    setCountryCode(data?.dialCode)
    if (errorMessage && errorMessage?.length > 0) {
      setErrorMessage('');
    }
    console.log(value,data)
    setIsValidPhone(isValidPhoneNumber(`+${value}`, data.countryCode));
    if(!isValidPhoneNumber(`+${value}`, data.countryCode) && value?.slice(data?.dialCode?.length)?.length > 0) setErrorMessage('Please enter a valid mobile number.')
    if (isValidPhoneNumber(`+${value}`, data.countryCode)) {
      const req = { mobileNumber: value?.slice(data?.dialCode?.length),countryCode: data?.dialCode };
      handleOtp(req);
    }
    setTouched(true);
  },[errorMessage])

  const handleLogin = useCallback(async (req) => {
    setShowLoginLoader(true);
    // eslint-disable-next-line no-debugger

    const res = await authService.handleSignIn(req);    
    if (res?.status === 200 || res?.status === 202) {
      HWLocalStorage.save('rms-user', res?.data?.user);
      HWLocalStorage.save('accessToken', res?.data?.accessToken);
      HWLocalStorage.save('refreshToken', res?.data?.refreshToken);
      HWLocalStorage.save('tenantId', res?.data?.tenantId);
      setShowLoginLoader(false);
      window.location.reload();
    } else {
      setShowLoginLoader(false);
      if (res?.data === 'The OTP is invalid. Request a new one.') {
        setErrorMessage('The OTP is invalid. Request a new one.');
      } else if (res?.data === 'No OTP available for this mobile number.') {
        setErrorMessage('No OTP available for this mobile number.');
      } else if (res?.data === 'User not found.') {
        setErrorMessage('This phone number is not registered with us.');
      } else if(res?.data === 'Internal server error.') {
        setErrorMessage('Something went wrong.');
      } else if(res?.data === 'The OTP is incorrect. Try again.'){
        setErrorMessage('The OTP is incorrect. Try again.');
      } else if(res?.data === 'You do not have access to the RMS platform.') {
        setErrorMessage('You do not have access to the RMS platform.');
      } else {
        setErrorMessage('Something went wrong.');
      }
    }
  }, []);
  
  const handleClickOfResendOtpButton = useCallback((e) => {
    e?.stopPropagation();
    setOtp('')
    setErrorMessage('')
      const pathName = location?.pathname;
      const req = {
        mobileNumber: HWLocalStorage.load('mobileNumber'),
        countryCode: HWLocalStorage.load('countryCode')
      }
    handleOtp(req);
    setShowRefreshButton(false)
      setTimeout(function () {
        if (pathName === '/otp-validation') setShowRefreshButton(true);
      }, 30000);
    
  },[])

  useEffect(() => {
    const pathName = location?.pathname;
    setTimeout(function () {
      if (pathName === '/otp-validation') setShowRefreshButton(true);
    }, 30000);
  }, [location?.pathname]);


  return (
    <div className="rms__login__container">
      <DashboardLoader handleClose={setShowLoginLoader} showLoader={showLoginLoader} />     
        <div className="rms__login__card">
          <div className="rms__login__logo-wrap">
            <div className="rms__login__logo rms__icon"></div>
            <p className="rms__login-header">
              {!showOtpScreen
                ? 'Enter your registered mobile number.'
                : 'Please enter the OTP sent to your registered mobile number ***** *' +
                HWLocalStorage.load('mobileNumber')?.slice(-4) + '.'}
            </p>
          </div>

          <div className="rms__login__form__field__wrap">
            <div className="rms__login__form__field-text">
              {!showOtpScreen ? '' : 'Enter OTP*'}
            </div>
            <div className="rms__login__form__field-input">
              {!showOtpScreen ? (
                  <PhoneInput
                    specialLabel={'Mobile Number *'}
                    country={getCurrentCountryCode()}
                    value={phoneNumber}
                    onlyCountries={allowedCountries}
                    onChange={onMobileNumberChange}
                    placeholder="**********"
                    autoFormat={true}
                    enableSearch={true}
                    countryCodeEditable={false}
                    searchNotFound='No countries to show.'
                    searchPlaceholder='Search'
                    inputProps={{
                      name: 'phone',
                      required: true,
                      autoFocus: true,
                    }}
                    onEnterKeyPress={() => {
                      if (isValidPhone) {
                        handleClickOfGetOtpBtn();
                      }
                    }}
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    isValid={(value, country: any) =>
                      touched
                        ? isValidPhoneNumber(`+${value}`, country?.iso2)
                        : true
                    }
                    // defaultErrorMessage={errorMessage || 'Please enter a valid number'}
                  />    
              ) : (
                  <MuiOtpInput
                    className="rms__otp__field"
                    length={6}
                    value={otp}
                    onChange={handleOtpChange}
                    onComplete={handleComplete}
                    autoFocus={true}
                    validateChar={(val) => !isNaN(Number(val))}
                  />            
              )}

              <div className="rms__login__form__error__message">
                {errorMessage && errorMessage?.length > 0 && errorMessage}
              </div>
            </div>

            {showOtpScreen && (
              <div className="rms__login__form-resend-otp-wrap">
                <button className={showRefreshButton ? 'rms__login__form-resend-otp' : 'rms__login__form-resend-otp disabled'} onClick={(e) => handleClickOfResendOtpButton(e)}>
                  Resend OTP
                </button>
              </div>
            )}

            {showOtpScreen && (
              <div className="rms__login__form-login-edit-phone-number-wrap">
                <button className={'rms__login__form-login-edit-phone-number'} onClick={(e) => {
                  e?.stopPropagation();
                  navigate('/')
                }}>
                  Edit mobile number
                </button>
              </div>
            )
          }
          </div>
        </div>
    </div>
  );
};

export default LoginScreen;
