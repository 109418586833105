/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { FC, useEffect, useState } from 'react';
import './CustomRadioForm.css';
import { RadioButton, SearchBox, CheckBox, CustomTooltipWithLabel, SelectDropDown, FilterProp, } from '../globalExports';
import { Divider, Chip } from '@mui/material';
import { cloneDeep } from 'lodash';
import { SearchBoxPropTypes } from '../SearchBox/SearchBox'
import { RadioButtonPropTypes } from '../RadioButton/RadioButton';


export interface ICustomRadioFormProps {
  header: string
  radioButtonConfiguration: RadioButtonPropTypes
  dataList: string[]
  formContainerClass: string
  individualCheckboxStates: string[]
  selectedChipValues: string[]
  selectAllChecked: boolean
  // eslint-disable-next-line @typescript-eslint/ban-types
  handleSelectedData: Function
  filterProps?: FilterProp[]
  searchBoxConfiguration?: SearchBoxPropTypes
  disableCheckBox?: boolean
  showRadioList?: boolean
}

const CustomRadioForm: FC<ICustomRadioFormProps> = (props) => {
  const { header, radioButtonConfiguration, dataList, formContainerClass, individualCheckboxStates,
    selectedChipValues, selectAllChecked, handleSelectedData, filterProps, searchBoxConfiguration, disableCheckBox, showRadioList } = props;
  // const [finalData,setFinalData] = useState<string[][] | null>([])
  const listWithoutHeader = dataList?.filter((item) => !item.endsWith(')'))
  
  
  const handleSelectAllChange = () => {
    !selectAllChecked ? handleSelectedData?.(listWithoutHeader, [], !selectAllChecked) : handleSelectedData?.([], [], !selectAllChecked);
  };

  const handleIndividualCheckboxChange = (val: string) => {
    let updatedCheckboxStates = cloneDeep(individualCheckboxStates);
    if (updatedCheckboxStates?.includes(val)) {
      updatedCheckboxStates = updatedCheckboxStates?.filter((item) => item !== val)
    } else {
      updatedCheckboxStates?.push(val)
    }
    handleSelectedData?.(updatedCheckboxStates, updatedCheckboxStates, updatedCheckboxStates?.length === listWithoutHeader?.length);
  };

  const handleDeleteChip = (val: string) => {
    let updatedCheckboxStates = cloneDeep(individualCheckboxStates);
    updatedCheckboxStates = updatedCheckboxStates?.filter((item) => item !== val)
    handleSelectedData?.(updatedCheckboxStates, updatedCheckboxStates, updatedCheckboxStates?.length === listWithoutHeader?.length);
  };

  return (
    <div className={'custom__radio__form ' + formContainerClass}>
      <div className='custom__radio__form-header'>
        {header}
      </div>
      <div className="custom__radio__form-content">
        <div className="custom__radio__form-content-top">
          <RadioButton
            buttonsList={radioButtonConfiguration?.buttonsList}
            handleSelectedRadioBtnChange={radioButtonConfiguration?.handleSelectedRadioBtnChange}
            selectedRadioBtnValue={radioButtonConfiguration?.selectedRadioBtnValue}
            radionBtnGrpName={radioButtonConfiguration?.radionBtnGrpName}
          />
        </div>
        {showRadioList && (
          <div className="custom__radio__form-content-single-selection-container">
            <div className="custom__radio__form-search-box-container">
              <SearchBox
                searchFieldId={searchBoxConfiguration?.searchFieldId ?? ''}
                searchFieldName={searchBoxConfiguration?.searchFieldName}
                searchBoxClassName={searchBoxConfiguration?.searchBoxClassName}
                handleSearch={searchBoxConfiguration?.handleSearch}
                isDisabled={searchBoxConfiguration?.isDisabled}
              />
              <div className="search__wrap-right">
                {filterProps && filterProps?.length > 0 && filterProps?.map((filter, index) => {
                  return (
                    <div key={index} className="side__filter__wrap-content-row">
                      <div className="side__filter__wrap-content-row-label">{filter?.filterLabel}</div>
                      <div className="side__filter__wrap-content">
                        <SelectDropDown
                          selectValue={filter?.filterDropdownProps?.selectValue}
                          selectOptions={filter?.filterDropdownProps?.selectOptions ?? []}
                          handleSelectOptionChange={(val) => filter?.filterDropdownProps?.handleSelectOptionChange?.(val)}
                          selectDropDownId={filter?.filterDropdownProps?.selectDropDownId ?? ''}
                          isMultiSelect={filter?.filterDropdownProps?.isMultiSelect}
                          isDisabled={filter?.filterDropdownProps?.isDisabled}
                          renderToBody={filter?.filterDropdownProps?.renderToBody}
                        />
                      </div>
                    </div>
                  )
                })}
              </div>
            </div>
            <div className='custom__radio__form-select-all-check-box'>
              <div className='custom__radio__form-checkbox' onClick={() => !disableCheckBox && handleSelectAllChange?.()}>
                <CheckBox checked={selectAllChecked} isDisabled={disableCheckBox} />
                Select All
              </div>
            </div>
            <Divider />
            <div className="custom__radio__form-selected-values-container">
              {selectedChipValues.map((value, index) => (
                <Chip
                  key={index}
                  label={<CustomTooltipWithLabel labelId='custom__radio__form-checkbox-text' label={value} />}
                  onDelete={() => !disableCheckBox && handleDeleteChip(value)}
                  variant='filled'
                  className="chip-item"
                  sx={{
                    marginRight: '10px',
                    marginTop: '10px'
                  }}
                />
              ))}
            </div>
            <div className="custom__radio__form-checkbox-list-container">
                {[...Array(Math.ceil(dataList.length / 7)).keys()].map((colIndex) => (
                <div key={colIndex} className='custom__radio__form-checkbox-individual-column'>
                  {dataList.slice(colIndex * 7, (colIndex + 1) * 7).map((item, index) => (
                    <div key={index} className="custom__radio__form-checkbox-item">
                      {item.endsWith(')') ? (
                        <div className='custom__radio__form-list-header'>{item}</div>
                      ) : (
                        <>
                          <div className='custom__radio__form-checkbox' onClick={() => !disableCheckBox && handleIndividualCheckboxChange(item)}>
                            <CheckBox checked={individualCheckboxStates?.includes(item)} isDisabled={disableCheckBox} />
                            <CustomTooltipWithLabel labelId='custom__radio__form-checkbox-text' label={item} containerClassName='custom__radio__form-checkbox-text' />
                          </div>
                        </>
                      )}
                    </div>
                  ))}
                </div>
                ))}
              {dataList.length === 0 && (
                <div className="no-entity-found"> No Chargers Available</div>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default CustomRadioForm;
