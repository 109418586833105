import { cloneDeep } from 'lodash';
import React, { ReactNode, memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import {
  CustomBreadCrumbs,
  downloadFile,
  FilterProp,
  renderHeaderActionButtons,
  TableDashboard,
  useAppDispatch,
  useAppSelector,
} from '../../../globalUtils/globalExports';
import {
  getCustomerReducer,
  exportToCsvForRoleList,
  getFilterSelectedValue,
  setFilterData,
  getCurrentFiltersValues,
  getFilterReducer,
  customerTypes,
  setCustomerTableProps,
  fetchUpdateHistory,
} from '../../../rmsReduxStore/reduxExports';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import '../EditHistory/editHistory.css';
import { Id, toast } from 'react-toastify';
import { NoDataComponentUpdateHistory } from '../../../globalUtils/TableDashboard/TableComponents';
import { useLocation } from 'react-router-dom';
import { GridRenderCellParams, GridValidRowModel } from '@mui/x-data-grid-pro';
import { createBreadCrumbProps, formatRelativeTimeForTable } from '../../../globalUtils/globalHooks';

const EditHistory = memo(function EditHistory() {
  const dispatch = useAppDispatch();
  // const navigate = useNavigate();
  const { historyRecords, historyRecordsCount, historyRecordTableProps, loader } = useAppSelector(getCustomerReducer);
  const { screenFilters } = useAppSelector(getFilterReducer)
  const [exportAnchorEl, setExportAnchorEl] = useState(null);
  const [exportingFile, setExportingFile] = useState(false);
  const historyRecordTablePropsRef = useRef(historyRecordTableProps);
  const historyRecordsRef = useRef(historyRecords);
  const location = useLocation();

  useEffect(() => {
    historyRecordTablePropsRef.current = historyRecordTableProps;
  }, [historyRecordTableProps]);

  useEffect(() => {
    historyRecordsRef.current = historyRecords;
  }, [historyRecordsRef]);

  const customerCleanUpState = useCallback(() => {
    // : Partial<ChargerAction>
    const action = {
      type: customerTypes.CLEAR_HISTORY_LIST_DATA,
    }
    dispatch(action);
  }, [])


  const getTableRows = useCallback((): HistoryRecord[] => {
    return historyRecords !== null && historyRecords?.length > 0 ? historyRecords : [];
  }, [historyRecords]);

  useEffect(() => {
    window.addEventListener('beforeunload', customerCleanUpState);
    return (): void => {
      customerCleanUpState()
      window.removeEventListener('beforeunload', customerCleanUpState);
    }
  }, [])

  // TODO: Search Box Configuartions
  const onChangeOfSearchText = useCallback(async (searchTerm) => {
    if (historyRecordTablePropsRef?.current) {
      await layoutCallback(1, historyRecordTablePropsRef?.current?.pageSize, historyRecordTablePropsRef?.current?.view, historyRecordTablePropsRef?.current?.sortBy, historyRecordTablePropsRef?.current?.order, searchTerm, null)
    }
  }, [historyRecordTablePropsRef?.current, historyRecordsRef]);

  const searchBoxConfiguration = useMemo(() => {
    return {
      searchFieldId: 'manage-roles-search-box',
      searchFieldName: 'manage-roles-search-box',
      handleSearch: onChangeOfSearchText,
    };
  }, [onChangeOfSearchText, historyRecordTablePropsRef?.current]);

  // TODO: Adds action btns in header and their callbacks
  const headerActionBtns = renderHeaderActionButtons({
    actionBtns:
      [
        {

          id: 'export_csv_btn',
          headerActionBtnClick: (event): void => {
            setExportAnchorEl(event.currentTarget);

          }
        }
      ]
  })

  // TODO: Table Layout Callbacks
  const mobileViewConfiguration = useMemo(() => {
    return {
      headerDataConfig: {
        headerLeftDataConfig: ['name', 'description'],
        headerRightDataConfig: {
          actionIconsComponent: false,
          statusIconComponent: true,
        },
      },
      statusIconKey: 'status'
    };
  }, []);

  const historyColumns = useMemo(() => {
    return [
      {
        field: 'user',
        headerName: 'User',
        flex: 1,
        sortable: false,
        minWidth: 100,
      },
      {
        field: 'action',
        headerName: 'Actions',
        flex: 1,
        minWidth: 100,
        sortable: false
      },
      {
        field: 'updatedField',
        headerName: 'Field',
        flex: 1,
        minWidth: 100,
        sortable: false
      },
      {
        field: 'oldValue',
        headerName: 'Previous Value',
        flex: 1,
        minWidth: 100,
        sortable: false
      },
      {
        field: 'newValue',
        headerName: 'Updated Value',
        flex: 1,
        minWidth: 100,
        sortable: false
      },
      {
        field: 'updatedAt',
        headerName: 'Updated At',
        flex: 1,
        minWidth: 100,
        sortable: true,
        renderCell: (
          params: GridRenderCellParams<GridValidRowModel>
        ): ReactNode => {
          const rowData = params?.row;
          const date = new Date(rowData?.createdAt);
          return formatRelativeTimeForTable(date);
        },
      },
    ];
  }, [historyRecordTableProps]);

  const breadCrumbs = createBreadCrumbProps({
    breadCrumbProps:
      [
        {
          objectType: 'link',
          id: 'customers',
        },
        {
          objectType: 'link',
          id: 'editCustomer',
          linkBtnState: location?.state,
        },
        {
          objectType: 'text',
          id: 'updateHistory',
          text: 'Update History'
        }
      ]
  })

  const handleFilterDataChange = useCallback(async (val, filterKey, isGlobal) => {

    const tableProps = cloneDeep(historyRecordTablePropsRef.current);
    const res = await setFilterData(val, filterKey, 'customerEditHistory', 'SET', isGlobal ? isGlobal : false, true)(dispatch);
    if (res?.message === 'Action dispatched successfully') {
      layoutCallback?.(
        1,
        tableProps?.pageSize,
        tableProps?.view ?? '',
        tableProps?.sortBy,
        tableProps?.order,
        tableProps?.searchTerm ?? '',
        res?.filters
      )
    }
  }, [historyRecordTablePropsRef.current])

  const alarmFilters: FilterProp[] = useMemo(() => {
    const filterAlarmSetTimeStampValue = getFilterSelectedValue('updateInterval', 'customerEditHistory', false);
    return [
      {
        filterLabel: 'Time (Updated At)',
        filterType: 'dateFilter',
        filterId: 'timeStampSetAt',
        dateFilterProps: {
          datePickerId: 'alarm__time__stamp__set__at__date__picker',
          datePickerClassName: '',
          selectsRange: true,
          startDate: filterAlarmSetTimeStampValue?.[0] ? new Date(filterAlarmSetTimeStampValue?.[0]) : null,
          endDate: filterAlarmSetTimeStampValue?.[1] ? new Date(filterAlarmSetTimeStampValue?.[1]) : null,
          onChange: (val): void => {
            handleFilterDataChange(val, 'updateInterval', false)
          },
        }
      },

    ]
  }, [screenFilters])


  const layoutCallback = useCallback(async (pageNumber: number, pageSize: number, view: string, sortField: string, sortOrder: string, searchTerm, filterData) => {
    const tableProps: IListTableProps = cloneDeep(historyRecordTablePropsRef.current)
    const filters = filterData ? filterData : getCurrentFiltersValues('customerEditHistory', true)

    await dispatch(fetchUpdateHistory({
      customerId: location?.state?.id,
      sortBy: sortField || '',
      order: sortOrder || 'ascending',
      filters: filters,
      pageSize,
      pageNumber,
      searchTerm: searchTerm != null && searchTerm != undefined ? searchTerm : historyRecordTablePropsRef?.current?.searchTerm
    }));
    if (tableProps) {
      tableProps.view = view || 'list'
      tableProps.sortBy = sortField || ''
      tableProps.order = sortOrder || 'ascending';
      tableProps.pageNumber = pageNumber;
      tableProps.pageSize = pageSize;
      if (searchTerm != null && searchTerm != undefined) {
        tableProps.searchTerm = searchTerm;
      }
    }
    await dispatch(setCustomerTableProps(tableProps, 'editHistoryList'))
  }, [historyRecordTablePropsRef?.current, dispatch]);

  // TODO: Redux state Clean up
  const editHistoryCleanUpStates = useCallback(() => {
    // : Partial<UserAction>
    const action = {
      type: customerTypes.CLEAR_HISTORY_LIST_DATA,
    }
    dispatch(action);
  }, [])

  useEffect(() => {
    window.addEventListener('beforeunload', editHistoryCleanUpStates);
    return (): void => {
      editHistoryCleanUpStates()
      window.removeEventListener('beforeunload', editHistoryCleanUpStates);
    }
  }, [])

  // ***********************************************************
  // TODO: Export Handlers
  const handleClose = (): void => {
    setExportAnchorEl(null);
  };

  const fileDownloadSuccessToast = useCallback((): Id => {
    setExportingFile(false);
    return toast.success('File downloaded.');
  }, []);

  const fileDownloadFailureToast = useCallback((): Id => {
    setExportingFile(false);
    return toast.warn('Something went wrong. Please export again.');
  }, []);


  const onExportBtnClick = useCallback(async (fileType: 'csv' | 'xlsx'): Promise<void> => {
    if (exportingFile) {
      // If already exporting, prevent another export
      return;
    }
    try {
      const response = await dispatch(exportToCsvForRoleList({
        fileType: fileType,
        filters: getCurrentFiltersValues('customerEditHistory', true)
      }));
      if (response?.status === 202 || response?.status === 200) {
        downloadFile({ url: response?.data?.url });
        fileDownloadSuccessToast()
      } else {
        fileDownloadFailureToast();
        setExportAnchorEl(null);
      }
    } finally {
      // Ensure that exporting status is updated even if there's an error
      setExportingFile(false);
      setExportAnchorEl(null);

    }
  }, [exportingFile, fileDownloadSuccessToast, fileDownloadFailureToast])

  return (
    <>
      <div className='edit__history__wrap'>
        <div className='edit__history__wrap-header-link-row'>
          <CustomBreadCrumbs breadCrumbs={breadCrumbs} />
        </div>
        <TableDashboard
          tableId="customer-history-table"
          tableClassName="customer-history-table"
          header={'Update History'}
          searchBoxIncluded={true}
          searchBoxConfiguration={searchBoxConfiguration}
          gridColumns={historyColumns}
          tableRows={getTableRows()}
          mobileViewConfiguration={mobileViewConfiguration}
          layoutView={'list'}
          headerActionBtns={headerActionBtns}
          listLayoutCallBack={layoutCallback}
          totalCount={historyRecordsCount}
          showSkeleton={!historyRecords}
          totalCountText={'updates'}
          showLoader={loader}
          customNoDataComponent={NoDataComponentUpdateHistory}
          pageNumber={historyRecordTableProps?.pageNumber}
          tablePageSize={historyRecordTableProps?.pageSize}
          excludeGlobalFilters={true}
          filterProps={alarmFilters}
          handleFilterDataChange={handleFilterDataChange}
        />
        <Menu
          id="export-option-dropdown"
          anchorEl={exportAnchorEl}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          keepMounted
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          className='export__option__dropdown'
          sx={{ top: '30px' }}
          open={Boolean(exportAnchorEl)}
          onClose={handleClose}

        >
          <MenuItem>
            <div className='export__btn-option' onClick={(e) => {
              e?.stopPropagation();
              onExportBtnClick('csv')
            }}>
              To CSV
            </div>
          </MenuItem>
          <MenuItem>

            <div className='export__btn-option' onClick={(e) => {
              e?.stopPropagation();
              onExportBtnClick('xlsx')
            }}>
              To Excel
            </div>
          </MenuItem>
        </Menu>
      </div>
    </>
  );
})

export default EditHistory;
