import moment from 'moment';
import React, { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { ChargerDetailSubHeaderSkeleton, ChargerPanelHeaderCardSkeleton, ChargerPanelHeaderSkeleton } from '../../../globalUtils/SkeletonDesign/SkeletonDesign';
import {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  AutoRefresh,
  CustomBreadCrumbs,
  DashboardHeader,
  DashboardLoader,
  FilledChargerCards,
  HorizontalNavbar,
  SubHeaderChargerDetailCards,
  useAppDispatch,
  useAppSelector,
} from '../../../globalUtils/globalExports';
import {
  fetchChargerConnectorStatus,
  fetchChargerGraphDetail,
  fetchChargerConnectorStatusLogs,
  fetchIndividualChargerDetail,
  getChargerReducer,
  getGlobalReducer,
  chargerTypes,
  fetchChargerUptimeHistory,
  fetchChargerStatusMasterInfo,
  fetchChargerStatusSystemInfo,
  fetchChargerMasterInfo,
  fetchChargerSystemInfo,
  getReferenceDataReducer, fetchAlarmListData, fetchChargerSettingsMasterInfo, fetchChargerSettingsSystemInfo, setExpandedToggleIds, fetchChargerHistoryTypeReferenceData, fetchChargerHistoryIntervalReferenceData, getCurrentFiltersValues, getFilterSelectedValue, setFilterData, getFilterReducer, clearChargerHistoryGraphData, fetchChargerGraphDetailBulk, setChargerHistoryGraphFilterHash,
  fetchAllowedCommandsForRole
} from '../../../rmsReduxStore/reduxExports';
import AlarmTable from '../../alarms/AlarmTable';
import {
  ChargerPanelAdminActionsDashboard,
  ChargerPanelRemoteCommandsDashboard,
  ChargerPanelSettingsDashboard,
  ChargerPanelStatusSecondOption,
  ChargerPanelSystemsInfoDashboard,
} from '../../componentExports';
import { navList } from '../chargerConstants';
import './chargerPanel.css';
import { cloneDeep } from 'lodash';
import { createBreadCrumbProps, formatRelativeTimeForTable, getAllKeyValues, useEffectOnce } from '../../../globalUtils/globalHooks';
import { IconWithTooltip } from '../../../globalUtils/globalIcons';
import ChargerStatusParameters from './ChargerPanelStatus/ChargerStatusParameters';

const ChargerPanelDashboard = memo(function ChargerPanelDashboard() {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const { selectedNav, individualChargerDetail, chargerHistoryUptime, lastRefreshTime, chargerStatusMasterInfoAltered, chargerMasterInfo, chargerSettingsMasterInfo, chargerHistoryGraphData } = useAppSelector(getChargerReducer);
  const { toggleExpandedIds } = useAppSelector(getGlobalReducer);
  const { chargerHistoryTypeReferenceData, chargerHistoryIntervalReferenceData } = useAppSelector(getReferenceDataReducer);
  const { screenFilters } = useAppSelector(getFilterReducer)
  const chargerPanel = useRef<HTMLDivElement>(null);
  const chargerPanelHeader = useRef<HTMLDivElement>(null);
  const chargerPanelHeaderCards = useRef<HTMLDivElement>(null);
  const chargerPanelContentNavbar = useRef<HTMLDivElement>(null);
  const chargerPanelParameterRef = useRef<HTMLDivElement>(null);
  // const [selectedNav, setSelectedNav] = useState<'status' | 'settings' | 'remote_commands' | 'system_info' | 'alarm_history' | 'admin_actions' | 'info_graphics'>(location?.state?.selectedNav === 'alarm_history' ? 'alarm_history' : 'info_graphics');
  const [contentHeight, setContentHeight] = useState(0);
  const [navbarHeight, setNavbarHeight] = useState(0);
  const [showLoader, setShowLoader] = useState(false);
  const [chargerUptimeData, setChargerUptimeData] = useState<{
    headerText: string;
    headerArray: {
      title: string;
      value: string;
    }[]
  }[] | null>(null);
  // const [newRefreshDate, setNewRefreshDate] = useState<string | null>(lastRefreshTime)

  const handleNavClick = useCallback((event, val) => {
    const chargerAction = {
      type: chargerTypes.SET_SELECTED_NAV,
      selectedNav: val
    }
    dispatch(chargerAction)
    // setSelectedNav(val);
  }, []);



  // TODO: Handle Refresh Btn Click.
  const apiCallBackOnRefresh = useCallback(async () => {
    dispatch(fetchIndividualChargerDetail({ chargerId: location?.state?.chargerId, }))
    dispatch(fetchChargerUptimeHistory({ chargerId: location?.state?.chargerId }))

    if (selectedNav === 'info_graphics') {
      apiCallForChargerStatusHistoryGraph();
      dispatch(fetchChargerConnectorStatus({ chargerId: location?.state?.chargerId }))
      apiCallbackForHistoryLogs()
    } else if (selectedNav === 'status') {
      dispatch(fetchChargerStatusMasterInfo({ chargerId: location?.state?.chargerId }))
      dispatch(fetchChargerStatusSystemInfo({ chargerId: location?.state?.chargerId }))
    } else if (selectedNav === 'system_info') {
      dispatch(fetchChargerMasterInfo({ chargerId: location?.state?.chargerId }))
      dispatch(fetchChargerSystemInfo({ chargerId: location?.state?.chargerId }))
    } else if (selectedNav === 'alarm_history') {
      apiCallbackforAlarmHistoryLayoutCallback(1, 25, '', '', '')
    } else if (selectedNav === 'settings') {
      dispatch(fetchChargerSettingsMasterInfo({ chargerId: location?.state?.chargerId }))
      dispatch(fetchChargerSettingsSystemInfo({ chargerId: location?.state?.chargerId }))
    }

  }, [screenFilters, chargerHistoryTypeReferenceData, chargerHistoryIntervalReferenceData, location, selectedNav, chargerHistoryGraphData])

  const apiCallForChargerStatusHistoryGraph = useCallback(async () => {
    if (chargerHistoryGraphData) {
      await dispatch(clearChargerHistoryGraphData())
    }
    const selectedHistoryType = getFilterSelectedValue('historyType', 'chargerPanel', false)
    if (!selectedHistoryType) {
      if (!chargerHistoryTypeReferenceData) {
        await dispatch(fetchChargerHistoryTypeReferenceData({ chargerId: location?.state?.chargerId }))
      }
      if (!chargerHistoryIntervalReferenceData) {
        await dispatch(fetchChargerHistoryIntervalReferenceData())
      }
      const historyType = chargerHistoryTypeReferenceData?.find(item => item?.defaultOption)
      const statType = historyType?.statType?.find(item => item?.defaultOption)
      const connectorType = historyType?.connectorType?.find(item => item?.defaultOption)
      const historyInterval = chargerHistoryIntervalReferenceData?.find(item => item?.defaultOption)
      const statInterval = historyInterval?.statInterval?.find(item => item?.defaultOption)
      if (historyType) {
        await setFilterData(historyType, 'historyType', 'chargerPanel', 'SET', false)(dispatch);
        await setFilterData(statType, 'statType', 'chargerPanel', 'SET', false)(dispatch);
        await setFilterData(connectorType, 'connectorId', 'chargerPanel', 'SET', false)(dispatch)
      }
      if (historyInterval) {
        await setFilterData(historyInterval, 'historyInterval', 'chargerPanel', 'SET', false)(dispatch);
        await setFilterData(statInterval, 'statInterval', 'chargerPanel', 'SET', false)(dispatch);
      }
      const filterHash = await dispatch(setChargerHistoryGraphFilterHash('SET',historyType?.id,connectorType?.id))
      await dispatch(fetchChargerGraphDetail({
        chargerId: location?.state?.chargerId,
        filterHash: filterHash,
        filters: getCurrentFiltersValues('chargerPanel', true)
      }))
    }
    else {
      if (!chargerHistoryTypeReferenceData) {
        await dispatch(fetchChargerHistoryTypeReferenceData({ chargerId: location?.state?.chargerId }))
      }
      const previousSelectedConnectorTypes = getFilterSelectedValue('connectorId', 'chargerPanel', false)?.map(item => item?.name)
      const connectorTypes = chargerHistoryTypeReferenceData?.find((item) => item.id === selectedHistoryType?.[0]?.id)?.connectorType
      const filteredConnectors = connectorTypes?.filter((item) => previousSelectedConnectorTypes?.includes(item?.name)) ?? []
      const connectorToSet = filteredConnectors?.length > 0 ? filteredConnectors : connectorTypes?.find(item => item?.defaultOption)
      await setFilterData(connectorToSet, 'connectorId', 'chargerPanel', 'SET', false)(dispatch)
      fetchChargerGraphDetailBulk({ chargerId: location?.state?.chargerId })(dispatch)
    }

  }, [screenFilters, chargerHistoryTypeReferenceData, chargerHistoryIntervalReferenceData, chargerHistoryGraphData])

  const apiCallbackForHistoryLogs = useCallback(() => {
    const filters = getCurrentFiltersValues('chargerPanel', true)
    dispatch(fetchChargerConnectorStatusLogs({ chargerId: location?.state?.chargerId, filters: filters?.filter((item) => item?.key === 'historyInterval') }))
  }, [screenFilters])

  const apiCallbackforAlarmHistoryLayoutCallback = useCallback(async (pageNumber: number, pageSize: number, view: string, field: string, order: string) => {
    const sortField = field === 'id' ? 'alarmId' : field;
    await dispatch(fetchAlarmListData({
      pageSize: pageSize,
      pageNumber: pageNumber,
      fetchType: 'history',
      sortBy: sortField,
      order: order,
      chargerId: location.state.chargerId
    }));

  }, [selectedNav, location]);
  // TODO: Format details for header card .
  const chargerHeaderCards = useMemo(() => {
    const lastPingTime = individualChargerDetail?.lastPingTime && new Date(individualChargerDetail?.lastPingTime);
    const statusBackgroundColor = individualChargerDetail?.status ? individualChargerDetail?.status === 'Online' ? 'var(--card-green-color)' : individualChargerDetail?.status === 'Offline' ? 'var(--grey-color)' : 'var(--grey-color))' : 'var(--grey-color))';
    const alarmBackgroundColor = (!individualChargerDetail?.alarmStatus || individualChargerDetail?.alarmStatus === 'No alarm') ? 'var(--green-color)' : individualChargerDetail?.alarmStatus === 'Critical' ? 'var(--critical-alarm-color)' : individualChargerDetail?.alarmStatus === 'Major' ? 'var(--major-alarm-color)' : 'var(--minor-alarm-color)';
    const LastPingBackgroundColor = lastPingTime ? Math.floor(((Math.abs(new Date(lastPingTime).valueOf() - new Date().valueOf())) / 1000) / 60) > 10 ? 'var(--card-grey-color)' : 'var(--charger-card-purple-color)' : 'var(--charger-card-purple-color)';

    return [
      {
        cardId: 'status',
        headerText: 'status',
        footerText: individualChargerDetail?.status ? individualChargerDetail?.status : '-',
        bgColor: statusBackgroundColor
      },
      {
        cardId: 'charger-model',
        headerText: 'chargerModel',
        footerText: individualChargerDetail?.model ? individualChargerDetail?.model : '-',
        footerSubText: individualChargerDetail?.modelDescription ?  individualChargerDetail?.modelDescription : '-'
      },
      {
        cardId: 'last-ping',
        headerText: 'lastPing',
        footerText: lastPingTime && moment(lastPingTime).format('MMM Do') ? moment(lastPingTime).format('MMM Do') : '-',
        footerSubText: lastPingTime && moment(lastPingTime).format('LT') ? moment(lastPingTime).format('LT') : '',
        bgColor: LastPingBackgroundColor
      },
      {
        cardId: 'alarm-status',
        headerText: 'alarmStatus',
        footerText: individualChargerDetail?.alarmStatus ? individualChargerDetail?.alarmStatus : 'No Alarm',
        bgColor: alarmBackgroundColor

      },
      {
        cardId: 'software-version',
        headerText: 'softwareVersion',
        footerText: individualChargerDetail?.softwareVersion ? individualChargerDetail?.softwareVersion : '-',
        footerSubText: individualChargerDetail?.softwareVersionLastUpdatedOn ? formatRelativeTimeForTable(new Date(individualChargerDetail?.softwareVersionLastUpdatedOn)): '-'
      }
    ]
  }, [individualChargerDetail])

  // TODO: Handle Expand and Collapse 
  const handleExpandAndCollapse = useCallback((value: 'expand_all' | 'collapse_all') => {
    let array: string[] = cloneDeep(toggleExpandedIds)
    const data = (nav): ChargerMasterInfo[] | null => {
      switch (nav) {
        case 'status': return cloneDeep(chargerStatusMasterInfoAltered); break;
        case 'system_info': return cloneDeep(chargerMasterInfo); break;
        case 'settings': return cloneDeep(chargerSettingsMasterInfo); break;
        default: return null; break;
      }
    }
    if (value === 'expand_all' && data && data !== null && data?.length > 0) {
      data(selectedNav)?.forEach(element => {
        const updatedKeys = getAllKeyValues(element, 'key');
        if (updatedKeys && updatedKeys?.length > 0) {
          array = [...array, ...updatedKeys]
        }
      })
    } else if (value === 'collapse_all') {
      array = []
    }
    dispatch(setExpandedToggleIds(array));
    if (selectedNav === 'status' && chargerPanelParameterRef && chargerPanelParameterRef?.current !== null) {
      chargerPanelParameterRef?.current?.scrollIntoView()
    }
  }, [selectedNav, chargerMasterInfo, chargerStatusMasterInfoAltered, toggleExpandedIds, chargerPanelParameterRef])

  // TODO: Calculate screen height for the content and table.
  const calculateContentHeight = useCallback(() => {
    if (chargerPanel.current != null && chargerPanelHeader.current != null && chargerPanelHeaderCards.current != null && chargerPanelContentNavbar.current != null) {
      const panelRect = chargerPanel.current.getBoundingClientRect();
      // const headerRect = chargerPanelHeader.current.getBoundingClientRect();
      // const headerCardsRect = chargerPanelHeaderCards.current.getBoundingClientRect();
      const navbarRect = chargerPanelContentNavbar.current.getBoundingClientRect();
      const totalHeight = panelRect.height;
      // const headerHeight = headerRect.height;
      // const headerCardsHeight = headerCardsRect.height;
      const navbarHeight = navbarRect.height;
      setNavbarHeight(navbarHeight);
      // const remainingHeight = totalHeight - headerHeight - headerCardsHeight - 30;
      setContentHeight(totalHeight - 30);
    }
  }, [chargerPanel, chargerPanelHeader, chargerPanelHeaderCards, chargerPanelContentNavbar]);

  useEffect(() => {
    const ro = new ResizeObserver(() => {
      calculateContentHeight()
    })
    if (chargerPanel.current != null) {
      ro.observe(chargerPanel.current)
    }
    window.addEventListener('resize', calculateContentHeight)
    return (): void => {
      window.removeEventListener('resize', calculateContentHeight)
    }
  }, [chargerPanel])

  useEffect(() => {
    calculateContentHeight();
    window.addEventListener('resize', calculateContentHeight);
    return (): void => {
      window.removeEventListener('resize', calculateContentHeight);
    };
  }, [calculateContentHeight]);

  // TODO: Handle Uptime of charger
  useEffect(() => {
    if (chargerHistoryUptime) {
      const modifiedData = Object.keys(chargerHistoryUptime).map(key => {
        const element = chargerHistoryUptime[key];
        return {
          headerText: key === 'uptimeOfCharger'
            ? 'Uptime of Charger ' + element.unit
            : key === 'chargerEnergy'
              ? 'Charger Energy'
              : key === 'chargingTime'
                ? 'Charging Time'
                : '',
          headerArray: [
            {
              title: '1 week',
              value: key === 'chargingTime' ? element.data.oneWeek : element.data.oneWeek?.toFixed(2) + element.unit
            },
            {
              title: '1 month',
              value: key === 'chargingTime' ? element.data.oneMonth : element.data.oneMonth?.toFixed(2) + element.unit
            },
            {
              title: '1 year',
              value: key === 'chargingTime' ? element.data.oneYear : element.data.oneYear?.toFixed(2) + element.unit
            }
          ]
        };
      });
      setChargerUptimeData(modifiedData)
    }
  }, [chargerHistoryUptime]);

  // TODO: Header API Callbacks
  useEffectOnce(() => {
    dispatch(fetchIndividualChargerDetail({ chargerId: location?.state?.chargerId, }))
    dispatch(fetchAllowedCommandsForRole())
    dispatch(fetchChargerHistoryTypeReferenceData({ chargerId: location?.state?.chargerId }));
    dispatch(fetchChargerHistoryIntervalReferenceData());
  })

  // TODO: Clear all redux states on unmount
  useEffect(() => {
    return (): void => {
      // Partial<ChargerAction> 
      const chargerAction = {
        type: chargerTypes.CLEAR_CHARGER_DETAIL,
      }
      dispatch(chargerAction);
      dispatch(setExpandedToggleIds([]))
    }
  }, [])

  // TODO: BreadCrumb navigation Props
  const breadCrumbs = createBreadCrumbProps({
    breadCrumbProps:
      [
        {
          objectType: 'link',
          id: 'chargers',
          linkBtnState: location?.state,
          handleOnClick: () => {
            const chargerAction = { type: chargerTypes.CLEAR_CHARGER_DETAIL }; dispatch(chargerAction)
          }
        },
        {
          objectType: 'text',
          id: 'text',
          text: location?.state?.charger?.visibleId || individualChargerDetail?.chargerVisibleId
        }
      ]
  })

  return (
    <div ref={chargerPanel} className='charger__panel'>
      <DashboardLoader handleClose={setShowLoader} showLoader={showLoader} />
      <div ref={chargerPanelHeader} className='charger__panel-header-wrap'>
        <div className='back__btn-container'>
          <CustomBreadCrumbs breadCrumbs={breadCrumbs} />
        </div>
        {individualChargerDetail ?
          <div className='charger__panel-header'>
            <div className='charger__panel-header-content-wrap'>
              <DashboardHeader header={individualChargerDetail?.chargerVisibleId} />
              <p className="charger__panel-sub-header-separator">|</p>
              <p className="charger__panel-sub-header">Customer: {individualChargerDetail?.customerName}</p>
              {location?.state?.charger?.city && 
              <>
              <p className="charger__panel-sub-header-separator">|</p>
              <p className="charger__panel-sub-header">Location: {location?.state?.charger?.city}</p>
              </>}
            </div>
            <div className="charger__panel-bottom-auto-refresh-wrap">
              <AutoRefresh
                refreshDate={new Date(lastRefreshTime || '')}
                containerClassName="charger__auto__refresh__component"
                onRefreshCallback={apiCallBackOnRefresh}
                pathName="/charger"
                refreshLocation={false}
              />
            </div>
          </div>
          :
          <ChargerPanelHeaderSkeleton />}
      </div>

      <div ref={chargerPanelHeaderCards} className='charger__panel-cards-wrap'>
        {individualChargerDetail ?
          <div className='charger__panel-alarm-cards'>
            {chargerHeaderCards && chargerHeaderCards?.length > 0 && chargerHeaderCards?.map((chargerHeaderCard, index) => <FilledChargerCards key={'charger-card-' + index} cardId={chargerHeaderCard?.cardId} headerText={chargerHeaderCard?.headerText} footerText={chargerHeaderCard?.footerText} footerSubText={chargerHeaderCard?.footerSubText} bgColor={chargerHeaderCard?.bgColor} />)}
          </div> :
          <ChargerPanelHeaderCardSkeleton />
        }

        {chargerHistoryUptime && chargerUptimeData ?
          <SubHeaderChargerDetailCards headerArray={chargerUptimeData} containerClassName='' subHeaderCardId='charger-summary-key-header' /> : <ChargerDetailSubHeaderSkeleton />}
      </div>

      <div className='charger__panel-detail-wrapper' style={{ height: `${contentHeight}px` }}>
        <div ref={chargerPanelContentNavbar} className='charger__panel-detail-navbar'>
          <HorizontalNavbar selectedNav={selectedNav} onNavClick={handleNavClick} navList={navList?.filter((nav) => nav?.isAccessible)} />
          {(selectedNav === 'status' || selectedNav === 'settings' || selectedNav === 'system_info') &&
            <div className='charger__panel__expand__and__collapse__wrap'>
              <IconWithTooltip iconClassName='panel_expand_all_icon' toolTipText={'Expand All'}
                onIconClick={(e) => { e?.stopPropagation(); handleExpandAndCollapse('expand_all') }}
              />
              <IconWithTooltip iconClassName='panel_collapse_all_icon' toolTipText={'Collapse All'}
                onIconClick={(e) => { e?.stopPropagation(); handleExpandAndCollapse('collapse_all') }}
              />
            </div>
          }

        </div>
        {selectedNav === 'info_graphics' && <ChargerPanelStatusSecondOption ref={chargerPanelParameterRef} apiCallForChargerStatusHistoryGraph={apiCallForChargerStatusHistoryGraph} apiCallbackForHistoryLogs={apiCallbackForHistoryLogs} contentHeight={contentHeight - navbarHeight} />}
        {selectedNav === 'status' && <ChargerStatusParameters ref={chargerPanelParameterRef} contentHeight={contentHeight - navbarHeight} />}
        {selectedNav === 'settings' && <ChargerPanelSettingsDashboard contentHeight={contentHeight - navbarHeight} />}
        {selectedNav === 'remote_commands' && (<ChargerPanelRemoteCommandsDashboard contentHeight={contentHeight - navbarHeight} />)}
        {selectedNav === 'system_info' && (<ChargerPanelSystemsInfoDashboard contentHeight={contentHeight - navbarHeight} />)}
        {selectedNav === 'alarm_history' && (<AlarmTable layoutCallback={apiCallbackforAlarmHistoryLayoutCallback} view={'list'} contentHeight={contentHeight - navbarHeight - 20} containerClassName='' calledFrom='charger_panel' id={location?.state?.chargerId} />)}
        {selectedNav === 'admin_actions' && (<ChargerPanelAdminActionsDashboard contentHeight={contentHeight - navbarHeight} />)}
      </div>
    </div>
  );
});

export default ChargerPanelDashboard;
