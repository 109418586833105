import { cloneDeep } from 'lodash';
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import CustomForm, { ICustomFormProps, IFormContentProp } from '../../../globalUtils/CustomForm/CustomForm';
import {
  CustomButtonGroup,
  useAppDispatch,
  useAppSelector,
  useEffectOnce,
  SearchBox,
  CustomDatePicker,
  RadioButton
} from '../../../globalUtils/globalExports';
import {
  createParameters,
  fetchParametersSettingsSystemInfo,
  fetchParametersSettingsMasterInfo,
} from '../../../rmsReduxStore/chargersRedux/chargerCreators';
import {
  getReferenceDataReducer, fetchAddParametersListDetail,
  getChargerReducer, fetchAddParameterRefChargingStations,
  fetchAddParameterRefChargers,
  fetchCustomerReferenceData,
  setExpandedToggleIds,
  getGlobalReducer,
  chargerTypes
} from '../../../rmsReduxStore/reduxExports';
import { Id, toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { AxiosResponse } from 'axios';
import './AddParameter.css';
import 'react-datepicker/dist/react-datepicker.css';
import { DashboardLoader, ParametersTable } from '../../../globalUtils/globalExports'
import { createBreadCrumbProps,  getAllKeyValues,  updateMasterInfo, updateSettingsValueByKey } from '../../../globalUtils/globalHooks'
import { checkValidationsAndShowError, removeParameterValueUnit } from '../ChargerPanel/ChargerPanelSettingsDashboard';


interface IChargingStation {
  id: string;
  name: string;
  customerId: string;
}

interface IParams {
  paramKey: string;
  paramName: string;
  values: unknown[]
}
interface IAddParameterForm {
  customer: IGeneralReferenceData[];
  chargingStation: IChargingStation[];
  chargers: string[];
  scheduledAt?: Date;
  sendNow: boolean;
  params: IParams[];
}
interface ICharger {
  chargerId: string,
  value: string,
  label: string,
}
const AddParameter: FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { toggleExpandedIds } = useAppSelector(getGlobalReducer);
  const { parametersSettingsSystemInfo, chargerParametersSettingsMasterInfo } = useAppSelector(getChargerReducer);
  const [parametersSettingsData, setParametersSettingsData] = useState<ChargerMasterInfo[] | null>(null);
  const [settingsInitialData, setSettingsInitialData] = useState<ChargerMasterInfo[] | null>(null);
  const [errorMessage, setErrorMessage] = useState<string>('')
  const [showLoader, setShowLoader] = useState(false);
  const [selectedChargers, setSelectedChargers] = useState<ICharger[]>([]);
  const { addParameterRefChargers, addParameterRefChargingStations } = useAppSelector(getChargerReducer);
  const { customerReferenceData } = useAppSelector(getReferenceDataReducer);
  const [chargingStationOptions, setChargingStationOptions] = useState<{ label: string; value: string; }[]>([]);
  const [chargerOptions, setChargerOptions] = useState<{ label: string; value: string; chargerId: string; }[]>([]);
  const [customerSelected, setCustomerSelected] = useState<string[]>([]);
  const [stationSelected, setStationSelected] = useState<string[]>([]);
  const [parameterValues, setParameterValues] = useState<IParams[]>([]);
  const [isVisible, setisVisible] = useState(false);
  const [selectedbtn, setSelectedbtn] = useState('nowBtn');
  const [selectedDate, setSelectedDate] = useState(null);
  const [singleCustomerOnly, setSingleCustomerOnly] = useState(false);
  const [singleChargingStationOnly, setSingleChargingStationOnly] = useState(false);
  const [singleChargerOnly, setSingleChargerOnly] = useState(false);

  const initialParameterFormData = {
    customer: [],
    chargers: [],
    chargingStation: [],
    sendNow: true,
    params: [],
  };
  const [addParameterFormData, setaddParameterFormData] = useState<IAddParameterForm>(initialParameterFormData);

  useEffect(() => {
    if (chargerParametersSettingsMasterInfo !== null && parametersSettingsSystemInfo !== null) {
      const chargerMasterData = cloneDeep(chargerParametersSettingsMasterInfo);
      const chargerSystemData = cloneDeep(parametersSettingsSystemInfo);
      const data = updateMasterInfo(chargerMasterData, chargerSystemData);
      setParametersSettingsData(data);
      setSettingsInitialData(data);
    }
  }, [chargerParametersSettingsMasterInfo, parametersSettingsSystemInfo])
  useEffect(() => {
    if (selectedChargers?.length > 0) {
      setisVisible(true);
    }
    else {
      setisVisible(false);
    }

  }, [singleChargerOnly, selectedChargers])

  // For getting chargers having same value of parameters so sending array having id of selected chargers 

  useEffect(() => {
    if (selectedChargers?.length > 0) {
      dispatch(fetchParametersSettingsSystemInfo({ chargerIds: addParameterFormData?.chargers }));
      dispatch(fetchParametersSettingsMasterInfo({ chargerIds: addParameterFormData?.chargers, searchTerm: '' }))
    }
  }, [selectedChargers, dispatch]);

  useEffect(() => {
    setaddParameterFormData((prevState) => ({
      ...prevState,
      params: parameterValues,
    }));
  }, [parameterValues])

  useEffectOnce(() => {
    dispatch(fetchAddParametersListDetail({}));
    dispatch(fetchCustomerReferenceData()).then((res) => {
      const response = JSON.parse(res)
      if (response?.length == 1) {
        setaddParameterFormData({
          ...addParameterFormData,
          customer: response
        })
        setCustomerSelected(response?.map((item) => item?.value));
        setSingleCustomerOnly(true);

        const chargingStationList = addParameterRefChargingStations?.filter(
          station => response?.some(customer => customer?.id === station?.customerId)
        );
        if (chargingStationList?.length === 1) {
          setaddParameterFormData({
            ...addParameterFormData,
            customer: response,
            chargingStation: chargingStationList
          })
          setStationSelected(chargingStationList?.map((item) => item?.id));

          //Chargers checked , whether single or multiple      
          setSingleChargingStationOnly(true);
          setisVisible(false);
          const chargerList = addParameterRefChargers?.filter(
            charger => chargingStationList?.some(chargingStation => chargingStation?.id === charger?.chargingStationId)
          );

          if (chargerList?.length === 1) {
            setaddParameterFormData({
              ...addParameterFormData,
              customer: response,
              chargingStation: chargingStationList,
              chargers: chargerList?.map((item) => item?.id)
            })
            const chargerOptions =
              chargerList?.map(charger => ({
                label: charger?.visibleId,
                value: charger?.id,
                chargerId: charger?.id
              }))
            setSelectedChargers(chargerOptions);
            setSingleChargerOnly(true)
          }
          else if (chargerList && chargerList?.length > 1) {
            setSingleChargerOnly(false);
            const chargerOptionsWithAll = [
              { label: 'All', value: 'all', chargerId: 'all' },
              ...(chargerList?.map(charger => ({
                label: charger?.visibleId,
                value: charger?.id,
                chargerId: charger?.id
              })) || [])
            ];

            if (chargerOptionsWithAll && chargerOptionsWithAll?.length > 1) {
              setChargerOptions(chargerOptionsWithAll);
            }
            else {
              setChargerOptions([]);
            }
          }
        }
        //if customer has multiple charging stations and customer is single 
        else if (chargingStationList && chargingStationList?.length > 1) {
          setSingleChargingStationOnly(false);
          let chargingStationOptionsWithAll: { label: string; value: string; }[] = [];
          chargingStationOptionsWithAll = [
            ...chargingStationList.map(station => ({
              label: station?.name,
              value: station?.id,
            })),
          ];
          const hasAllOption = chargingStationOptionsWithAll?.some(option => option?.label === 'All');
          if (!hasAllOption && chargingStationOptionsWithAll?.length > 0) {
            chargingStationOptionsWithAll?.unshift({ label: 'All', value: 'all' });
          }
          setChargingStationOptions(chargingStationOptionsWithAll);
        }
      }
    });
    dispatch(fetchAddParameterRefChargingStations({}));
    dispatch(fetchAddParameterRefChargers({}));
    setSingleCustomerOnly(false);
  });

  // TODO: Clear all redux states on unmount

  useEffect(() => {
    const cleanUpFunction = () => {
      dispatch(setExpandedToggleIds([]));
      const action =  {
        type: chargerTypes.FETCH_CHARGER_PARAMETERS_SETTINGS_MASTER_INFO_DATA,
        chargerParametersSettingsMasterInfo: null
      }
      dispatch(action)
    };
    window.addEventListener('beforeunload', cleanUpFunction);
    return () => {
      window.removeEventListener('beforeunload', cleanUpFunction);
      cleanUpFunction();
    };
  }, [])

  // Method: Update the value of parameters and check the validations for Input field while editing settings parameters
  const handleChange = useCallback((key, value, subParameter) => {
    const existingData = cloneDeep(parametersSettingsData)
    const data = updateSettingsValueByKey(existingData, 'key', key, value)

    setParametersSettingsData(data)
    if (subParameter?.fieldType === 'Input') {
      const inValidInputErrorMsg = checkValidationsAndShowError(subParameter, value)
      setErrorMessage(inValidInputErrorMsg != null ? inValidInputErrorMsg : '')
    }
  }, [parametersSettingsData])

  // Method: Called when user cancel the changes made on settings Parameters

  const handleCancel = useCallback(() => {
    const existingData = cloneDeep(settingsInitialData)
    setParametersSettingsData(existingData)
    setErrorMessage('')
  }, [settingsInitialData])

  // Method: Called when user confirm while changing settings Parameters
  const handleSubmit = async (subParameter): Promise<void> => {
    let finalValue: string | number = removeParameterValueUnit(subParameter)
    if (subParameter?.dataType === 'Integer' && subParameter?.fieldType === 'Input') {
      finalValue = parseInt(finalValue); // Converting parameter value to Integer
    } else if (subParameter?.dataType === 'Double' && subParameter?.fieldType === 'Input') {
      finalValue = parseFloat(finalValue).toFixed(subParameter?.precision || 2) // Converting parameter value to Double and limiting it to either (precision) or 2 decimal points
    }
    const val = [finalValue];
    handleSelectedParameterChange(subParameter?.key, subParameter?.visibleName, val);
    setSettingsInitialData(parametersSettingsData)
  }

  const handleDateTimeChange = (date) => {

 const currentDate = new Date(); 
  if (date >= currentDate) {
    setSelectedDate(date);
    handleFormDataChange(date, 'scheduledAt');
  }
  };

  const handleFormDataChange = useCallback((val, formKey) => {
    const formData = cloneDeep(addParameterFormData);
    if (val?.id) {
      formData[formKey] = val?.id;
    }
    else {
      formData[formKey] = val;
    }
    setaddParameterFormData(formData);
    if (formKey === 'customer') {
      setCustomerSelected(val?.map((item) => item?.value));
      // TODO: Empty the values
      if (!val || val?.length === 0) {
        formData.chargingStation = [];
        formData.chargers = [];
        setChargingStationOptions([]);
        setChargerOptions([]);
        setParameterValues([]);
        setSelectedChargers([]);
        setSingleChargingStationOnly(false);
        setSingleChargerOnly(false);
      }
      else if (val?.length === 1) {
        const chargingStationList = addParameterRefChargingStations?.filter(
          station => val?.some(customer => customer?.id === station?.customerId)
        );
        if (chargingStationList?.length === 1) {
          setaddParameterFormData({
            ...addParameterFormData,
            customer: val,
            chargingStation: chargingStationList
          })
          setStationSelected(chargingStationList?.map((item) => item?.id));
          //Chargers checked , whether single or multiple      
          setSingleChargingStationOnly(true);
          setisVisible(false);
          const chargerList = addParameterRefChargers?.filter(
            charger => chargingStationList?.some(chargingStation => chargingStation?.id === charger?.chargingStationId)
          );
          if (chargerList?.length === 1) {
            setaddParameterFormData({
              ...addParameterFormData,
              customer: val,
              chargingStation: chargingStationList,
              chargers: chargerList?.map((item) => item?.id)
            })
            const chargerOptions =
              chargerList?.map(charger => ({
                label: charger?.visibleId,
                value: charger?.id,
                chargerId: charger?.id
              }))
            setSelectedChargers(chargerOptions);
            setSingleChargerOnly(true)
          }
          else if (chargerList && chargerList?.length > 1) {
            setSingleChargerOnly(false);
            const chargerOptionsWithAll = [
              { label: 'All', value: 'all', chargerId: 'all' },
              ...(chargerList?.map(charger => ({
                label: charger?.visibleId,
                value: charger?.id,
                chargerId: charger?.id
              })) || [])
            ];
            if (chargerOptionsWithAll && chargerOptionsWithAll?.length > 1) {
              setChargerOptions(chargerOptionsWithAll);
            }
            else {
              setChargerOptions([]);
            }
          }
        }
        else {
          setSingleChargingStationOnly(false);
          setSingleChargerOnly(false);
          setaddParameterFormData((prevState) => ({
            ...prevState,
            chargingStation: [],
            chargers: [],
          }))
          setChargerOptions([]);
          setSelectedChargers([]);
          let chargingStationOptionsWithAll: { label: string; value: string; }[] = [];
          const filteredChargingStations = addParameterRefChargingStations?.filter(
            station => val?.some(customer => customer?.id === station?.customerId)
          ) || [];
          chargingStationOptionsWithAll = [
            ...filteredChargingStations.map(station => ({
              label: station?.name,
              value: station?.id,
            })),
          ];
          const hasAllOption = chargingStationOptionsWithAll?.some(option => option?.label === 'All');
          if (!hasAllOption && chargingStationOptionsWithAll?.length > 0) {
            chargingStationOptionsWithAll?.unshift({ label: 'All', value: 'all' });
          }
          setChargingStationOptions(chargingStationOptionsWithAll);
        }
      }
      else if (addParameterRefChargingStations && val?.length > 1) {
        setSingleChargingStationOnly(false);
        setSingleChargerOnly(false);
        setaddParameterFormData((prevState) => ({
          ...prevState,
          chargingStation: [],
          chargers: [],
        }))
        setSelectedChargers([]);
        setChargerOptions([]);
        let chargingStationOptionsWithAll: { label: string; value: string; }[] = [];
        const filteredChargingStations = addParameterRefChargingStations?.filter(
          station => val?.some(customer => customer?.id === station?.customerId)
        );
        chargingStationOptionsWithAll = [
          ...filteredChargingStations.map(station => ({
            label: station?.name,
            value: station?.id,
          })),
        ];
        const hasAllOption = chargingStationOptionsWithAll?.some(option => option?.label === 'All');
        if (!hasAllOption && chargingStationOptionsWithAll?.length > 0) {
          chargingStationOptionsWithAll?.unshift({ label: 'All', value: 'all' });
        }
        setChargingStationOptions(chargingStationOptionsWithAll);
      }
    }
    else if (formKey === 'chargingStation') {
      setStationSelected(val?.map(item => item?.value));
      if (!val || val?.length === 0) {
        setChargerOptions([]);
        formData.chargers = [];
        setSelectedChargers([]);
        setParameterValues([]);
        setSingleChargerOnly(false);
      }
      else if (val?.length === 1) {
        const chargerList = addParameterRefChargers?.filter(
          charger => val?.some(chargingStation => chargingStation?.value === charger?.chargingStationId)
        );
        if (chargerList?.length === 1) {
          setaddParameterFormData({
            ...addParameterFormData,
            chargingStation: val,
            chargers: chargerList?.map((item) => item?.id)
          })
          const chargerOptions =
            chargerList?.map(charger => ({
              label: charger?.visibleId,
              value: charger?.id,
              chargerId: charger?.id
            }))
          setSelectedChargers(chargerOptions);
          setSingleChargerOnly(true)
        }
        else {
          setSingleChargerOnly(false);
          setaddParameterFormData((prevState) => ({
            ...prevState,
            chargers: [],
          }))
          setSelectedChargers([]);
          const filteredChargers = addParameterRefChargers?.filter(
            charger => val?.some(chargingStation => chargingStation?.value === charger?.chargingStationId)
          ) || [];
          const chargerOptionsWithAll = [
            { label: 'All', value: 'all', chargerId: 'all' },
            ...filteredChargers.map(charger => ({
              label: charger?.visibleId,
              value: charger?.id,
              chargerId: charger?.id
            }))
          ]
          if (chargerOptionsWithAll && chargerOptionsWithAll?.length > 1) {
            setChargerOptions(chargerOptionsWithAll);
          }
          else {
            setChargerOptions([]);
          }
        }
      }
      else if (addParameterRefChargers && val?.length > 1) {
        setSingleChargerOnly(false);
        setaddParameterFormData((prevState) => ({
          ...prevState,
          chargers: [],
        }))
        setSelectedChargers([]);
        const filteredChargers = addParameterRefChargers?.filter(
          charger => val?.some(chargingStation => chargingStation?.value === charger?.chargingStationId)
        );
        const chargerOptionsWithAll = [
          { label: 'All', value: 'all', chargerId: 'all' },
          ...filteredChargers.map(charger => ({
            label: charger?.visibleId,
            value: charger?.id,
            chargerId: charger?.id
          }))
        ]
        if (chargerOptionsWithAll && chargerOptionsWithAll?.length > 1) {
          setChargerOptions(chargerOptionsWithAll);
        }
        else {
          setChargerOptions([]);
        }
      }
    }
    else if (formKey == 'chargers') {
      setSelectedChargers(val);
      formData[formKey] = val
        ? val
          .map((item) => item?.value)
          .filter((value) => value !== 'all')
        : [];
      setaddParameterFormData((prevState) => ({
        ...prevState,
        params: [],
      }))

    }
  }, [addParameterFormData, addParameterRefChargingStations, addParameterRefChargers, chargingStationOptions, chargerOptions, handleDateTimeChange, parameterValues, customerSelected, stationSelected]);

  const resetFormData = useCallback(() => {
    setaddParameterFormData(initialParameterFormData);
    setChargingStationOptions([]);
    setChargerOptions([]);
    setParameterValues([]);
    setSelectedChargers([]);
  }, [])
  const disableAddButton = useCallback(() => {
    return (!(addParameterFormData?.customer?.length > 0 && addParameterFormData?.chargingStation?.length > 0
      && addParameterFormData?.chargers?.length > 0))
  }, [addParameterFormData])
  const parameterAddedToast = (): Id => toast.success('Setting added.');
  const somethingWentWrongToast = (): Id => toast.warn('Something went wrong.');
  const btnsList = useMemo(() => {
    return [
      {
        buttonText: 'Save',
        buttonId: 'add',
        btnClassName: disableAddButton() ? 'primary__btn disabled' : 'primary__btn',
        handleClick: async (): Promise<void> => {
          const response: AxiosResponse = await dispatch(createParameters(addParameterFormData));
          if (response?.status === 200 || response?.status === 202) {
            parameterAddedToast();
            setTimeout(() => {
              navigate('/update-settings');
            }, 2000)
          }
          else {
            somethingWentWrongToast();
            setTimeout(() => {
              resetFormData();
            }, 2000)
          }
        },
        isDisabled: disableAddButton(),
        buttonVariant: 'filled',
      },

    ];
  }, [addParameterFormData, disableAddButton]);

  const formArray: IFormContentProp[] = useMemo(() => {
    return [
      {
        rowCountClassName: 'grid-3',
        formObject: [
          !singleCustomerOnly ?
            {
              formLabel: 'Customer',
              isRequired: true,
              objectType: 'dropdown',
              selectDropdownProps: {
                isMultiSelect: true,
                selectValue: addParameterFormData?.customer,
                selectOptions: customerReferenceData ?? [],
                handleSelectOptionChange: (val): void => {
                  handleFormDataChange(val, 'customer');
                },
                selectDropDownId: 'charging-model-dropdown',
                selectDropDownName: 'charging-model-dropdown',
              },
              inputProps: null,
              textAreaProps: null,
            } : {
              formLabel: 'Customer',
              isRequired: true,
              objectType: 'input',
              selectDropdownProps: null,
              inputProps: {
                inputPlaceHolder: 'Type here',
                handleInputChange: (event): void => {
                  handleFormDataChange(event?.target?.value, 'customer');
                },
                inputValue: addParameterFormData?.customer?.[0]?.label,
                inputType: 'string',
                inputFieldId: 'add_charger_mac_address',
                isRequired: false,
                isDisabled: true,
              },
              textAreaProps: null,
            },
          !singleChargingStationOnly ?
            {
              formLabel: 'Charging Station',
              isRequired: true,
              objectType: 'dropdown',
              selectDropdownProps: {
                isMultiSelect: true,
                selectValue: addParameterFormData?.chargingStation,
                selectOptions: chargingStationOptions ?? [],
                handleSelectOptionChange: (val): void => {
                  handleFormDataChange(val, 'chargingStation');
                },
                selectDropDownId: 'charging-model-dropdown',
                selectDropDownName: 'charging-model-dropdown',
              },
              inputProps: null,
              textAreaProps: null,
            } : {
              formLabel: 'Charging Station',
              isRequired: true,
              objectType: 'input',
              selectDropdownProps: null,
              inputProps: {
                inputPlaceHolder: 'Type here',
                handleInputChange: (event): void => {
                  handleFormDataChange(event?.target?.value, 'chargingStation');
                },
                inputValue: addParameterFormData?.chargingStation?.[0]?.name,
                inputType: 'string',
                inputFieldId: 'add_charger_mac_address',
                isRequired: false,
                isDisabled: true,
              },
              textAreaProps: null,
            },
          !singleChargerOnly ?
            {
              formLabel: 'Charger',
              isRequired: true,
              objectType: 'dropdown',
              selectDropdownProps: {
                isMultiSelect: true,
                selectValue: selectedChargers,
                selectOptions: chargerOptions ?? [],
                handleSelectOptionChange: (val): void => {
                  handleFormDataChange(val, 'chargers');
                },
                selectDropDownId: 'charging-model-dropdown',
                selectDropDownName: 'charging-model-dropdown',
              },
              inputProps: null,
              textAreaProps: null,
            } : {
              formLabel: 'Charger',
              isRequired: true,
              objectType: 'input',
              selectDropdownProps: null,
              inputProps: {
                inputPlaceHolder: 'Type here',
                handleInputChange: (event): void => {
                  handleFormDataChange(event?.target?.value, 'chargers');
                },
                inputValue: selectedChargers[0].label || '',
                inputType: 'string',
                inputFieldId: 'add_charger_mac_address',
                isRequired: false,
                isDisabled: true,
              },
              textAreaProps: null,
            },
        ],
      },
    ];
  }, [singleCustomerOnly, addParameterFormData?.chargers, addParameterFormData?.customer, addParameterFormData?.chargingStation, chargingStationOptions, customerReferenceData, chargerOptions, singleChargingStationOnly, singleChargerOnly])
  // Method: Allowing only numbers for dataType (Integer/Double) in Input field while changing settings parameters
  const keyPressValidation = (event, dataType): void => {
    const { key, target } = event;
    // const charCode = event.which ? event.which : event.keyCode;
    if (dataType === 'Integer') {
      if (key === ' ' || (isNaN(Number(key)) && key !== 'Backspace' && !(event.metaKey || event.ctrlKey && key.toLowerCase() === 'v'))) event.preventDefault();
    } else if (dataType === 'Double') {
      if (key === '.' && target?.value?.includes('.')) event.preventDefault()
      if (key === ' ' || (isNaN(Number(key)) && key !== '.' && key !== 'Backspace' && !(event.metaKey || event.ctrlKey && key.toLowerCase() === 'v'))) event.preventDefault();
    }
  }


  // TODO: BreadCrumb navigation Props

  const breadCrumbs = createBreadCrumbProps({
    breadCrumbProps:
      [
        {
          objectType: 'link',
          id: 'setParameters',
        },
        {
          objectType: 'text',
          id: 'text',
          text: 'Set New Parameter'
        }
      ]
  })

  const customFormProps: ICustomFormProps = useMemo(() => {
    return {
      formContainerClassName: 'add__parameter__wrap',
      breadCrumbprops: {
        containerId: 'add-parameter-breadcrumb',
        containerClassName: 'add-parameter-breadcrumb',
        breadCrumbs: breadCrumbs
      },
      headerPropTypes: {
        header: 'Set New Parameter',
        headerClassName: 'add__parameter__wrap-header-text',
      },
      formArray: formArray
    };
  }, [formArray, btnsList, customerReferenceData])

  //Hand
  const onChangeOfSearchText = useCallback(async (val: string) => {
    await dispatch(fetchParametersSettingsMasterInfo({ chargerIds: addParameterFormData?.chargers, searchTerm: val }))
    // eslint-disable-next-line prefer-const 
    let array: string[] = cloneDeep(toggleExpandedIds)
    const data = chargerParametersSettingsMasterInfo;
    if ( data && data !== null && data?.length > 0) {
      data.forEach(element => {
        const updatedKeys = getAllKeyValues(element, 'key');
        if (updatedKeys && updatedKeys?.length > 0) {
          array = [...array, ...updatedKeys]
        }
      })
    }
    val?.length? dispatch(setExpandedToggleIds(array)) :  dispatch(setExpandedToggleIds([]));
  }, [addParameterFormData,toggleExpandedIds,chargerParametersSettingsMasterInfo])

  const searchBoxConfiguration = useMemo(() => {
    return {
      searchBoxClassName: 'add__parameter__search__box',
      searchFieldId: 'add-parameter-search-box',
      searchFieldName: 'add-parameter-search-box',
      handleSearch: onChangeOfSearchText,
    };
  }, [onChangeOfSearchText]);

  // handling the seleceted and typed input value of parameters

  const handleSelectedParameterChange = (paramKey: string, paramName: string, values) => {
    const existingIndex = parameterValues.findIndex((item) => item?.paramKey === paramKey);
    const flattenedValues = Array.isArray(values) ? [].concat(...values) : [values];
    if (existingIndex === -1) {
      setParameterValues((prevValues) => [
        ...prevValues,
        { paramKey, paramName, values: flattenedValues },
      ]);
    } else {
      setParameterValues((prevValues) => {
        const updatedValues = [...prevValues];
        updatedValues[existingIndex] = { paramKey, paramName, values: flattenedValues };
        return updatedValues;
      });
    }
    // Update formData's params
    setaddParameterFormData((prevState) => ({
      ...prevState,
      params: parameterValues,
    }));
  };

  const sendNowLaterbtnsList = [
    { radioButtonLabel: 'Now', radioButtonId: 'nowBtn' },
    { radioButtonLabel: 'Later', radioButtonId: 'laterBtn' },
  ]

  const handleSendLaterChange = (event, value) => {
    setSelectedbtn(value);
    if (value === 'nowBtn') {

      handleFormDataChange(null, 'scheduledAt');
      setaddParameterFormData((prevState) => ({
        ...prevState,
        sendNow: true,
      }));
    } else {
      setaddParameterFormData((prevState) => ({
        ...prevState,
        sendNow: false,
      }));
    }
  };
  return (
    <>
      <CustomForm {...customFormProps} />
      {isVisible && (
        <div className='add-parameter-content-wrap'>

          <div className='add-parameter-content-wrap-top'>
            <div className='add-parameter-search'>
              <SearchBox
                key={'search'}
                searchFieldId={searchBoxConfiguration?.searchFieldId ?? ''}
                searchFieldName={searchBoxConfiguration?.searchFieldName ?? ''}
                searchBoxClassName={searchBoxConfiguration?.searchBoxClassName ?? ''}
                handleSearch={searchBoxConfiguration?.handleSearch}
              />
            </div>
            <div className="add__parameter__parameters__list">
              <DashboardLoader handleClose={setShowLoader} showLoader={showLoader} loaderText={'Changing the parameter on the chargers!!!'} />
              <ParametersTable
                data={parametersSettingsData}
                containerClassName={'add__paramters__paramters-table'}
                handleChange={handleChange}
                handleCancel={handleCancel}
                errorMessage={errorMessage}
                keyPressValidation={keyPressValidation}
                handleSubmit={handleSubmit} />
            </div>
          </div>
          <div className='add__parameter__btns'>
            <div className='send-now-and-later-box'>
              <RadioButton
                buttonsList={sendNowLaterbtnsList}
                handleSelectedRadioBtnChange={handleSendLaterChange}
                selectedRadioBtnValue={selectedbtn}
                radionBtnGrpName="customerOptions"
              />
              {selectedbtn === 'laterBtn' && (
                <div className='add-parameter-scheduled-date'>
                  {/* Additional content for sending later */}
                  <CustomDatePicker
                    startDate={selectedDate}
                    showTimePicker={true}
                    endDate={null}
                    selectsRange={false}
                    onChange={handleDateTimeChange}
                    datePickerClassName={'add_parameter_scheduled_date_time'}
                    timeInterval={15}
                  />
                </div>
              )}
            </div>
            <CustomButtonGroup buttonsList={btnsList} buttonGroupClassName='button__group__footer' />
          </div>

        </div>

      )}
    </>
  );
};

export default AddParameter;
