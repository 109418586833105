import { filterTypes } from '../reduxExports';

export const dashboardInitialFilterValues: InnerFilterState[] = [
  {
    key: 'chargerType',
    value: null
  },
  {
    key: 'severity',
    value: null
  }
]

export const alarmInitialFilterValues: InnerFilterState[] = [
  {
    key: 'chargerType',
    value: null
  },
  {
    key: 'severity',
    value: null
  },
  {
    key: 'alarmSetTimeStamp',
    value: null
  },
  {
    key: 'alarmClearedTimeStamp',
    value: null
  },
  {
    key: 'alarmType',
    value: null
  },
  {
    key: 'chargerId',
    value: null
  },
  {
    key:'chargerStatus',
    value:null
  }
]

export const chargerInitialFilterValues: InnerFilterState[] = [
  {
    key: 'chargerType',
    value: null
  },
  {
    key: 'location',
    value: null
  },
  {
    key: 'chargerStatus',
    value: null
  },
  {
    key: 'softwareVersion',
    value: null
  },
  {
    key: 'alarmStatus',
    value: null
  },
  {
    key: 'visibility',
    value: null
  },
  {
    key:'chargingStationId',
    value:null
  }
  
]

export const parametersInitialFilterValues: InnerFilterState[] = [
  {
    key: 'param',
    value: null
  },
  {
    key: 'user',
    value: null
  },
  {
    key: 'setInterval',
    value: null
  },

]

export const chargingStationsInitialFilterValues: InnerFilterState[] = [
  {
    key: 'location',
    value: null
  },
]


export const customersInitialFilterValues: InnerFilterState[] = []

export const customerEditHistoryInitialFilterValues: InnerFilterState[] = [
  {
    key: 'updateInterval',
    value: null,
  }
]
export const chargingStationEditHistoryInitialFilterValues: InnerFilterState[] = [
  {
    key: 'stationUpdateInterval',
    value: null,
  }
]

export const customerChargerInitialFilterValues: InnerFilterState[] = [
  {
    key: 'location',
    value: null
  },
  {
    key: 'chargerStatus',
    value: null
  },
  {
    key: 'softwareVersion',
    value: null
  },
  {
    key: 'alarmStatus',
    value: null
  }
]

export const usersInitialFilterValues: InnerFilterState[] = [
  {
    key: 'orgName',
    value: null
  },
]

export const chargingHistoryReportInitialFilterValues: InnerFilterState[] = [
  {
    key: 'chargerId',
    value: null,
  },
  {
    key: 'chargingHistoryInterval',
    value: null
  }
]

export const chargerLogsReportInitialFilterValues: InnerFilterState[] = [
  {
    key: 'chargerId',
    value: null,
  },
  {
    key: 'chargerLogStatusInterval',
    value: null
  },
  {
    key: 'chargerLogSettingInterval',
    value: null
  },
  {
    key: 'chargerLogSystemLogInterval',
    value: null
  }
]

export const exceptionLogsReportInitialFilterValues: InnerFilterState[] = [
  {
    key: 'chargerId',
    value: null,
  },
  {
    key: 'logSeverity',
    value: null
  },
  {
    key: 'logInterval',
    value: null
  }
]

export const chargerPanelInitialFilterValues: InnerFilterState[] = [
  {
    key: 'historyType',
    value: null
  },
  {
    key: 'statType',
    value: null
  },
  {
    key: 'historyInterval',
    value: null

  },
  {
    key: 'statInterval',
    value: null
  },
  {
    key: 'connectorId',
    value: null
  }
]


export const screenFiltersInitialState: ScreenFilterState[] = [
  {
    screen: 'dashboard',
    filters: dashboardInitialFilterValues
  },
  {
    screen: 'chargers',
    filters: chargerInitialFilterValues
  },
  {
    screen: 'alarms',
    filters: alarmInitialFilterValues
  },
  {
    screen: 'parameters',
    filters: parametersInitialFilterValues
  },
  {
    screen: 'chargingStations',
    filters: chargingStationsInitialFilterValues
  },
  {
    screen: 'customerCharger',
    filters: customerChargerInitialFilterValues
  },
  {
    screen: 'chargingHistoryReport',
    filters: chargingHistoryReportInitialFilterValues
  },
  {
    screen: 'chargerLogsReport',
    filters: chargerLogsReportInitialFilterValues
  },
  {
    screen: 'customerEditHistory',
    filters: customerEditHistoryInitialFilterValues
  },
  {
     screen: 'chargingStationEditHistory',
     filters:chargingStationEditHistoryInitialFilterValues
  },
  {
    screen: 'customers',
    filters: customersInitialFilterValues
  },
  {
    screen: 'users',
    filters: usersInitialFilterValues
  },
  {
    screen: 'chargerPanel',
    filters: chargerPanelInitialFilterValues
  },
  {
    screen: 'exceptionLogsReport',
    filters: exceptionLogsReportInitialFilterValues
  }
]


export const globalFiltersInitialState: InnerFilterState[] = [
  {
    key: 'customer',
    value: null
  },
]

const filterInitialState: FilterDataState = {
  globalFilters: globalFiltersInitialState,
  screenFilters: screenFiltersInitialState,
};

const filterReducer = (state: FilterDataState = filterInitialState, action: FilterDataAction): FilterDataState => {
  switch (action.type) {
    case filterTypes.SET_GLOBAL_FILTERS_DATA:
      return {
        ...state,
        globalFilters: action.globalFilters,
      };

    case filterTypes.SET_SCREEN_FILTERS_DATA:
      return {
        ...state,
        screenFilters: action.screenFilters,
      };
    default:
      return state;
  }
};


export default filterReducer;