import { reportTypes } from '../reduxExports'

const chargersHistoryReportInitialState: ReportState = {
    chargingHistoryColumnHeaders: null,
    chargingHistoryData: null,
    chargingHistoryCount: 0,
    reportLoader: false,
    chargerLogsCount: 0,
    chargerLogsColumnHeaders:null,
    chargerLogsData: null,
    exceptionLogsCount: 0,
    exceptionLogsColumnHeaders: null,
    exceptionLogsData: null,
    chargingHistoryListTableProps: {
        view: 'list',
        sortBy: '',
        order: 'ascending',
        pageSize: 15,
        pageNumber: 1,
        searchTerm: ''
    },
    chargerLogsListTableProps: {
        view: 'list',
        sortBy: '',
        order: 'ascending',
        pageSize: 15,
        pageNumber: 1,
        searchTerm: ''
    },
    exceptionLogsListTableProps: {
        view: 'list',
        sortBy: '',
        order: 'ascending',
        pageSize: 15,
        pageNumber: 1,
        searchTerm: ''
    }
}

const reportsReducer = (state: ReportState = chargersHistoryReportInitialState, action: ReportAction): ReportState => {
    switch (action.type) {
        case reportTypes.FETCH_CHARGERS_HISTORY_DATA:
            return {
                ...state,
                chargingHistoryColumnHeaders: action.chargingHistoryColumnHeaders,
                chargingHistoryData: action.chargingHistoryData,
                chargingHistoryCount: action.chargingHistoryCount
            }
        case reportTypes.SET_REPORT_LOADER:
            return {
                ...state,
                reportLoader: action.reportLoader
            }
        case reportTypes.SET_CHARGING_HISTORY_LIST_TABLE_PROPS:
            return {
                ...state,
                chargingHistoryListTableProps: action.chargingHistoryListTableProps
            }
        case reportTypes.CLEAR_CHARGING_HISTORY_LIST_DATA:
            return {
                ...state,
                chargingHistoryColumnHeaders: null,
                chargingHistoryData: null,
                chargingHistoryCount: 0,
                reportLoader: false,
                chargingHistoryListTableProps: {
                    view: 'list',
                    sortBy: '',
                    order: 'ascending',
                    pageSize: state.chargingHistoryListTableProps?.pageSize || 15,
                    pageNumber: state.chargingHistoryListTableProps?.pageNumber || 1,
                    searchTerm: ''
                }
            }           
        case reportTypes.FETCH_CHARGER_LOGS_DATA:
            return {
                ...state,
                chargerLogsColumnHeaders: action.chargerLogsColumnHeaders,
                chargerLogsData: action.chargerLogsData,
                chargerLogsCount: action.chargerLogsCount
            }
        case reportTypes.SET_CHARGER_LOGS_LIST_TABLE_PROPS:
            return {
                ...state,
                chargerLogsListTableProps: action.chargerLogsListTableProps
            }
        case reportTypes.CLEAR_CHARGER_LOGS_LIST_DATA:
            return {
                ...state,
                chargerLogsColumnHeaders: null,
                chargerLogsData: null,
                chargerLogsCount: 0,
                reportLoader: false,
                chargerLogsListTableProps: {
                    view: 'list',
                    sortBy: '',
                    order: 'ascending',
                    pageSize: state.chargerLogsListTableProps?.pageSize || 15,
                    pageNumber: state.chargerLogsListTableProps?.pageNumber || 1,
                    searchTerm: ''
                }
            }  
        case reportTypes.FETCH_EXCEPTION_LOGS_DATA:
            return {
                ...state,
                exceptionLogsColumnHeaders: action.exceptionLogsColumnHeaders,
                exceptionLogsData: action.exceptionLogsData,
                exceptionLogsCount: action.exceptionLogsCount
            }
        case reportTypes.SET_EXCEPTION_LOGS_LIST_TABLE_PROPS:
            return {
                ...state,
                exceptionLogsListTableProps: action.exceptionLogsListTableProps
            }
        case reportTypes.CLEAR_EXCEPTION_LOGS_LIST_DATA:
            return {
                ...state,
                exceptionLogsColumnHeaders: null,
                exceptionLogsData: null,
                exceptionLogsCount: 0,
                reportLoader: false,
                exceptionLogsListTableProps: {
                    view: 'list',
                    sortBy: '',
                    order: 'ascending',
                    pageSize: state.exceptionLogsListTableProps?.pageSize || 15,
                    pageNumber: state.exceptionLogsListTableProps?.pageNumber || 1,
                    searchTerm: ''
                }
            }  
        default:
            return state
    }
}

export default reportsReducer