/* eslint-disable @typescript-eslint/no-explicit-any */
import { cloneDeep } from 'lodash';
import { globalTypes } from '../reduxExports';
import rmsStore from '../rmsStore';


export const setExpandedToggleIds: any = (data) => async (dispatch: (arg0: Partial<GlobalDataAction>) => void): Promise<void> => {
  const action: Partial<GlobalDataAction> = {
    type: globalTypes.SET_TOGGLE_IDS,
    toggleExpandedIds: data,
  };
  dispatch(action);
};

export const setTableColumnPrefrences: any = (screenName, operation, data) => async (dispatch: (arg0: Partial<GlobalDataAction>) => void): Promise<void> => {
  const previousPrefrences: ITableColumnPrefrences[] = cloneDeep(rmsStore.getState().globalReducer.screenTableColumnPrefrences);
  const selectedScreenPrefrences = previousPrefrences?.find((filter) => filter?.screen === screenName);
  if (selectedScreenPrefrences) {
    if (operation === 'columnOrder') selectedScreenPrefrences.columnOrders = data
    else if (operation === 'columnResize') selectedScreenPrefrences.columnDimensions = data
    else if (operation === 'columnVisibility') selectedScreenPrefrences.columnVisibilityModel = data
  }
  const action: Partial<GlobalDataAction> = {
    type: globalTypes.SET_TABLE_COLUMN_PREFRENCES,
    screenTableColumnPrefrences: previousPrefrences,
  };
  dispatch(action);
};