import { LinkButtonPropTypes } from '../globalUtils/LinkButton/LinkButton'

const BreadCrumbsNavigations: LinkButtonPropTypes[] = [
    {
        linkBtnId: 'text',
        link: '',
        btnText: ''
    },
    {
        linkBtnId: 'dashboard',
        link: '/dashboard',
        btnText: 'Dashboard',
    },
    {
        linkBtnId: 'alarms',
        link: '/alarms',
        btnText: 'Alarms',
    },
    {
        linkBtnId: 'alarmDetail',
        link: '/alarm?alarmId=',
        btnText: 'Alarm Detail',
    },
    {
        linkBtnId: 'chargers',
        link: '/manage-chargers',
        btnText: 'Chargers',
    },
    {
        linkBtnId: 'chargerDetail',
        link: '/charger?chargerId=',
        btnText: 'Charger Detail',
    },
    {
        linkBtnId: 'addCharger',
        link: '/add-charger',
        btnText: 'Add Charger',
    },
    {
        linkBtnId: 'editCharger',
        link: '/charger-detail',
        btnText: 'Edit Charger',
    },
    {
        linkBtnId: 'viewCharger',
        link: '/charger-detail',
        btnText: 'View Charger',
    },
    {
        linkBtnId: 'setParameters',
        link: '/update-settings',
        btnText: 'Update Settings',
    },
    {
        linkBtnId: 'customers',
        link: '/customers',
        btnText: 'Customers',
    },
    {
        linkBtnId: 'addCustomer',
        link: '/customer',
        btnText: 'Add Customer',
    },
    {
        linkBtnId: 'editCustomer',
        link: '/customer',
        btnText: 'Edit Customer',
    },
    {
        linkBtnId: 'editChargingStation',
        link: '/station',
        btnText: 'Edit Station',  
    },
    {
        linkBtnId: 'chargingStation',
        link: '/charging-stations',
        btnText: 'Charging Station',
    },
    {
        linkBtnId: 'viewCustomer',
        link: '/customer',
        btnText: 'Customer Detail',
    },
    {
        linkBtnId: 'updateHistory',
        link: '/update-history',
        btnText: 'Update History',
    },
    {
        linkBtnId: 'organisations',
        link: '/manage-org',
        btnText: 'Organisations',
    },
    {
        linkBtnId: 'addOrg',
        link: '/org',
        btnText: 'Add Organisation',
    },
    {
        linkBtnId: 'editOrg',
        link: '/org',
        btnText: 'Edit Organisation',
    },
    {
        linkBtnId: 'viewOrg',
        link: '/org',
        btnText: 'Organisation Details',
    },
    {
        linkBtnId: 'users',
        link: '/manage-users',
        btnText: 'Users',
    },
    {
        linkBtnId: 'addUser',
        link: '/user',
        btnText: 'Add User',
    },
    {
        linkBtnId: 'editUser',
        link: '/user',
        btnText: 'Edit User',
    },
    {
        linkBtnId: 'viewUser',
        link: '/user',
        btnText: 'User Details',
    },
    {
        linkBtnId: 'roles',
        link: '/roles',
        btnText: 'Roles',
    },
    {
        linkBtnId: 'reports',
        link: '/reports',
        btnText: 'Reports',
    },
    {
        linkBtnId: 'chargingStations',
        link: '/charging-stations',
        btnText: 'Charging Stations',
    },
    {
        linkBtnId: 'addChargingStation',
        link: '/station',
        btnText: 'Add Charging Station',
    },

]


export default BreadCrumbsNavigations