/* eslint-disable @typescript-eslint/ban-types */
import { GridRenderCellParams, GridRowModel, GridValidRowModel } from '@mui/x-data-grid-pro';
import React, { ReactNode, memo, useCallback, useEffect, useMemo, useRef, useState, } from 'react';
import {useNavigate } from 'react-router-dom';
import {
  CustomTooltipWithLabel,
  NoDataComponentAlarms,
  TableLayout,
  alarmCardMapper,
  formatRelativeTimeForTable,
  useAppSelector
} from '../../globalUtils/globalExports';
import { IconWithTooltip } from '../../globalUtils/globalIcons';
import {
  getAlarmReducer
} from '../../rmsReduxStore/reduxExports';
import AlarmRowCard from './AlarmRowCard';

interface AlarmTableProps {
  contentHeight: number;
  containerClassName: string;
  calledFrom: 'history' | 'active' | 'charger_panel';
  fetch_type?: 'active' | 'history';
  view?: 'list' | 'detail';
  id?: string;
  alarmFilterVal?: string;
  layoutCallback: Function;
  setSelectedAlarmCard?: React.Dispatch<React.SetStateAction<string>>,
  pageNumber?: number;
  tablePageSize?: number;
  sortField?: string;
  sortOrder?: string;
  columnPrefrences?:ITableColumnPrefrences
  handleColumnPrefrenceChange?: Function
}



const AlarmTable = memo(function AlarmTable({
  contentHeight,
  containerClassName,
  calledFrom,
  fetch_type,
  view,
  layoutCallback,
  id,
  pageNumber,
  tablePageSize,
  sortField,
  sortOrder,
  columnPrefrences,
  handleColumnPrefrenceChange
}: AlarmTableProps) {
  const navigate = useNavigate();
  const { alarms, alarmsCount} = useAppSelector(getAlarmReducer);
  const tableRef = useRef<HTMLDivElement>(null);
  const dashboardRef = useRef<HTMLDivElement>(null);
  const [tableHeight, setTableHeight] = useState(0);


  // *****************************************************************************************************************
  const handleSelectedAlarm = useCallback((event: React.MouseEvent<HTMLElement>, data: GridRowModel<GridValidRowModel> | undefined) => {
    event?.stopPropagation()
    navigate('/alarm?alarmId=' + data?.id, {
      state: {
        row: data,
        calledFrom: calledFrom,
      },
    });
  }, [[navigate, calledFrom]]);

  const alarmLayoutCallback = useCallback((pageNumber: number, pageSize: number, view: string, field: string, order?: string | null, searchTerm?: string, chargerId?: string) => {
    layoutCallback?.(pageNumber, pageSize, view, field, order, fetch_type, searchTerm, chargerId)
  }, [fetch_type, layoutCallback])

  // *****************************************************************************************************************
  // TODO: Alarm Column Configuration....
  const alarmTableColumns = useMemo(() => {
    const availableColumns = [
      {
        field: 'severity',
        headerName: 'Category',
        flex: 1,
        sortable: true,
        minWidth: 110,
        maxWidth: 120,

        headerAlign: 'left',
        renderCell: (
          params: GridRenderCellParams<GridValidRowModel, Date>
        ): ReactNode => {
          const rowData = params?.row;
          const severity = rowData?.severity;
          const icon = alarmCardMapper?.[severity]?.alarmSummaryIcon;
          const tooltipText = alarmCardMapper?.[severity]?.title;

          return (
            <div className="active__alarms__severity__cell__wrap">
              <IconWithTooltip iconClassName={icon} toolTipText={tooltipText} />          
            </div>
          );
        },
      },
      {
        field: 'customerName',
        headerName: 'Customer',
        minWidth: 150,
        flex: 1,
        editable: false,
        renderCell: (
          params: GridRenderCellParams<GridValidRowModel>
        ): ReactNode => {
          const rowData = params?.row;
          return (
            <div
              className="active__alarms__name__wrap"
              onClick={(e) => {e?.stopPropagation();navigate('/customer',{ state: { id: rowData?.customerId, mode: 'View', name: rowData?.customerName } });}}
            >
              <div className="individual__charger__text">
                <div className="alarm_name-text">
                  {rowData.customerName}
                </div>
              </div>
            </div>
          );
        },
      },
      {
        field: 'chargerVisibleId',
        flex: 1,
        headerName: 'Charger',
        minWidth: 150,
        renderCell: (
          params: GridRenderCellParams<GridValidRowModel>
        ): ReactNode => {
          const rowData = params?.row;
          return (
            <div
              className="active__alarms__name__wrap"
              onClick={(e) => {e?.stopPropagation();navigate('/charger?chargerId=' + rowData?.chargerVisibleId,{ state: { chargerId: rowData?.chargerId }});}}
            >
              <div className="individual__charger__text">
                <div className="alarm_name-text">
                  {rowData.chargerVisibleId}
                </div>
              </div>
            </div>
          );
        },
      },
      {
        field: 'alarmVisibleName',
        headerName: 'Alarm',
        minWidth: 180,
        editable: false,
        // flex: 1,
        maxWidth: 500,
        hideable:false,
        renderCell: (
          params: GridRenderCellParams<GridValidRowModel>
        ): ReactNode => {
          const rowData = params?.row;
          return (
            <div
              className="active__alarms__name__wrap"
              onClick={(e) => handleSelectedAlarm(e, rowData)}
            >
              <div className="individual__charger__text">
                <div className="alarm_name-text">
                  {rowData.alarmVisibleName}
                </div>
              </div>
            </div>
          );
        },
      },
      {
        field: 'chargerStatus',
        headerName: 'Charger Status',
        minWidth: 110,
        flex: 1,
      },
      {
        field: 'connector',
        headerName: 'Connector',
        minWidth: 150,
        flex: 1,
        editable: false,
      },
      // {
      //   field: 'connectorNumber',
      //   headerName: 'Connector No.',
      //   minWidth: 150,
      //   flex: 1,
      //   editable: false,
      // },

      {
        field: 'alarmStatus',
        headerName: 'Alarm Status',
        minWidth: 110,
        flex: 1,
      },
      {
        field: 'notes',
        headerName: 'Notes',
        minWidth: 150,
        flex: 1,
        sortable: false,
        renderCell: (params: GridRenderCellParams<GridValidRowModel>): JSX.Element => {
          const rowData = params?.row;
          return <CustomTooltipWithLabel labelId='alarm__dashboard-notes' label={rowData?.notes} />
        },
      },
      {
        field: 'alarmSetTime',
        flex: 0.9,
        headerName: 'Time (Set at)',
        minWidth: 150,
        renderCell: (
          params: GridRenderCellParams<GridValidRowModel>
        ): ReactNode => {
          const rowData = params?.row;
          const date = new Date(rowData?.alarmSetTime);
          return formatRelativeTimeForTable(date);
        },
      },
      {
        field: 'alarmClearTime',
        headerName: 'Time (Cleared at)',
        sortable: true,
        minWidth: 170,
        flex: 0.9,
        renderCell: (
          params: GridRenderCellParams<GridValidRowModel>
        ): ReactNode => {

          const rowData = params?.row;
          if (rowData?.alarmClearTime !== null) {
            const date = new Date(rowData?.alarmClearTime);
            return formatRelativeTimeForTable(date);
          } else {
            return '-'
          }

        },
      },

      {
        field: 'warranty',
        headerName: 'Warranty',
        flex: 1,
        sortable: true,
        minWidth: 110,
        headerAlign: 'center',
        renderCell: (
          params: GridRenderCellParams<GridValidRowModel, Date>
        ): ReactNode => {
          const rowData = params?.row;
          const warranty = rowData?.warranty;
          const icon =
            warranty === 'Active'
              ? 'under__warranty__icon'
              : warranty === 'Expired'
                ? 'no__warranty__icon'
                : '-';
          const tooltipText =
            warranty === 'Active'
              ? 'Under Warranty'
              : warranty === 'Expired'
                ? 'No Warranty'
                : '-';

          return (
            <div className="active__alarms__warranty__cell__wrap">
              <IconWithTooltip iconClassName={icon} toolTipText={tooltipText} />
            </div>
          );
        },
      },
    ];

    const alarmHistoryColumns = [
      'severity',
      'alarmVisibleName',
      'chargerVisibleId',
      'customerName',
      'connector',
      // 'connectorNumber',
      'chargerStatus',
      'alarmStatus',
      'notes',
      'alarmSetTime',
      'alarmClearTime',
    ];
    const activeAlarmColumns = [
      'severity',
      'alarmVisibleName',
      'chargerVisibleId',
      'customerName',
      'connector',
      // 'connectorNumber',
      'chargerStatus',
      'alarmStatus',
      'notes',
      'alarmSetTime',
      'warranty',
    ];

    if (calledFrom === 'charger_panel' || calledFrom === 'history') {
      return (
        alarmHistoryColumns?.map((item) =>
          availableColumns?.find((column) => column?.field === item)
        ) ?? []
      );
    } else if (calledFrom === 'active') {
      return activeAlarmColumns?.map((item) =>
        availableColumns?.find((column) => column?.field === item)
      );
    }
  }, [calledFrom]);



  // ********************************************
  // TODO: Mobile Configuration for Alarm Columns....
  const mobileViewConfiguration = useMemo(() => {
    return {
      headerDataConfig: {
        headerLeftDataConfig: ['alarmVisibleName', 'chargerVisibleId'],
        headerRightDataConfig: {
          actionIconsComponent: false,
          statusIconComponent: false,
        },
      },

      contentDataConfig: [
        [
          {
            key: 'customerName',
            iconClassName: 'customer__management__icon',
          },

          {
            key: 'connector',
            iconClassName: 'chargers__icon',
          },
        ],

        [
          {
            key: 'alarmSetTime',
            iconClassName: 'mobile__view__time__icon',
          },
          {
            key: 'alarmClearTime',
            iconClassName: 'mobile__view__time__icon',
          },
        ],
      ],
    };
  }, []);



  // *****************************************
  // TODO: fetch rows and sort callbacks...
  const getAlarmHistoryRows = useCallback(() => {
    return alarms !== null && alarms?.length > 0
      ? alarms?.map((alarm) => {
        const { alarmId, ...rest } = alarm;
        return { id: alarmId, ...rest };
      })
      : [];
  }, [alarms]);


  // *******************************************************
  // TODO: Screen Resize handlers...
  const calculateContentHeight = useCallback((parentHeight: number) => {
    if (tableRef.current != null) {
      setTableHeight(parentHeight);
    }
  }, [tableRef.current]);

  useEffect(() => {
    const ro = new ResizeObserver(() => {
      calculateContentHeight(contentHeight);
    });
    calculateContentHeight(contentHeight);

    if (tableRef.current != null) {
      ro.observe(tableRef.current);
    }
  }, [tableRef.current, calculateContentHeight, contentHeight]);

  useEffect(() => {
    calculateContentHeight(contentHeight);
    window.addEventListener('resize', () => {
      calculateContentHeight(contentHeight)
    });
    return (): void => {
      window.removeEventListener('resize', () => {
        calculateContentHeight(contentHeight)
      })
    };
  }, [calculateContentHeight, contentHeight]);



  return (
    <div ref={dashboardRef} className={'charger__panel__alarm__history__dashboard ' + containerClassName} style={{ height: `${contentHeight}px` }}>
      <div ref={tableRef} style={{ height: `${tableHeight}px` }} className="charger__panel__alarm__history__table__wrap">
        <TableLayout
          key={'table' + id}
          gridColumns={alarmTableColumns}
          tableRows={getAlarmHistoryRows()}
          tableId={'alarm-history-table'}
          tableClassName={'alarm__history__table'}
          layoutView={view}
          pageNumber={calledFrom === 'charger_panel' ? 1 : pageNumber}
          mobileViewConfiguration={mobileViewConfiguration}
          listLayoutCallBack={alarmLayoutCallback}
          renderCustomRowComponent={view === 'detail'}
          customNoDataComponent={NoDataComponentAlarms}
          customRowComponent={AlarmRowCard}
          disableHeader={view === 'detail'}
          totalCount={alarmsCount}
          totalCountText={'alarms'}
          showSkeleton={!alarms}
          tablePageSize={tablePageSize}
          sortField={sortField}
          sortOrder={sortOrder}
          columnPrefrences={columnPrefrences}
          handleColumnPrefrenceChange={handleColumnPrefrenceChange}
        />
      </div>
    </div>
  );
});

export default AlarmTable;
