/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { chargingStationTypes } from '../reduxExports'
import hwaxios from '../api-service/HWAxios';
import { isAxiosError } from 'axios';

// Charging Station action Creators

const url = process.env.REACT_APP_SERVER_BASE_URL

export const fetchChargingStationData:any = (reqBody: unknown) => async (dispatch: (arg0: Partial<ChargingStationAction>) => void) => {
  try {
    const loaderStartAction: Partial<ChargingStationAction> = {
      type: chargingStationTypes.SET_CHARGING_STATION_LOADER,
      loader: true
    }
    dispatch(loaderStartAction);
    const response = await hwaxios.post(url + '/charging-stations', reqBody)
    const action: Partial<ChargingStationAction> = {
      type: chargingStationTypes.FETCH_CHARGING_STATION_DATA,
      chargingStations: response.data?.data?.chargingStations,
      chargingStationsCount: response.data?.data?.count,
    }
    dispatch(action)
    if (response?.status === 200) {
      const loaderEndAction: Partial<ChargingStationAction> = {
        type: chargingStationTypes.SET_CHARGING_STATION_LOADER,
        loader: false
      }
      dispatch(loaderEndAction);
    }
  } catch (error) {
    const loaderEndAction: Partial<ChargingStationAction> = {
      type: chargingStationTypes.SET_CHARGING_STATION_LOADER,
      loader: false
    }
    dispatch(loaderEndAction);
    console.error(error)
    throw error
  }
}

export const exportToCsvChargingStationData:any = (reqParams: unknown) => async () => {
  try {
    const { data, status } = await hwaxios.post(url + '/charging-stations/export', reqParams)
    return { data, status };
  } catch (error) {
    if (isAxiosError(error)) {
      if (error.response) {
        const data = error.response.data;
        const status = error.response.status;
        return { data, status };
      }
    } else {
      return {
        data: 'Something went wrong', status: 400
      }
    }
  }
}

export const setTableListProps: any = (data) => async (dispatch: (arg0: Partial<ChargingStationAction>) => void): Promise<void> => {
  const action: Partial<ChargingStationAction> = {
    type: chargingStationTypes.SET_CHARGING_STATIONS_TABLE_PROPS,
    chargingStationTableProps: data,
  };
  dispatch(action);
}

export const fetchIndividualStationDetail: any = (reqParams: unknown) => async (dispatch: (arg0: Partial<ChargingStationAction>) => void): Promise<void> => {
  try {
    const loaderStartAction: Partial<ChargingStationAction> = {
      type: chargingStationTypes.SET_CHARGING_STATION_LOADER,
      loader: true
    }
    dispatch(loaderStartAction);
    const response = await hwaxios.get(url + '/charging-station', { params: reqParams })
    const action: Partial<ChargingStationAction> = {
      type: chargingStationTypes.FETCH_SINGLE_STATION_DETAIL,
     singleStationDetail: response?.data?.data?.chargingStation,
    }
    dispatch(action)
    if (response?.status === 200) {
      const loaderEndAction: Partial<ChargingStationAction> = {
        type: chargingStationTypes.SET_CHARGING_STATION_LOADER,
        loader: false
      }
      dispatch(loaderEndAction);
    }
  } catch (error) {
    const loaderEndAction: Partial<ChargingStationAction> = {
      type: chargingStationTypes.SET_CHARGING_STATION_LOADER,
      loader: false
    }
    dispatch(loaderEndAction);
    console.error(error)
    throw error
  }
}

export const updateChargingStation: any = (reqParams: unknown) => async () => {
  try {
    const { data, status } = await hwaxios.put(url + '/charging-station', reqParams)
    return { data, status };
  } catch (error) {
    if (isAxiosError(error)) {
      if (error.response) {
        const data = error.response.data;
        const status = error.response.status;
        return { data, status };
      }
    } else {
      return {
        data: 'Something went wrong', status: 400
      }
    }
  }
}



export const fetchStationUpdateHistory: any = (reqBody: unknown) => async (dispatch: (arg0: Partial<ChargingStationAction>) => void): Promise<void> => {
  try {
    const loaderStartAction: Partial<ChargingStationAction> = {
      type: chargingStationTypes.SET_CHARGING_STATION_LOADER,
      loader: true
    }
    dispatch(loaderStartAction);
    const response = await hwaxios.post(url + '/charging-station/history', reqBody)
    const action: Partial<ChargingStationAction> = {
      type: chargingStationTypes.FETCH_STATION_UPDATE_HISTORY_DATA,
      historyRecords: response?.data?.data?.history,
      historyRecordCount: response?.data?.data?.historyCount,
    }
    dispatch(action)
    if (response?.status === 200) {
      const loaderEndAction: Partial<ChargingStationAction> = {
        type: chargingStationTypes.SET_CHARGING_STATION_LOADER,
        loader: false
      }
      dispatch(loaderEndAction);
    }
  } catch (error) {
    const loaderEndAction: Partial<ChargingStationAction> = {
      type: chargingStationTypes.SET_CHARGING_STATION_LOADER,
      loader: false
    }
    dispatch(loaderEndAction);
    console.error(error)
    throw error
  }
}
export const setChargingStationTableProps: any = (data) => (dispatch: (arg0: Partial<ChargingStationAction>) => void) => {

    const action: Partial<ChargingStationAction> = {
      type: chargingStationTypes.SET_STATION_UPDATE_HISTORY_LIST_TABLE_PROPS,
      historyRecordTableProps: data,
    };
    dispatch(action);
  
}