import { cloneDeep } from 'lodash';
import React, {
  FC,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  AlarmCards,
  DashboardHeader,
  FilterLayout,
  FilterProp,
  HorizontalNavbar,
  SearchBox,
  SidePopover,
  TableSubHeader,
  downloadFile,
  renderHeaderActionButtons,
  useAppDispatch,
  useAppSelector,
} from '../../globalUtils/globalExports';
import { alarmNavList } from './alarmConstants';
import './alarmsDashboard.css';

import {
  alarmTypes,
  exportAlarmHistoryData,
  fetchAlarmCardCountData,
  fetchAlarmListData,
  fetchAlarmSeverityReferenceData,
  fetchChargerVisibleIdReferenceData,
  getAlarmReducer,
  getCurrentFiltersValues,
  getCurrentScreenAppliedFiltersCount,
  getFilterReducer,
  getFilterSelectedValue,
  getGlobalReducer,
  getReferenceDataReducer,
  setAlarmTableProps,
  setFilterData,
  setRedirectedStateForAlarmFilters,
  setTableColumnPrefrences
} from '../../rmsReduxStore/reduxExports';
import AlarmTable from './AlarmTable';
import { CardPropTypes } from '../../globalUtils/AlarmCards/AlarmCards';
import { AlarmCardTableSkeleton } from '../../globalUtils/SkeletonDesign/SkeletonDesign';
import { IconWithTooltip } from '../../globalUtils/globalIcons';
import { useLocation } from 'react-router-dom';
import { Menu, MenuItem } from '@mui/material';
import { Id, toast } from 'react-toastify';
import { AxiosResponse } from 'axios';
import access from '../../auth/service/AccessControl';

const AlarmsDashboard: FC = () => {
  const dispatch = useAppDispatch()
  const location = useLocation();
  const { alarmDashboardCount, alarms, alarmHistoryTableProps, activeAlarmTableProps, alarmFiltersAppliedFromRedirection } = useAppSelector(getAlarmReducer);
  const { alarmSeverityReferenceData, alarmTypeReferenceData, chargerVisibleIdReferenceData, chargerTypeReferenceData,chargerStatusReferenceData } = useAppSelector(getReferenceDataReducer);
  const { screenFilters } = useAppSelector(getFilterReducer)
  const { screenTableColumnPrefrences } = useAppSelector(getGlobalReducer)
  const dashboardRef = useRef<HTMLDivElement>(null);
  const subHeaderRef = useRef<HTMLDivElement>(null);
  const alarmCardsRef = useRef<HTMLDivElement>(null);
  const headerRef = useRef<HTMLDivElement>(null);
  const filterHeaderRef = useRef<HTMLDivElement>(null);
  const [layoutView, setLayoutView] = useState<'list' | 'detail'>('list');
  const [prevLayoutView, setPrevLayoutView] = useState<'list' | 'detail'>('list');
  const [selectedNav, setSelectedNav] = useState<'history' | 'active'>('active');
  const [tableHeight, setTableHeight] = useState(0);
  const [selectedAlarmCard, setSelectedAlarmCard] = useState<string>('all')
  const alarmHistoryTablePropsRef = useRef(alarmHistoryTableProps);
  const activeAlarmTablePropsRef = useRef(activeAlarmTableProps);
  const [exportAnchorEl, setExportAnchorEl] = useState(null);
  const [exportingFile, setExportingFile] = useState(false);
  const [filtersVisible, setFiltersVisible] = useState(false);

  useEffect(() => {
    // Set the initial value of selectedNav based on location state and call the Card Count API.
    if (location.state && location.state.calledFrom === 'history') {
      setSelectedNav('history');
      fetchAlarmCountData('history');
    } else {
      setSelectedNav('active');
      fetchAlarmCountData('active');
    }
  }, [location.state]);

  // ********************************************
  // TODO: Callback wasn't taking the updated value
  useEffect(() => {
    alarmHistoryTablePropsRef.current = alarmHistoryTableProps;
  }, [alarmHistoryTableProps]);

  useEffect(() => {
    activeAlarmTablePropsRef.current = activeAlarmTableProps;
  }, [activeAlarmTableProps]);


  // ******************************************************
  // TODO: set Initial Value of Severity Filter and highlight the selectedCard
  useEffect(() => {
    // if (selectedNav == 'active') {
    const severityFilterSelectedValues = getFilterSelectedValue('severity', 'alarms', false)
    if (severityFilterSelectedValues && severityFilterSelectedValues?.length > 0) {
      const value = severityFilterSelectedValues?.[0].id === 'all' ? 'TOTAL' : severityFilterSelectedValues?.[0].id;
      setSelectedAlarmCard(value?.toUpperCase());
    }
    // }
  }, [screenFilters, selectedNav])

  // ********************************************
  // TODO: Handle onchange of layouts in active alarms
  const handleLayoutToggle = useCallback((val: 'list' | 'detail') => {
    setPrevLayoutView(val);
    setLayoutView?.(val);
  }, [setLayoutView, selectedAlarmCard]);

  const layoutsConfiguration = useMemo(() => {
    return [
      {
        toolTipText: 'List View',
        onIconClick: (): void => {
          handleLayoutToggle('list');
        },
        layout: 'list',
        iconClassName: 'rms__table__dashboard-list__view__icon',
      },
      {
        toolTipText: 'Detailed View',
        onIconClick: (): void => {
          handleLayoutToggle('detail');
        },
        layout: 'detail',
        iconClassName: 'rms__table__dashboard-detailed__view__icon',
      }

    ];
  }, [handleLayoutToggle]);

  // ******************************************************
  // TODO: Handle alarm card click in active alarms
  const handleCardClick = useCallback((props: CardPropTypes): void => {
    // eslint-disable-next-line react/prop-types
    const { headerText } = props
    // eslint-disable-next-line react/prop-types
    const value = headerText === 'TOTAL' ? 'all' : headerText[0] + headerText.slice(1).toLowerCase();
    handleAlarmFilter(value);
    setSelectedAlarmCard(headerText);
  }, [alarmSeverityReferenceData, selectedAlarmCard, selectedNav])

  const handleAlarmFilter = useCallback(async (val): Promise<void> => {
    if (!alarmSeverityReferenceData) {
      await dispatch(fetchAlarmSeverityReferenceData())
    }
    const severity = alarmSeverityReferenceData?.find(severity => severity?.id === val)
    const tableProps = selectedNav === 'history' ? cloneDeep(alarmHistoryTablePropsRef.current) : cloneDeep(activeAlarmTablePropsRef.current);
    if (severity) {
      const res = await setFilterData(severity, 'severity', 'alarms', 'SET', false)(dispatch)
      if (res?.message === 'Action dispatched successfully') {
        layoutCallback?.(
          1,
          tableProps?.pageSize,
          tableProps?.view ?? '',
          tableProps?.sortBy,
          tableProps?.order,
          selectedNav,
          tableProps?.searchTerm ?? '',
          res?.filters)
        // fetchAlarmCountData(selectedNav, res?.filters);
      }
    }
  }, [alarmHistoryTablePropsRef.current, activeAlarmTablePropsRef.current, alarmSeverityReferenceData, selectedNav])

  const handleNavBtnClick = useCallback(async (val: 'history' | 'active') => {
    if (alarms || alarmDashboardCount) {
      // Partial<AlarmsAction> 
      const alarmAction = {
        type: alarmTypes.CLEAR_ALARM_DATA,
      }
      await dispatch(alarmAction);
    }
    if (val === 'active') {
      setLayoutView(prevLayoutView);
    }
    setSelectedNav(val);
    fetchAlarmCountData(val);
    calculateContentHeight(val);
    setSelectedAlarmCard('TOTAL')
  }, [alarms, alarmDashboardCount]);

  // *****************************************************
  // TODO: Handle Alarm api callback
  const layoutCallback = useCallback(async (pageNumber: number, pageSize: number, view: string, field: string | null, order: string, fetch_type: string, searchTerm: string, filterValue) => {
    const tableProps = fetch_type === 'history' ? cloneDeep(alarmHistoryTablePropsRef.current) : cloneDeep(activeAlarmTablePropsRef.current);

    const sortField = field === 'id' ? 'alarmId' : field;
    const alarmFilter = getCurrentFiltersValues('alarms');
    const activeTabFilters = filterValue ? filterValue?.filter((filter) => filter?.key !== 'alarmClearedTimeStamp') : alarmFilter?.filter((filter) => filter?.key !== 'alarmClearedTimeStamp');

    await dispatch(
      fetchAlarmListData({
        pageSize: pageSize,
        pageNumber: pageNumber,
        filters: fetch_type === 'history' ? (filterValue ? filterValue : alarmFilter) : activeTabFilters,
        fetchType: fetch_type,
        sortBy: sortField || tableProps?.sortBy,
        order: order || tableProps?.order,
        searchTerm: searchTerm !== null && searchTerm !== undefined ? searchTerm : fetch_type === 'history' ? alarmHistoryTablePropsRef.current.searchTerm : activeAlarmTablePropsRef.current.searchTerm,
      })
    )
    if (tableProps) {
      tableProps.order = order || tableProps.order;
      tableProps.sortBy = field || tableProps.sortBy;
      tableProps.pageSize = pageSize;
      tableProps.pageNumber = pageNumber;
      if (fetch_type === 'active') {
        tableProps.view = view ?? layoutView;
      }
      if (searchTerm != null && searchTerm != undefined) {
        tableProps.searchTerm = searchTerm;
      }
    }
    dispatch(setAlarmTableProps(tableProps, fetch_type))
  }, [layoutView, alarmHistoryTablePropsRef.current, activeAlarmTablePropsRef.current]);

  // Handler For Column Prefrence Chnages
  const handleColumnPrefrenceChange = useCallback(async (operation, data) => {
    await dispatch(setTableColumnPrefrences('alarms', operation, data))
  }, [])

  const fetchAlarmCountData = useCallback(async (fetch_type?: string, filters?, searchTerm?: string) => {
    const alarmFilter = getCurrentFiltersValues('alarms');
    // On Applying Severity Filter Card Count Data Should not change....
    const filterWithoutSeverity = filters ? filters?.filter((filter) => filter?.key !== 'severity') : alarmFilter?.filter((filter) => filter?.key !== 'severity')
    const activeTabFilters = filterWithoutSeverity?.filter((filter) => filter?.key !== 'alarmClearedTimeStamp');
    dispatch(fetchAlarmCardCountData({
      fetchType: fetch_type || selectedNav,
      searchTerm: searchTerm !== null && searchTerm !== undefined ? searchTerm : fetch_type === 'history' ? alarmHistoryTablePropsRef.current.searchTerm : activeAlarmTablePropsRef.current.searchTerm,
      filters: fetch_type === 'history' ? filterWithoutSeverity : activeTabFilters,
    }));
  }, [alarmHistoryTablePropsRef.current, activeAlarmTablePropsRef.current, selectedNav])

  // ***********************************************************
  // TODO: Handle  Screen Resize and height handlers
  const calculateContentHeight = useCallback((val) => {
    if (headerRef.current != null &&
      dashboardRef.current != null &&
      subHeaderRef.current != null &&
      alarmCardsRef.current != null &&
      filterHeaderRef.current != null
    ) {
      const headerRect = headerRef.current.getBoundingClientRect();
      const dashboardRect = dashboardRef.current.getBoundingClientRect();
      const subHeaderRect = subHeaderRef.current.getBoundingClientRect();
      const alarmCardsRect = alarmCardsRef.current.getBoundingClientRect();
      const filterHeaderRect = filterHeaderRef.current.getBoundingClientRect();
      const headerHeight = headerRect.height;
      const dashboardHeight = dashboardRect.height;
      const subHeaderHeight = subHeaderRect.height;
      const alarmCardHeight = alarmCardsRect.height;
      const filterHeaderHeight = filterHeaderRect.height;


      const remainingHeight =
        val === 'active' && (location.state && location?.state.calledFrom === 'active')
          ? dashboardHeight - headerHeight - filterHeaderHeight - subHeaderHeight - alarmCardHeight
          : dashboardHeight - headerHeight - filterHeaderHeight - subHeaderHeight + alarmCardHeight;
      setTableHeight(remainingHeight);
    }
  }, [headerRef.current, dashboardRef.current, subHeaderRef.current, alarmCardsRef.current, filterHeaderRef.current, selectedNav]);

  useEffect(() => {
    calculateContentHeight(selectedNav);
    window.addEventListener('resize', () => {
      calculateContentHeight(selectedNav)
    });

    return (): void => {
      window.removeEventListener('resize', () => {
        calculateContentHeight(selectedNav)
      });
    };
  }, [calculateContentHeight])

  // ********************************************
  // TODO: Search box Callbacks
  // TODO: handle change of search box...
  const onChangeOfSearchText = useCallback((val: string) => {
    const tableProps = selectedNav === 'history' ? cloneDeep(alarmHistoryTablePropsRef.current) : cloneDeep(activeAlarmTablePropsRef.current);

    if (tableProps) {
      tableProps.searchTerm = val
    }
    const alarmFilter = getCurrentFiltersValues('alarms');
    // const alarmFilterWithoutServerity = alarmFilter?.filter((filter) => filter?.key !== 'severity');
    layoutCallback?.(
      1,
      tableProps?.pageSize,
      tableProps?.view ?? '',
      tableProps?.sortBy,
      tableProps?.order,
      selectedNav,
      val,
      alarmFilter)
    fetchAlarmCountData(selectedNav, alarmFilter, val);
  }, [selectedNav, alarmHistoryTablePropsRef.current, activeAlarmTablePropsRef.current, layoutCallback]);

  const searchBoxConfiguration = useMemo(() => {
    return {
      searchBoxClassName: 'alarm__history__search__box',
      searchFieldId: 'alarm-history-search-box',
      searchFieldName: 'alarm-history-search-box',
      handleSearch: onChangeOfSearchText,
    };
  }, [onChangeOfSearchText]);

  // ************************************************
  // TODO: Export btn handlers....
  const handleClose = (): void => {
    setExportAnchorEl(null);
  };

  const fileDownloadSuccessToast = useCallback((): Id => {
    setExportingFile(false);
    return toast.success('File downloaded.');
  }, []);

  const fileDownloadFailureToast = useCallback((): Id => {
    setExportingFile(false);
    return toast.warn('Something went wrong. Please export again.');
  }, []);

  const onExportBtnClick = useCallback(async (fileType: 'csv' | 'xlsx'): Promise<void> => {
    if (exportingFile) {
      // If already exporting, prevent another export
      return;
    }
    try {
      const response: AxiosResponse = await dispatch(exportAlarmHistoryData({
        fetchType: selectedNav,
        fileType,
        filters: getCurrentFiltersValues('alarms')
      }));

      if (response?.status === 202 || response?.status === 200) {
        downloadFile({ url: response?.data?.url });
        fileDownloadSuccessToast()
      } else {
        fileDownloadFailureToast();
        setExportAnchorEl(null);
      }
    } finally {
      // Ensure that exporting status is updated even if there's an error
      setExportingFile(false);
      setExportAnchorEl(null);

    }
  }, [exportingFile, fileDownloadSuccessToast, fileDownloadFailureToast, selectedNav])


  // ****************************************************************************************************************
  // TODO: Header btns in Alarms Dashboard...
  const headerActionBtns = renderHeaderActionButtons({
    actionBtns:
      [
        {
          id: 'add_filter_btn',
          isAccessible: access?.chargers?.export(),
          headerActionBtnClick: (): void => {   
            setFiltersVisible(true);
          },
          badgeCount: getCurrentScreenAppliedFiltersCount('alarms')
        },
        {
          id: 'export_csv_btn',
          isAccessible: access?.alarms?.export(),
          // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
          headerActionBtnClick: (event): void => {
            setExportAnchorEl(event.currentTarget);
          },
        },
      ],
  })
  const handleFiltersClosePopOver = (): void => {
    setFiltersVisible(false);
   }
   const onResetButtonClick = useCallback(async (callListApi: boolean) => {
    const res = await setFilterData(null, '', 'alarms', 'CLEAR', false)(dispatch)
    if (res?.message === 'Action dispatched successfully') {
      if ((alarmHistoryTablePropsRef?.current || activeAlarmTablePropsRef.current) && callListApi) {
        const tableProps = selectedNav === 'history' ? cloneDeep(alarmHistoryTablePropsRef.current) : cloneDeep(activeAlarmTablePropsRef.current);
        layoutCallback?.(
          1,
          tableProps?.pageSize,
          tableProps?.view ?? '',
          tableProps?.sortBy,
          tableProps?.order,
          selectedNav,
          tableProps?.searchTerm ?? '',
          res?.filters)
          fetchAlarmCountData(selectedNav, res?.filters);
          setSelectedAlarmCard('TOTAL')
      }
    }
    setRedirectedStateForAlarmFilters(false)(dispatch)
  }, [alarmHistoryTablePropsRef.current, activeAlarmTablePropsRef.current, selectedNav,setSelectedAlarmCard])

   const filterFooterBtns = useMemo(() => {
    return [
      {
        buttonText: 'Reset',
        buttonId: 'Reset',
        btnClassName: 'secondary__btn',
        handleClick: (): Promise<void> => onResetButtonClick(true),
        isDisabled: false,
        buttonVariant: 'outlined',
      },
    ];
  }, [onResetButtonClick]);

  // *****************************************
  // TODO: Filter Implementation



  const handleFilterDataChange = useCallback(async (val, filterKey, isGlobal) => {
    if (isGlobal && filterKey === 'customer') {
      const reqBody = {
        customerIds : val?.filter((item) => item?.id === 'all')?.length > 0  ? ['all'] : val?.map((customer) => customer?.id)
      }
      dispatch(fetchChargerVisibleIdReferenceData(reqBody))
    }
    if (filterKey === 'severity') {
      const cardValue = val?.label === 'All' ? 'TOTAL' : val?.value?.toString()?.toLocaleUpperCase()
      setSelectedAlarmCard?.(cardValue)
    }
    const tableProps = selectedNav === 'history' ? cloneDeep(alarmHistoryTablePropsRef.current) : cloneDeep(activeAlarmTablePropsRef.current);
    const res = await setFilterData(val, filterKey, 'alarms', 'SET', isGlobal ? isGlobal : false)(dispatch);
    if (res?.message === 'Action dispatched successfully') {
      layoutCallback?.(
        1,
        tableProps?.pageSize,
        tableProps?.view ?? '',
        tableProps?.sortBy,
        tableProps?.order,
        selectedNav,
        tableProps?.searchTerm ?? '',
        res?.filters
      )
      fetchAlarmCountData(selectedNav, res?.filters);
    }
  }, [alarmHistoryTablePropsRef.current, activeAlarmTablePropsRef.current, selectedNav])

  const alarmFilters: FilterProp[] = useMemo(() => {
    const filterAlarmSetTimeStampValue = getFilterSelectedValue('alarmSetTimeStamp', 'alarms', false);
    const filterAlarmClearedTimeStampValue = getFilterSelectedValue('alarmClearedTimeStamp', 'alarms', false);
    return [
      {
        filterLabel: 'Connector Types',
        filterType: 'dropdown',
        filterId: 'chargerType',
        filterDropdownProps: {
          selectValue: getFilterSelectedValue('chargerType', 'alarms', false),
          selectOptions: chargerTypeReferenceData ?? [],
          handleSelectOptionChange: (val): void => {
            handleFilterDataChange(val, 'chargerType', false)
          },
          selectDropDownId: 'alarm-charger-type-filter-dropdown',
          isMultiSelect: false
        }
      },
      {
        filterLabel: 'Chargers',
        filterType: 'dropdown',
        filterId: 'chargerId',
        filterDropdownProps: {
          selectValue: getFilterSelectedValue('chargerId', 'alarms', false),
          selectOptions: chargerVisibleIdReferenceData ?? [],
          handleSelectOptionChange: (val): void => {
            handleFilterDataChange(val, 'chargerId', false)
          },
          selectDropDownId: 'alarm-chargerId-filter-dropdown',
          isMultiSelect: true
        }
      },
      {
        filterLabel: 'Severity',
        filterId: 'category',
        filterType: 'dropdown',
        filterDropdownProps: {
          selectValue: getFilterSelectedValue('severity', 'alarms', false),
          selectOptions: alarmSeverityReferenceData?.filter((item) => item.id !== 'No Alarm') ?? [],
          handleSelectOptionChange: (val): void => {
            handleFilterDataChange(val, 'severity', false)
          },
          selectDropDownId: 'alarm-severity-filter-dropdown',
          isMultiSelect: false
        }
      },
      {
        filterLabel: 'Alarms',
        filterType: 'dropdown',
        filterId: 'alarmType',
        filterDropdownProps: {
          selectValue: getFilterSelectedValue('alarmType', 'alarms', false),
          selectOptions: alarmTypeReferenceData ?? [],
          handleSelectOptionChange: (val): void => {
            handleFilterDataChange(val, 'alarmType', false)
          },
          selectDropDownId: 'alarm-alarmType-filter-dropdown',
          isMultiSelect: true
        }
      },
      {
        filterLabel: 'Charger Status',
        filterId: 'status',
        filterType: 'dropdown',
        filterDropdownProps: {
          selectValue: getFilterSelectedValue('chargerStatus', 'alarms', false),
          selectOptions: chargerStatusReferenceData ?? [],
          handleSelectOptionChange: (val): void => {
            handleFilterDataChange(val, 'chargerStatus', false)
          },
          selectDropDownId: 'alarm-chargerStatus-filter-dropdown',
          isMultiSelect: true
        }
      },
      {
        filterLabel: 'Time (set at)',
        filterType: 'dateFilter',
        filterId: 'timeStampSetAt',
        dateFilterProps: {
          datePickerId: 'alarm__time__stamp__set__at__date__picker',
          datePickerClassName: '',
          selectsRange: true,
          startDate: filterAlarmSetTimeStampValue?.[0] ? new Date(filterAlarmSetTimeStampValue?.[0]) : null,
          endDate: filterAlarmSetTimeStampValue?.[1] ? new Date(filterAlarmSetTimeStampValue?.[1]) : null,
          onChange: (val): void => {
            handleFilterDataChange(val, 'alarmSetTimeStamp', false)
          },
        }
      },
      {
        filterLabel: 'Time (cleared at)',
        filterType: 'dateFilter',
        filterId: 'timeStampClearedAt',
        dateFilterProps: {
          datePickerId: 'alarm-time-stamp-cleared-at-date-picker',
          datePickerClassName: 'alarm__time__stamp__cleared__at__date__picker',
          selectsRange: true,
          startDate: filterAlarmClearedTimeStampValue?.[0] ? new Date(filterAlarmClearedTimeStampValue?.[0]) : null,
          endDate: filterAlarmClearedTimeStampValue?.[1] ? new Date(filterAlarmClearedTimeStampValue?.[1]) : null,
          onChange: (val): void => {
            handleFilterDataChange(val, 'alarmClearedTimeStamp', false)
          },
        }
      },

    ]
  }, [chargerTypeReferenceData, chargerVisibleIdReferenceData, alarmSeverityReferenceData, alarmTypeReferenceData, screenFilters, selectedNav,chargerStatusReferenceData])


  const alarmFilterProps: FilterProp[] = useMemo(() => {
    if (selectedNav != 'history') {
      return alarmFilters?.filter(item => item?.filterId?.toString() !== 'timeStampClearedAt')
    } else {
      return alarmFilters
    }
  }, [alarmFilters, screenFilters, selectedNav])


  // TODO: Redux state Clean up
  const alarmCleanUpStates = useCallback(() => {
    // Partial<AlarmsAction> 
    const action = {
      type: alarmTypes.CLEAR_ALARM_DATA,
    }
    dispatch(action);
    if (alarmFiltersAppliedFromRedirection) {
      onResetButtonClick(false)
    }
    setRedirectedStateForAlarmFilters(false)(dispatch)

  }, [alarmFiltersAppliedFromRedirection])

  useEffect(() => {
    window.addEventListener('beforeunload', alarmCleanUpStates);
    return (): void => {
      alarmCleanUpStates()
      window.removeEventListener('beforeunload', alarmCleanUpStates);
    }
  }, [])

  return (
    <>
    <div ref={dashboardRef} className="alarms__dashboard__container">
      <div ref={headerRef} className="alarms__dashboard__container-header">
        <DashboardHeader header="Alarms" />
        <div className="alarms__dashboard__container-header-right">
          <div className='alarms__dashboard__container-header-right-export'>
            <TableSubHeader headerActionBtns={headerActionBtns} />
            <Menu
              id="export-option-dropdown"
              anchorEl={exportAnchorEl}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              className='export__option__dropdown'
              sx={{ top: '30px' }}
              open={Boolean(exportAnchorEl)}
              onClose={handleClose}
            >
              <MenuItem>
                <div className='export__btn-option' onClick={(e) => { e?.stopPropagation(); onExportBtnClick('csv') }}>
                  To CSV
                </div>
              </MenuItem>
              <MenuItem>

                <div className='export__btn-option' onClick={(e) => {
                  e?.stopPropagation();
                  onExportBtnClick('xlsx')
                }}>
                  To Excel
                </div>
              </MenuItem>
            </Menu>
          </div>
          {selectedNav === 'active' && (
            Array.isArray(layoutsConfiguration) &&
            layoutsConfiguration?.length > 0 &&
            layoutsConfiguration?.map((layout, index) => {
              return (
                <IconWithTooltip
                  key={index}
                  toolTipText={layout.toolTipText}
                  onIconClick={layout.onIconClick}
                  iconClassName={
                    layoutView === layout.layout
                      ? layout.iconClassName + ' selected'
                      : layout.iconClassName
                  }
                />
              );
            }))}
        </div>
      </div>

      <div ref={filterHeaderRef} className="alarms__dashboard__container-filter-header">
        <SearchBox
          key={'search' + selectedNav}
          searchFieldId={searchBoxConfiguration?.searchFieldId ?? ''}
          searchFieldName={searchBoxConfiguration?.searchFieldName ?? ''}
          searchBoxClassName={searchBoxConfiguration?.searchBoxClassName ?? ''}
          handleSearch={searchBoxConfiguration?.handleSearch}
        />
        {/* <div className={'alarms__dashboard__container-header-filter-btn-wrap ' + selectedNav}>
          <FilterLayout requiredReferenceDataCallbacks={['chargerType', 'severity', 'alarmType', 'chargerVisibleId']}
            filters={alarmFilterProps} handleFilterDataChange={handleFilterDataChange} screen='alarms' />
        </div> */}
      </div>

      <div ref={subHeaderRef} className="alarms__dashboard__container-sub-header">
        <div className="alarms__dashboard__container-sub-header-left">
          <HorizontalNavbar
            selectedNav={selectedNav}
            onNavClick={(event, val) => { event?.stopPropagation(); handleNavBtnClick(val) }}
            navList={alarmNavList}
          />
        </div>
      </div>

      {/* {selectedNav === 'active' && ( */}
      <div ref={alarmCardsRef} key={'alarmCards' + selectedNav} className="alarms__dashboard__container-cards-wrap">
        {!alarmDashboardCount ?
          <AlarmCardTableSkeleton /> :
          alarmDashboardCount?.length > 0 &&
          alarmDashboardCount?.map((alarmType, index) => (
            <AlarmCards
              key={alarmType?.severity + index}
              alarmContainerClassName="alarms__dashboard__container-alarm-cards"
              cardId={`alarm-summary-${alarmType?.severity}`}
              alarmCount={alarmType?.alarmCount}
              headerText={alarmType?.severity}
              onCardClick={handleCardClick}
              selectedCard={selectedAlarmCard}

            />
          ))
        }
      </div>
      {/* )} */}

      <AlarmTable
        key={'data-' + selectedNav}
        contentHeight={tableHeight}
        containerClassName="alarms__dashboard__table__container-wrap"
        calledFrom={selectedNav}
        fetch_type={selectedNav}
        view={selectedNav === 'history' ? 'list' : layoutView}
        layoutCallback={layoutCallback}
        setSelectedAlarmCard={setSelectedAlarmCard}
        alarmFilterVal={selectedAlarmCard === 'TOTAL' ? 'all' : selectedAlarmCard === '' ? 'all' : selectedAlarmCard}
        pageNumber={selectedNav === 'history' ? alarmHistoryTableProps?.pageNumber : activeAlarmTableProps?.pageNumber}
        tablePageSize={selectedNav === 'history' ? alarmHistoryTableProps?.pageSize : activeAlarmTableProps?.pageSize}
        sortField={selectedNav === 'history' ? alarmHistoryTableProps?.sortBy : activeAlarmTableProps?.sortBy}
        sortOrder={selectedNav === 'history' ? alarmHistoryTableProps?.order : activeAlarmTableProps?.order}
        columnPrefrences={screenTableColumnPrefrences?.find((item) => item.screen === 'alarms')}
        handleColumnPrefrenceChange={handleColumnPrefrenceChange}
      />
    </div>
          { filtersVisible && <SidePopover
            id={'manage__alarms__side__filters'}
            open={filtersVisible}
            containerClassName='manage__alarms__side__filters'
            handleClose={handleFiltersClosePopOver}
            headerText={' Add Filters'}
            // eslint-disable-next-line react/no-children-prop
            children={ <FilterLayout requiredReferenceDataCallbacks={['chargerType', 'severity', 'alarmType', 'chargerVisibleId','status']}
            filters={ alarmFilterProps} handleFilterDataChange={handleFilterDataChange} />}
            btnsList={filterFooterBtns}
          />
          }
          </>

  );
}

export default AlarmsDashboard;
