// A reducer is a pure function that receives the state of the store and an action as parameters and then returns the updated state.

import { chargingStationTypes } from '../reduxExports'

// Declare an initial state to have some chargingStationData. The state object needs to match the type ChargingStationState - otherwise, TypeScript will throw an error.
const chargingStationInitialState: ChargingStationState = {
  chargingStations: null,
  chargingStationsCount: 0,
  loader: false,
  chargingStationTableProps: {
    view: 'list',
    pageSize: 15,
    pageNumber: 1,
    order: 'ascending',
    sortBy: 'chargingStationName',
    searchTerm: ''
  },
 singleStationDetail:null,
 historyRecords: null,
 historyRecordCount: 0,
 historyRecordTableProps: {
  view: 'list',
  sortBy: '',
  order: 'descending',
  pageSize: 15,
  pageNumber: 1,
  searchTerm: ''
},
}

// Reducer function that expects the previous state and an action to be able to update the store.
const chargingStationReducer = (
  state: ChargingStationState = chargingStationInitialState,
  action: ChargingStationAction
): ChargingStationState => {
  switch (action.type) {
    case chargingStationTypes.FETCH_CHARGING_STATION_DATA:
      return {
        ...state,
        chargingStations: action.chargingStations,
        chargingStationsCount: action.chargingStationsCount
      }
 
    case chargingStationTypes.SET_CHARGING_STATIONS_TABLE_PROPS:
      return {
        ...state,
        chargingStationTableProps: action.chargingStationTableProps
      }
      case chargingStationTypes.CLEAR_CHARGING_STATION_LIST_DATA:
        return {
          ...state,
          chargingStationTableProps: {
            view: 'list',
            pageSize: state.chargingStationTableProps?.pageSize || 15,
            pageNumber: state.chargingStationTableProps?.pageNumber || 1,
            order: state.chargingStationTableProps?.order || 'ascending',
            sortBy: state.chargingStationTableProps?.sortBy || '',
            searchTerm: ''
          },
        }
    case chargingStationTypes.FETCH_SINGLE_STATION_DETAIL:
      return{
        ...state,
        singleStationDetail:action.singleStationDetail
      }
      case chargingStationTypes.CLEAR_UPDATED_STATION_DETAIL_DATA:
      return{
        ...state,
        singleStationDetail:null
      }
      case chargingStationTypes.FETCH_STATION_UPDATE_HISTORY_DATA:
        return{
          ...state,
          historyRecords: action.historyRecords,
          historyRecordCount: action.historyRecordCount
        }
        case chargingStationTypes.SET_STATION_UPDATE_HISTORY_LIST_TABLE_PROPS:
          return {
            ...state,
            historyRecordTableProps: action.historyRecordTableProps
          }  
          case chargingStationTypes.CLEAR_STATION_HISTORY_LIST_DATA:
            return {
              ...state,
              historyRecords: null,
              historyRecordCount: 0,
              historyRecordTableProps: {
                view: 'list',
                sortBy: '',
                order: 'ascending',
                pageSize: state.historyRecordTableProps?.pageSize || 15,
                pageNumber: state.historyRecordTableProps?.pageNumber || 1,
                searchTerm: ''
              }
            }    
    default:
      return state
  }
}

export default chargingStationReducer
