// Action types for the Charging Station to use it in Redux store
export const FETCH_CHARGING_STATION_DATA = 'FETCH_CHARGING_STATION_DATA'
export const ADD_CHARGING_STATION_DATA = 'ADD_CHARGING_STATION_DATA'
export const SET_CHARGING_STATION_LOADER = 'SET_CHARGING_STATION_LOADER'
export const SET_CHARGING_STATIONS_TABLE_PROPS = 'SET_CHARGING_STATIONS_TABLE_PROPS'
export const FETCH_SINGLE_STATION_DETAIL='FETCH_SINGLE_STATION_DETAIL'
export const CLEAR_UPDATED_STATION_DETAIL_DATA = 'CLEAR_UPDATED_STATION_DETAIL_DATA'
export const CLEAR_CHARGING_STATION_LIST_DATA = 'CLEAR_CHARGING_STATION_LIST_DATA'
export const FETCH_STATION_UPDATE_HISTORY_DATA = 'FETCH_STATION_UPDATE_HISTORY_DATA'
export const SET_STATION_UPDATE_HISTORY_LIST_TABLE_PROPS='SET_STATION_UPDATE_HISTORY_LIST_TABLE_PROPS'
export const CLEAR_STATION_HISTORY_LIST_DATA = 'CLEAR_STATION_HISTORY_LIST_DATA'