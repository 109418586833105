import {
  AxisLabelsFormatterContextObject,
  Options, OptionsChartZoomingTypeValue
} from 'highcharts';
import moment from 'moment';
import React, { FC, useEffect, useMemo, useState } from 'react';
import { CustomHighCharts, useAppSelector } from '../../../../globalUtils/globalExports';
import { getFilterReducer, getFilterSelectedValue } from '../../../../rmsReduxStore/reduxExports';
import { generateDataPoints } from '../../../../globalUtils/globalHooks';

interface IChargerStatusGraph {
  timeSeriesConnectorData: IHistoryGraphConnectorData[];
  startDateTime: string,
  endDateTime: string
}

export interface IHistoryGraphConnectorData {
  connectorName: string,
  connectorColor: string,
  parameterName: string
  unit: string
  data: [number, number][]
}

const ChargerStatusGraph: FC<IChargerStatusGraph> = ({ timeSeriesConnectorData, startDateTime, endDateTime }) => {
  const [graphTickPositions, setGraphTickPositions] = useState({ min: 0, mid: 0, max: 0 });
  const [graphTickPositionsY1, setGraphTickPositionsY1] = useState({ min: 0, mid: 0, max: 0 });
  const [categories, setCategories] = useState<string[]>([]);
  const { screenFilters } = useAppSelector(getFilterReducer);

  // TODO: Calculate tick positions for graph
  useEffect(() => {
    let maxValue = -Infinity;
    let minValue = Infinity;
    let maxValueY1 = -Infinity;
    let minValueY1 = Infinity;
    // const categories: string[] = []
    if (timeSeriesConnectorData && timeSeriesConnectorData?.length > 0) {
      timeSeriesConnectorData?.forEach(item => {
        if (item?.parameterName === 'Alarm' || item?.parameterName === 'Gun Temp Derating flag') {
          if (item && item?.data?.length > 0) {
            item?.data?.forEach(dataPoint => {
              const value = dataPoint[1];
              if (value !== null) {
                if (value > maxValueY1) {
                  maxValueY1 = value;
                }
                if (value < minValueY1) {
                  minValueY1 = value;
                }
              }
            })
          }
        }
        else {
          if (item && item?.data?.length > 0) {
            item?.data?.forEach(dataPoint => {
              const value = dataPoint[1];
              if (value !== null) {
                if (value > maxValue) {
                  maxValue = value;
                }
                if (value < minValue) {
                  minValue = value;
                }
              }
            })
          }
        }
      })
    }
    setGraphTickPositionsY1({ min: minValueY1 === 0 ? Math.round(minValueY1) : Math.round(minValueY1) - 1, mid: isNaN(minValueY1) && isNaN(maxValueY1) ? 0 : Math.round((minValueY1 + maxValueY1) / 2), max: Math.round(maxValueY1) + 1 })
    setGraphTickPositions({ min: minValue === 0 ? Math.round(minValue) : Math.round(minValue) - 1, mid: isNaN(minValue) && isNaN(maxValue) ? 0 : Math.round((minValue + maxValue) / 2), max: Math.round(maxValue) + 1 })

  }, [timeSeriesConnectorData,])

  useEffect(() => {
    const selectedHistoryInterval = getFilterSelectedValue('historyInterval', 'chargerPanel', false)?.[0] as IChargerHistoryIntervalRefData
    if (selectedHistoryInterval) {
      const datapoints = generateDataPoints(startDateTime, endDateTime, selectedHistoryInterval?.id);
      if (datapoints && datapoints?.length > 0) {
        setCategories(datapoints)
      }
    }

  }, [screenFilters, startDateTime, endDateTime])

  const chartOptions: Options = useMemo(() => {
    return {
      time: {
        useUTC: false,
        timezone: 'Asia/Calcutta'
      },
      chart: {
        type: 'line',
        height: timeSeriesConnectorData?.length > 10 ? 400 : 300,
        // zoomType:'x',
        zooming: {
          key: 'ctrl',
          type: 'xy' as OptionsChartZoomingTypeValue
        },
        resetZoomButton: {
          position: {
            verticalAlign: 'bottom',
            y: -50
          },
        },
      },
      title: {
        text: '',
      },
      legend: {
        enabled: true,
        symbolRadius: 0,
        symbolWidth: 0,
        useHTML: true,
        labelFormatter: function (): string {
          const name = this.name;
          const color = this.color
          return `<span style="display:inline-flex;align-items:center; justify-content:center"><span class="connector__type connector__type-legend" style="background-color:${color}"></span> <span>${name}</span></span>`;
        }
      },
      tooltip: {
        enabled: true,
        useHTML: true,
        outside: true,
        style: {
          zIndex: 10
        },
        formatter: function (): string {
          const date = this.key ? moment(new Date(this.key)).format('llll') : '-';
          return (
            '<div style=\'font-size:12px;\' class="graph__tooltip__header">' +
            this.series.name + `
            </div>
            <div>
              <div style='font-size:10px;' class="graph__tooltip__total___content">` +
            date + `
              </div>
              <div style='font-size:10px;' class="graph__tooltip__total___content">` +
            this.y?.toFixed(this.series.userOptions?.description ? 3 : 0) + (this.series.userOptions?.description ? this.series.userOptions?.description : '') + `
              </div>
            </div>`
          );
        }
      },
      credits: {
        enabled: false,
      },
      xAxis: {
        type: 'datetime',
        title: {
          text: '',
        },
        startOnTick: true,
        endOnTick: true,
        // categories: categories,
        // tickInterval: categories?.length,
        labels: {
          style: {
            fontSize: '10px',
            fontFamily: 'var(--font-family-regular)',
            color: 'var(--primary-text-color)'

          },
          useHTML: true,

          autoRotation: [90],
          autoRotationLimit: 20,
          formatter: function (this: AxisLabelsFormatterContextObject): string {
            const timestamp = this.value as number;
            const date = new Date(timestamp);
            const formattedDate = `${moment(date).format('MMM Do')}`;
            const formattedTime = ` ${moment(date).format('LT')}`;

            return formattedDate + '<br/>' + formattedTime;
          },
        },
      },
      yAxis: [{
        title: {
          text: '',
        },
        allowDecimals: true,
        labels: {
          style: {
            fontSize: '10px',
            fontFamily: 'var(--font-family-regular)',
            color: 'var(--primary-text-color)'

          },
        },
        tickPositions: [graphTickPositions?.min, graphTickPositions?.mid, graphTickPositions?.max],
      },
      {
        title: {
          text: '',
        },
        opposite: true,
        allowDecimals: false,
        labels: {
          style: {
            fontSize: '10px',
            fontFamily: 'var(--font-family-regular)',
            color: 'var(--primary-text-color)'

          },
        },
        tickPositions: [graphTickPositionsY1?.min, graphTickPositionsY1?.mid, graphTickPositionsY1?.max],
      }],
      plotOptions: {
        line: {
          marker: {
            enabled: true,
            symbol: 'circle',
            radius: 2,
          },
          lineWidth: 1,
        }
      },
      series: timeSeriesConnectorData?.map((item) => {
        return {
          name: item?.parameterName + '-' + item?.connectorName,
          turboThreshold: 6000,
          data: item?.data,
          type: 'line',
          color: item?.connectorColor?.toLocaleLowerCase(),
          description: item?.unit, //passing Unit in description
          yAxis: (item?.parameterName === 'Alarm' || item?.parameterName === 'Gun Temp Derating flag') ? 1 : 0

        }
      })

    };
  }, [timeSeriesConnectorData, startDateTime, graphTickPositions, categories]);

  return (
    <div className='charger__status__history__graph__wrap'>
      <CustomHighCharts container='charger__status__history__graph__wrap' highChartOptions={chartOptions} />
    </div>
  );
};

export default ChargerStatusGraph;
