import React, { FC } from 'react';
import { ChargerStatusSkeleton } from '../../../../globalUtils/SkeletonDesign/SkeletonDesign';
import ChargerIcon from '../../../../rmsAssets/charger.svg';
import { getChargerReducer } from '../../../../rmsReduxStore/reduxExports';
import { useAppSelector } from '../../../../rmsReduxStore/rmsReduxHooks';
import { Icon } from '../../../../globalUtils/globalIcons';
import { CustomTooltipWithLabel } from '../../../../globalUtils/globalExports';

interface IConnectorStatus {
  status: string
  connectorType: string
  connectorNumber: string
  connectorColor: string
  content: IChargerPanelContent[]
  index: number
  chargerNetwork: string,
  connectorTypeIconClass:string
}

interface IChargerPanelContent {
  visibleName: string
  value: string | number
  unit: string

}

interface IChargerPanelStatusParametersTable {
  status: string
  content: IChargerPanelContent[]
  tableColor: string
}

// TODO: Connector status with charger
export default function ChargerStatusConnectorDetails(): JSX.Element {
  const connectorColorsList = ['Idle', 'bluecolor','Charging']
  const { chargerConnectorStatus, chargerNetwork } = useAppSelector(getChargerReducer);
  const connectorIconMap = {
    'DC CCS':     'dc__ccs__connector__icon',
    'DC GBT':     'dc__gbt__connector__icon',
    'DC Chademo': 'dc__chademo__connector__icon',
    'AC Type 2':  'ac__type2__connector__icon'
  }
  
  return (
    <div className='charger__status__details__wrap__container'>
      {chargerConnectorStatus && chargerNetwork &&  chargerConnectorStatus?.length > 0? <>
          {chargerConnectorStatus?.map((chargerConnectorStatusItem, index) => {
              return (
                <>
                  <ConnectorStatus 
                  index={index} 
                  chargerNetwork={chargerNetwork} 
                  connectorColor={connectorColorsList[index]} 
                  status={chargerConnectorStatusItem?.status} 
                  connectorNumber={chargerConnectorStatusItem?.connectorName} 
                  connectorType={chargerConnectorStatusItem?.connectorType} 
                  connectorTypeIconClass = {connectorIconMap[chargerConnectorStatusItem?.connectorType]} 
                  content={chargerConnectorStatusItem?.parameters}/>
                </>
              )
            })
          }
      </> : <ChargerStatusSkeleton />}
    </div>
  )
}

// TODO: Individual Connector block
const ConnectorStatus: FC<IConnectorStatus> = (props) => {
  const { status, connectorType, connectorNumber, connectorColor, content, index, chargerNetwork,connectorTypeIconClass } = props
  return (
    <div className='charger__status__details__wrap '>
      <div className={index === 0 ? 'charger__status__details-top-first' : 'charger__status__details-top'}>
        <div className='charger__status__connector__wrap'>
          <div className={'charger__status__connector__wrap-top ' + connectorColor?.toLocaleLowerCase()}>
            <p className='text'> {status ? status : '-'}
            </p>
          </div>
          <div className={'charger__status__connector__wrap-bottom ' + connectorColor?.toLocaleLowerCase()}>
            <div className='charger__status__connector__wrap-bottom-right'>
              <p className='text'> {connectorType === 'DC Chademo' ? 'DC CHAdeMO' : connectorType}</p>
            </div>
            <div className='charger__status__connector__wrap-bottom-center'>
            <Icon iconClassName= {connectorTypeIconClass} />
            </div>
            <div className='charger__status__connector__wrap-bottom-left'>
              <p className='text'> {connectorNumber}</p>
            </div>
          </div>
        </div>
          {index === 0 && <div className='charger__status__charger__wrap'>
              <div className='charger__status__charger__connectivity'>
                {chargerNetwork}
              </div>
              <div className='charger__status__charger__icon__wrap'>
                <img alt={'charger__icon'} className='charger__icon' src={ChargerIcon} />
              </div>
            </div>
            }
      </div>
      <div className='charger__status__details-bottom'>
        <ChargerPanelStatusParametersTable tableColor={connectorColor} status={status} content={content} />
      </div>
    </div>
  )
}

// TODO: Connector Parameters Table
const ChargerPanelStatusParametersTable: FC<IChargerPanelStatusParametersTable> = (props) => {
  const { content, tableColor } = props
  return (
    <div className={'charger__panel__status__table__wrap ' + tableColor?.toLocaleLowerCase()}>
      {content && content?.map((row, index) => {

        return (
          <div key={index} className='charger__panel__status__table__row'>
            <p className='charger__panel__status__table__header'>
              {row?.visibleName}
            </p>
            <p className='charger__panel__status__table__value'>
              <CustomTooltipWithLabel label={(row?.value || row?.value === 0 ? row?.value.toString() : '-') + (((row.value || row?.value === 0)&& row?.unit && row?.value !== 'NA' && row?.value !== 'Disconnected') ? row?.unit : '')} labelId={'labelId'+ index} />
            </p>
          </div>
        )
      })}
    </div>
  )
}

