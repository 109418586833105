import { type GridRenderCellParams, GridValidRowModel } from '@mui/x-data-grid-pro';
import { cloneDeep} from 'lodash';
import React, { ReactNode, memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import {  useNavigate } from 'react-router-dom';
import {
  CustomStatus,
  downloadFile,
  renderHeaderActionButtons,
  TableDashboard,
  useAppDispatch,
  useAppSelector,
  CustomDialog,
  FilterProp,
  TooltipTheme,
  CustomTooltipWithLabel,

} from '../../globalUtils/globalExports';

import {
  setChargerParametersListTableProps,
  exportToCsvForParametersList,
  deleteParameter,
} from '../../rmsReduxStore/chargersRedux/chargerCreators';
import {
  getChargerReducer,
  fetchParametersData,
  getFilterSelectedValue,
  setFilterData,
  getReferenceDataReducer,
  getFilterReducer,
  getCurrentFiltersValues,
  getGlobalReducer,
  setTableColumnPrefrences,
  chargerTypes
} from '../../rmsReduxStore/reduxExports';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import './chargers.css';
import { Id, toast } from 'react-toastify';
import { ActionIcons} from '../../globalUtils/globalIcons';
import { NoDataComponentParameters } from '../../globalUtils/TableDashboard/TableComponents';
import {  Tooltip } from '@mui/material';
import { ThemeProvider } from '@emotion/react';
import { ElectricalServicesSharp } from '@mui/icons-material';
import {formatDateInDateTimeFormatNotSeconds, formatRelativeTimeForTable } from '../../globalUtils/globalHooks';
import access from '../../auth/service/AccessControl';





const SetParametersDashboard = memo(function SetParametersDashboard() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const layouts = ['list', 'map'];
  const { chargerParameters, chargerParametersCount, chargerParametersTableLoader, chargerParametersListTableProps } = useAppSelector(getChargerReducer);
  const [exportAnchorEl, setExportAnchorEl] = useState(null);
  const [exportingFile, setExportingFile] = useState(false);
  const chargerParametersListTablePropsRef = useRef(chargerParametersListTableProps);
  const parametersRef = useRef(chargerParameters);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [selectedParameterId, setSelectedParameterId] = useState<string | null>(null);
  const { parametersReferenceData, userReferenceData } = useAppSelector(getReferenceDataReducer)
  const { screenFilters } = useAppSelector(getFilterReducer)
  const {screenTableColumnPrefrences} = useAppSelector(getGlobalReducer)


  useEffect(() => {
    chargerParametersListTablePropsRef.current = chargerParametersListTableProps;
  }, [chargerParametersListTableProps]);

  useEffect(() => {
    parametersRef.current = chargerParameters;
  }, [chargerParameters]);

  // TODO: Handles global search box
  // In config - pass id, classname and onChange
  // In onChange - just add a callback rest is handled in search box component

  const onChangeOfSearchText = useCallback(async (searchTerm) => {
    if (chargerParametersListTablePropsRef?.current) {
      await layoutCallback(1, chargerParametersListTablePropsRef?.current?.pageSize, 'list', chargerParametersListTablePropsRef?.current?.sortBy, chargerParametersListTablePropsRef?.current?.order, null, searchTerm)
    }
  }, [chargerParameters, chargerParametersListTablePropsRef?.current, parametersRef?.current]);

  const searchBoxConfiguration = useMemo(() => {
    return {
      searchFieldId: 'manage-charger-parameters-search-box',
      searchFieldName: 'manage-charger-parameters-search-box',
      handleSearch: onChangeOfSearchText,
    };
  }, [onChangeOfSearchText]);

  // TODO: Status Icon display
  const getStatusIconClass = {
    'InProgress': 'status__in-progress__parameter__icon',
    'Scheduled': 'status__scheduled__parameter__icon',
    'Completed': 'status__completed__parameter__icon'
  }

  // TODO: Adds action btns in header and their callbacks
  const headerActionBtns = renderHeaderActionButtons({
    actionBtns:
      [
        {
          id: 'set_param_btn',
          isAccessible: access?.updateSetting?.add(),
          headerActionBtnClick: (): void => {
            navigate('/add-parameter')
          }
        },
        {

          id: 'export_csv_btn',
          isAccessible: access?.updateSetting?.export(),
          headerActionBtnClick: (event): void => {
            setExportAnchorEl(event?.currentTarget);

          }
        }
      ]
  })



  // to handle edt-icon

  const onEditIconClick = (event, rowData) => {
    navigate('/edit-parameter',{ state: { parameterData: rowData, } });
  };



  const parameterDeletionSuccessToast = useCallback((): Id => {
    return toast.success('Parameter deleted!');
  }, []);

  const parameterDeletionFailureToast = useCallback((): Id => {
    return toast.warn('Something went wrong. Please try again.');
  }, []);
  const onDeleteIconClick = useCallback(async (id: string): Promise<void> => {
    setSelectedParameterId(id);
    setIsDeleteDialogOpen(true);

  }, []);
  const handleDeleteDialogClose = useCallback(() => {
    setIsDeleteDialogOpen(false);
    setSelectedParameterId(null);
  }, []);

  const handleDeleteParameter = useCallback(async () => {
    if (selectedParameterId) {
      const { data, status } = await dispatch(deleteParameter({
        id: selectedParameterId

      }));
      if ((status === 200 || status === 202) && data?.message === 'Success') {
        parameterDeletionSuccessToast();
        layoutCallback(1, chargerParametersListTablePropsRef?.current?.pageSize, chargerParametersListTablePropsRef?.current?.view, chargerParametersListTablePropsRef?.current?.sortBy, chargerParametersListTablePropsRef?.current?.order, null, chargerParametersListTablePropsRef?.current?.searchTerm)
      }
      handleDeleteDialogClose();
    } else {
      parameterDeletionFailureToast()
    }
  }, [selectedParameterId, chargerParametersListTablePropsRef?.current]);

  const actionIconsList = useMemo(() => {
    return [{ icon: 'pencilIcon',isAccessible: access?.updateSetting?.edit(), action: onEditIconClick }, 
    { icon: 'deleteIcon',isAccessible: access?.updateSetting?.delete(), action: (_, rowData) => onDeleteIconClick(rowData?.id) }];
  }, [onDeleteIconClick]);


  // TODO: Table Layout Callbacks
  const mobileViewConfiguration = useMemo(() => {
    return {
      headerDataConfig: {
        headerLeftDataConfig: ['parameterName','org','value'],
        headerRightDataConfig: {
          actionIconsComponent: true,
          statusIconComponent: true,
        },
      },
      statusIconKey: 'status',
      statusIconMapper: getStatusIconClass,
      contentDataConfig: [
        [
          {
            key: 'createdBy',
            iconClassName: 'parameters_createdBy_icon',
          },
          {
            key: 'createdOn',
            iconClassName: 'parameters_createdOn_icon',
          },
        ],
        [
          {
            key: 'devicesCount',
            iconClassName: 'parameters_deviceCount_icon',
          },
        ],
      ],
    };
  }, []);

  const getTableRows = useCallback((): ChargerParameters[] => {
    return chargerParameters !== null && chargerParameters?.length > 0 ? chargerParameters : [];
  }, [chargerParameters]);

  const handleNameClick = async (event, rowData) => {
    navigate('/view-parameter', { state: { parameterData: rowData, } });
  };

  const parametersColumns = useMemo(() => {

    return [

      {
        field: 'parameterName',
        headerName: 'Parameter',
        // flex: 1,
        maxWidth: 500,
        sortable: true,
        minWidth: 200,
        hideable:false,
        renderCell: (params: GridRenderCellParams<GridValidRowModel>): JSX.Element => {
          const rowData = params?.row;
          return (

            <div
              className="individual__user__text_set_parameter"
              onClick={(e) => {
                e?.stopPropagation()
                handleNameClick(e, params?.row);
              }
              }
            >
              {rowData?.parameterName}
            </div>
          );
        },
      },
      {
        field: 'value',
        headerName: 'Set Value',
        flex: .6,
        sortable: true,
        minWidth: 80,
        renderCell: (params: GridRenderCellParams<GridValidRowModel, Date>): JSX.Element => {
          const rowData = params?.row;
          let value = rowData?.value
          const labelId = rowData?.id
          if (rowData?.parameterType == 'Date') {
            value = rowData?.value ? formatDateInDateTimeFormatNotSeconds(rowData?.value) : ''
          }
          else{
            value=rowData?.unit && rowData?.value ? rowData?.value + rowData?.unit : rowData?.value
          }
          return (
            <>
               <CustomTooltipWithLabel label={value} labelId={labelId} />
            </>
          )
        },
      },
      {
        field: 'createdBy',
        headerName: 'Set By',
        flex: .6,
        sortable: true,
        minWidth: 80,
      },
    
      {
        field: 'createdOn',
        flex: .6,
        headerName: 'Requested On',
        minWidth: 150,
        renderCell: (
          params: GridRenderCellParams<GridValidRowModel>
        ): ReactNode => {
          const rowData = params?.row;
          const date = new Date(rowData?.createdOn);
          return formatRelativeTimeForTable(date);
        },
      },
      {
        field: 'org',
        headerName: 'Organisation',
        flex: .6,
        sortable: true,
        minWidth: 120,
      },
      {
        field: 'customer',
        headerName: 'Customer(s)',
        flex: .6,
        sortable: false,
        minWidth: 120,
 
        renderCell: (params) => {
          const customers = params?.row?.customers || [];
          const firstCharger = customers[0]?.name || '';
          const additionalCustomersCount = customers?.length - 1;
          const truncatedFirstCustomer = firstCharger?.length > 15 ? `${firstCharger?.substring(0, 15)}...` : firstCharger;
          const visibleLabel = `${truncatedFirstCustomer}${additionalCustomersCount > 0 ? ` +${additionalCustomersCount}` : ''}`;
          return (
            <>
              {
             (<span>{visibleLabel}</span>)
              }
            </>
          );
        },
      },
      {
        field: 'devices',
        headerName: 'Charger(s)',
        flex: 0.5,
        sortable: false,
        minWidth: 160,
        renderCell: (params) => {
          const devices = params?.row?.devices || [];
          const firstCharger = devices[0]?.chargerVisibleId || '';
          const additionalChargersCount = devices?.length - 1;
          const truncatedFirstCharger = firstCharger?.length > 15 ? `${firstCharger?.substring(0, 15)}...` : firstCharger;
          const visibleLabel = `${truncatedFirstCharger}${additionalChargersCount > 0 ? ` +${additionalChargersCount}` : ''}`;
          return (
            <>
              {
            (<span>{visibleLabel}</span>)
              }
            </>
          );
        },
      },
      {
        field: 'status',
        headerName: 'Execution Status',
        minWidth: 110,
        flex: 0.6,
        editable: false,
        renderCell: (params: GridRenderCellParams<GridValidRowModel, Date>): JSX.Element => {
          const rowData = params?.row;
          if (rowData?.status === 'Scheduled') {
           
            const tooltipContent =  rowData?.triggerTime ? formatDateInDateTimeFormatNotSeconds(rowData?.triggerTime) : ''
            return (
              <>
                <ThemeProvider theme={TooltipTheme}>
                  <Tooltip
                    title={tooltipContent}
                    enterDelay={300}
                    leaveDelay={200}
                  >
                    <span>
                      <CustomStatus statusText={params?.row?.status} statusIconClassName={getStatusIconClass[params?.row?.status] || 'rms__icon status__completed__parameter__icon'} />

                    </span>
                  </Tooltip>

                </ThemeProvider>
              </>
            );
          }
          ElectricalServicesSharp
          return (
            <CustomStatus statusText={params?.row?.status} statusIconClassName={getStatusIconClass[params?.row?.status] || 'rms__icon status__completed__parameter__icon'} />
          )
        },
      },
      {
        field: 'View',
        headerName: 'Actions',
        flex: 1,
        sortable: false,
        minWidth: 90,
        maxWidth: 100,
        headerAlign: 'center',
        renderCell: (params: GridRenderCellParams<GridValidRowModel, Date>): JSX.Element => {
          const rowData = params?.row;
           if (rowData?.status === 'Scheduled') {
            return (
              <div className="charging__station__action__icons__wrap">
                <ActionIcons actionIconsList={actionIconsList} data={rowData} />
              </div>
            );
           }
           else return (<div></div>)
        },
      },
    ];
  }, []);

  const handleFilterDataChange = useCallback(async (val, filterKey, isGlobal) => {
    const res = await setFilterData(val, filterKey, 'parameters', 'SET', isGlobal ? isGlobal : false)(dispatch)
    if (res?.message === 'Action dispatched successfully') {
      if (chargerParametersListTablePropsRef?.current) {
        await layoutCallback(1, chargerParametersListTablePropsRef?.current?.pageSize, chargerParametersListTablePropsRef?.current?.view, chargerParametersListTablePropsRef?.current?.sortBy, chargerParametersListTablePropsRef?.current?.order, res?.filters, chargerParametersListTablePropsRef?.current?.searchTerm)
      }
    }
  }, [chargerParametersListTablePropsRef?.current, dispatch, parametersRef?.current])

  const parameterFilterProps: FilterProp[] = useMemo(() => {
    const filterSetOnValue = getFilterSelectedValue('setInterval', 'parameters', false);

    return [
      {
        filterLabel: 'Parameter Name',
        filterType: 'dropdown',
        filterId: 'param',
        filterDropdownProps: {
          selectValue: getFilterSelectedValue('param', 'parameters', false),
          selectOptions: parametersReferenceData ?? [],
          handleSelectOptionChange: (val): void => {
            handleFilterDataChange(val, 'param', false)

          },
          selectDropDownId: 'charger-location-filter-dropdown',
          isMultiSelect: true
        }
      },

      {
        filterLabel: 'Set By',
        filterType: 'dropdown',
        filterId: 'createdBy',
        filterDropdownProps: {
          selectValue: getFilterSelectedValue('user', 'parameters', false),
          selectOptions: userReferenceData ?? [],
          handleSelectOptionChange: (val): void => {
            handleFilterDataChange(val, 'user', false)
          },
          selectDropDownId: 'charger-stats-filter-dropdown',
          isMultiSelect: true
        }
      },
      {
        filterLabel: 'Requested On',
        filterType: 'dateFilter',
        filterId: 'createdOn',
        dateFilterProps: {
          datePickerId: 'alarm__time__stamp__set__at__date__picker',
          datePickerClassName: 'alarm__time__stamp__cleared__at__date__picker',
          selectsRange: true,
          startDate: filterSetOnValue?.[0] ? new Date(filterSetOnValue?.[0]) : null,
          endDate: filterSetOnValue?.[1] ? new Date(filterSetOnValue?.[1]) : null,
          onChange: (val): void => {
            handleFilterDataChange(val, 'setInterval', false)
          },
        }
      },

    ]
  }, [screenFilters,userReferenceData,parametersReferenceData])

  const layoutCallback = useCallback(async (pageNumber: number, pageSize: number, view: string, sortField: string, sortOrder: string, filterData, searchTerm) => {
    const tableProps: IChargerListTableProps = cloneDeep(chargerParametersListTablePropsRef?.current)

    await dispatch(fetchParametersData({
      sortBy: sortField || chargerParametersListTablePropsRef?.current?.sortBy,
      order: sortOrder || chargerParametersListTablePropsRef?.current?.order,
      pageSize: pageSize || 15,
      pageNumber,
      filters: filterData ? filterData : getCurrentFiltersValues('parameters'),
      searchTerm: searchTerm != null && searchTerm != undefined ? searchTerm : chargerParametersListTablePropsRef?.current?.searchTerm
    }));

    if (tableProps) {
      tableProps.view = view ?? 'list'
      tableProps.sortBy = sortField || chargerParametersListTablePropsRef?.current?.sortBy;
      tableProps.order = sortOrder || chargerParametersListTablePropsRef?.current?.order;
      tableProps.pageNumber = pageNumber;
      tableProps.pageSize = pageSize || 15;
      if (searchTerm != null && searchTerm != undefined) {
        tableProps.searchTerm = searchTerm;
      }
    }
    await dispatch(setChargerParametersListTableProps(tableProps))
  }, [chargerParametersListTablePropsRef?.current, dispatch]);

    // Handler For Column Prefrence Chnages
    const handleColumnPrefrenceChange = useCallback(async (operation,data) => {
      await dispatch(setTableColumnPrefrences('parameters',operation,data))
   },[])

  // Redux state Clean up
  const parametersListCleanUpState = useCallback(() => {
    const action = {
      type: chargerTypes.CLEAR_CHARGER_PARAMETER_LIST_DATA,
    }
    dispatch(action);
  }, [])
  useEffect(() => {
    window.addEventListener('beforeunload', parametersListCleanUpState);
    return (): void => {
      parametersListCleanUpState()
      window.removeEventListener('beforeunload', parametersListCleanUpState);
    }
  }, [])
  // TODO: Export Handlers
  const handleClose = (): void => {
    setExportAnchorEl(null);
  };

  const fileDownloadSuccessToast = useCallback((): Id => {
    setExportingFile(false);
    return toast.success('File downloaded.');
  }, []);

  const fileDownloadFailureToast = useCallback((): Id => {
    setExportingFile(false);
    return toast.warn('Something went wrong. Please export again.');
  }, []);


  const onExportBtnClick = useCallback(async (fileType: 'csv' | 'xlsx'): Promise<void> => {
    if (exportingFile) {
      // If already exporting, prevent another export
      return;
    }
    try {
      const response = await dispatch(exportToCsvForParametersList({
        fileType: fileType,
        filters: getCurrentFiltersValues('parameters')
      }));
      if (response?.status === 202 || response?.status === 200) {
        downloadFile({ url: response?.data?.url });
        fileDownloadSuccessToast()
      } else {
        fileDownloadFailureToast();
        setExportAnchorEl(null);
      }
    } finally {
      // Ensure that exporting status is updated even if there's an error
      setExportingFile(false);
      setExportAnchorEl(null);

    }
  }, [exportingFile, fileDownloadSuccessToast, fileDownloadFailureToast])

  // TODO : Render Header Text
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  return (
    <>
      <TableDashboard
        tableId="manage-parameter-table"
        tableClassName="manage__parameter__table"
        header={'Update Settings'}
        searchBoxIncluded={true}
        searchBoxConfiguration={searchBoxConfiguration}
        gridColumns={parametersColumns}
        tableRows={getTableRows()}
        mobileViewConfiguration={mobileViewConfiguration}
        layouts={layouts}
        layoutView={'list'}
        headerActionBtns={headerActionBtns}
        listLayoutCallBack={layoutCallback}
        totalCount={chargerParametersCount}
        showSkeleton={!chargerParameters}
        totalCountText={'parameters'}
        showLoader={chargerParametersTableLoader}
        filterProps={parameterFilterProps}
        customNoDataComponent={NoDataComponentParameters}
        pageNumber={chargerParametersListTableProps?.pageNumber}
        tablePageSize={chargerParametersListTableProps?.pageSize}
        sortField={chargerParametersListTableProps?.sortBy}
        sortOrder={chargerParametersListTableProps?.order}
        handleFilterDataChange={handleFilterDataChange}
        referenceDataCallbacksFilters={['parameterName', 'user']}
        columnPrefrences={screenTableColumnPrefrences?.find((item) => item.screen === 'parameters')}
        handleColumnPrefrenceChange={handleColumnPrefrenceChange}
      />

      <Menu
        id="export-option-dropdown"
        anchorEl={exportAnchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        className='export__option__dropdown'
        sx={{ top: '30px' }}
        open={Boolean(exportAnchorEl)}
        onClose={handleClose}
      >
        <MenuItem>
          <div className='export__btn-option' onClick={(e) => {
            e?.stopPropagation();
            onExportBtnClick('csv')
          }}>
            To CSV
          </div>
        </MenuItem>
        <MenuItem>

          <div className='export__btn-option' onClick={(e) => {
            e?.stopPropagation();
            onExportBtnClick('xlsx')
          }}>
            To Excel
          </div>
        </MenuItem>
      </Menu>
      <CustomDialog
        dialogConfig={{
          dialogDescription: 'Are you sure you want to delete this parameter?',
          dialogCancelTitle: 'Cancel',
          dialogOkTitle: 'Delete',
        }}
        show={isDeleteDialogOpen}
        handleClose={handleDeleteDialogClose}
        handleSubmit={handleDeleteParameter}
      />
    </>
  );
})

export default SetParametersDashboard;
