import { type GridRenderCellParams, GridValidRowModel } from '@mui/x-data-grid-pro';
import { cloneDeep } from 'lodash';
import React, { ReactNode, memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  CustomDialog,
  CustomTooltipWithLabel,
  downloadFile,
  renderHeaderActionButtons,
  TableDashboard,
  useAppDispatch,
  useAppSelector,
} from '../../globalUtils/globalExports';
import {
  customerTypes,
  deleteCustomer,
  exportToCsvForCustomerList,
  fetchCustomersData,
  getCurrentFiltersValues,
  getCustomerReducer,
  getGlobalReducer,
  setCustomerTableProps,
  setFilterData,
  setTableColumnPrefrences,
} from '../../rmsReduxStore/reduxExports';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import './customerManagementDashboard.css';
import { Id, toast } from 'react-toastify';
import { ActionIcons } from '../../globalUtils/globalIcons';
import { NoDataComponentCustomers } from '../../globalUtils/TableDashboard/TableComponents';
import { formatNumberWithCommas } from '../../globalUtils/globalHooks';
import access from '../../auth/service/AccessControl';
import { setRedirectedStateForChargerFilters } from '../../rmsReduxStore/chargersRedux/chargerCreators';



const CustomerManagementDashboard = memo(function CustomerManagementDashboard() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  // const location = useLocation();
  const layouts = ['list', 'map'];
  const { customers, customerCount, customerListTableProps, loader } = useAppSelector(getCustomerReducer);
  const { screenTableColumnPrefrences } = useAppSelector(getGlobalReducer)
  const [exportAnchorEl, setExportAnchorEl] = useState(null);
  const [exportingFile, setExportingFile] = useState(false);
  const customerListTablePropsRef = useRef(customerListTableProps);
  const customersRef = useRef(customers);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [selectedCustomerId, setSelectedCustomerId] = useState<string | null>(null);

  // ********************************************
  // TODO: Callback wasn't taking the updated value
  useEffect(() => {
    customerListTablePropsRef.current = customerListTableProps;
  }, [customerListTableProps]);

  useEffect(() => {
    customersRef.current = customers;
  }, [customers]);

  const onDeleteIconClick = useCallback(async (id: string): Promise<void> => {
    setSelectedCustomerId(id);
    setIsDeleteDialogOpen(true);
  }, []);

  const handleDeleteDialogClose = useCallback(() => {
    setIsDeleteDialogOpen(false);
    setSelectedCustomerId(null);
  }, []);

  const userDeletionSuccessToast = useCallback((): Id => {
    return toast.success('Customer deleted.');
  }, []);

  const userDeletionFailureToast = useCallback((message: string): Id => {
    return toast.warn(message ?? 'Something went wrong.');
  }, []);

  const handleDeleteUser = useCallback(async () => {
    const { data, status } =
      await dispatch(deleteCustomer({
        customerId: selectedCustomerId
      }));
    if ((status === 200 || status === 202) && data?.message === 'Success') {
      userDeletionSuccessToast();
      layoutCallback(1, customerListTablePropsRef?.current?.pageSize, customerListTablePropsRef?.current?.view, customerListTablePropsRef?.current?.sortBy, customerListTablePropsRef?.current?.order, null, '');
    } else {
      userDeletionFailureToast(data?.message)
    }
    handleDeleteDialogClose();
  }, [dispatch, selectedCustomerId, handleDeleteDialogClose, customerListTablePropsRef?.current]);

  // TODO: Handles global search box
  // In config - pass id, classname and onChange
  // In onChange - just add a callback rest is handled in search box component
  const onChangeOfSearchText = useCallback(async (val) => {
    if (customerListTablePropsRef?.current) {
      await layoutCallback(1, customerListTablePropsRef?.current?.pageSize, customerListTablePropsRef?.current?.view, customerListTablePropsRef?.current?.sortBy, customerListTablePropsRef?.current?.order, null, val)
    }
  }, [customerListTablePropsRef?.current]);

  const searchBoxConfiguration = useMemo(() => {
    return {
      searchFieldId: 'manage-chargers-search-box',
      searchFieldName: 'manage-chargers-search-box',
      handleSearch: onChangeOfSearchText,
    };
  }, [onChangeOfSearchText]);

  // TODO: Adds action btns in header and their callbacks
  const headerActionBtns = renderHeaderActionButtons({
    actionBtns:
      [
        {
          id: 'add_new_btn',
          isAccessible: access?.customers?.add(),
          headerActionBtnClick: (): void => {
            navigate('/customer', { state: { id: null, mode: 'Add' } })
          }
        },
        {
          id: 'export_csv_btn',
          isAccessible: access?.customers?.export(),
          headerActionBtnClick: (event): void => {
            setExportAnchorEl(event.currentTarget);

          }
        }
      ]
  })

  const actionIconsList = useMemo(() => {
    return [
      { icon: 'sheildIcon', isAccessible: access?.chargers?.view(), action: (_, rowData) => handleChargerNavigation(rowData, 'mapView') },
      { icon: 'pencilIcon', isAccessible: access?.customers?.edit(), action: (_, rowData) => { navigate('/customer', { state: { id: rowData.id, mode: 'Edit', rowData: rowData } }) } },
      { icon: 'deleteIcon', isAccessible: access?.customers?.delete(), action: (_, rowData) => onDeleteIconClick(rowData?.id) },
    ];
  }, []);

  // TODO: Table Layout Callbacks
  const mobileViewConfiguration = useMemo(() => {
    return {
      headerDataConfig: {
        headerLeftDataConfig: ['id', 'name'],
        headerRightDataConfig: {
          actionIconsComponent: true,
          statusIconComponent: true,
        },
      },
      statusIconKey: 'status',
      contentDataConfig: [
        [
          {
            key: 'chargingStations',
            iconClassName: 'dashboard__charging__station__icon',
          },
          {
            key: 'chargers',
            iconClassName: 'chargers__icon',
          },
        ],
        [
          {
            key: 'faultyChargersCount',
            iconClassName: 'alarm__management__icon  ',
          },
        ],
      ],
    };
  }, []);

  const getTableRows = useCallback((): IManageCustomer[] => {
    return customers !== null && customers?.length > 0 ? customers : [];
  }, [customers]);

  const handleNameClick = async (event, rowData) => {
    navigate('/customer', { state: { id: rowData.id, mode: 'View', rowData: rowData } });
  };

  const handleChargerNavigation = async (rowData, column) => {
    let response: string
    if (column === 'chargers' || column === 'mapView') {
      response = await setRedirectedStateForChargerFilters(true, null, rowData?.id)(dispatch)
    } else {
      response = await setRedirectedStateForChargerFilters(true, 'Faulty', rowData?.id)(dispatch)
    }
    if (response === 'Action dispatched successfully') {
      navigate('/manage-chargers', {
        state: { layoutView: column === 'mapView' ? 'map' : 'list' }
      });
    }
  }

  const customerColumns = useMemo(() => {
    return [
      {
        field: 'name',
        // flex: 1,
        maxWidth: 500,
        headerName: 'Name',
        minWidth: 150,
        hideable: false,
        renderCell: (params: GridRenderCellParams<GridValidRowModel>): JSX.Element => {
          const rowData = params?.row;
          return (
            <div
              className="customer__dashboard_link-text"
              onClick={(e) => {
                e?.stopPropagation()
                handleNameClick(e, params.row);
              }
              }
            >
              {rowData?.name}
            </div>
          );
        },
      },
      {
        field: 'contactPerson',
        headerName: 'Contact Person',
        minWidth: 100,
        flex: 1,
      },
      {
        field: 'mobileNumber',
        headerName: 'Contact Number',
        minWidth: 100,
        flex: 1,
        renderCell: (params: GridRenderCellParams<GridValidRowModel>): ReactNode => {
          const rowData = params?.row;
          if (rowData?.countryCode && rowData?.mobileNumber) return rowData?.countryCode + rowData?.mobileNumber
          return rowData?.mobileNumber
        },
      },
      {
        field: 'chargingStations',
        headerName: 'Charging Stations',
        minWidth: 90,
        flex: 1,
        headerAlign: 'right',
        align: 'right',
        renderCell: (params: GridRenderCellParams<GridValidRowModel>): ReactNode => {
          const rowData = params?.row;
          return formatNumberWithCommas(rowData?.chargingStations);
        },
      },
      {
        field: 'chargers',
        headerName: 'Chargers',
        flex: 1,
        sortable: true,
        minWidth: 90,
        headerAlign: 'right',
        align: 'right',
        renderCell: (params: GridRenderCellParams<GridValidRowModel>): JSX.Element => {
          const rowData = params?.row;
          return (
            <div
              className="customer__dashboard_link-text"
              onClick={(e) => {
                e?.stopPropagation()
                handleChargerNavigation(params.row, 'chargers');
              }
              }
            >
              {formatNumberWithCommas(rowData?.chargers)}
            </div>
          );
        },
      },

      {
        field: 'faultyChargersCount',
        headerName: 'Faulty Chargers',
        flex: 1,
        sortable: true,
        minWidth: 90,
        headerAlign: 'right',
        align: 'right',
        renderCell: (params: GridRenderCellParams<GridValidRowModel>): JSX.Element => {
          const rowData = params?.row;
          return (
            <div
              className="customer__dashboard_link-text"
              onClick={(e) => {
                e?.stopPropagation()
                handleChargerNavigation(params.row, 'faultyChargersCount');
              }
              }
            >
              {formatNumberWithCommas(rowData?.faultyChargersCount)}
            </div>
          );
        },
      },
      {
        field: 'notes',
        headerName: 'Notes',
        minWidth: 100,
        flex: 1,
        headerAlign: 'left',
        sortable: false,
        renderCell: (params: GridRenderCellParams<GridValidRowModel>): JSX.Element => {
          const rowData = params?.row;
          return <CustomTooltipWithLabel labelId='customer__dashboard-notes' label={rowData?.notes} />
        },
      },
      {
        field: 'View',
        headerName: 'Actions',
        flex: 1,
        sortable: false,
        minWidth: 90,
        headerAlign: 'center',
        renderCell: (params: GridRenderCellParams<GridValidRowModel, Date>): JSX.Element => {
          const rowData = params?.row;
          return (
            <div className='charging__station__action__icons__wrap'>
              <div>
                <ActionIcons actionIconsList={actionIconsList} data={rowData} />
              </div>
            </div>
          );
        },
      },
    ];
  }, []);

  const layoutCallback = useCallback(async (pageNumber: number, pageSize: number, view: string, field: string, order: string, filterData, searchTerm) => {
    const tableProps: IChargerListTableProps = cloneDeep(customerListTablePropsRef.current)
    await dispatch(fetchCustomersData({
      view: view ?? 'list',
      sortBy: field || customerListTablePropsRef?.current?.sortBy,
      order: order || customerListTablePropsRef?.current?.order,
      pageSize,
      pageNumber,
      filters: filterData ? filterData : getCurrentFiltersValues('customers'),
      searchTerm: searchTerm != null && searchTerm != undefined ? searchTerm : customerListTablePropsRef?.current?.searchTerm
    }));
    if (tableProps) {
      tableProps.view = view || 'list'
      tableProps.sortBy = field || customerListTablePropsRef?.current?.sortBy;
      tableProps.order = order || customerListTablePropsRef?.current?.order;
      tableProps.pageNumber = pageNumber;
      tableProps.pageSize = pageSize;
      if (searchTerm != null && searchTerm != undefined) {
        tableProps.searchTerm = searchTerm;
      }
    }
    await dispatch(setCustomerTableProps(tableProps, 'customersList'))
  }, [customerListTablePropsRef?.current, dispatch]);

  // Handler For Column Prefrence Chnages
  const handleColumnPrefrenceChange = useCallback(async (operation, data) => {
    await dispatch(setTableColumnPrefrences('customers', operation, data))
  }, [])

  // ********************************************
  // TODO: Filter side pop up 
  // onchange -> handles onchange of all the dropdowns, layout props -> pass the required dropdowns, footer button action
  const handleFilterDataChange = useCallback(async (val, filterKey, isGlobal) => {
    const res = await setFilterData(val, filterKey, 'customers', 'SET', isGlobal ? isGlobal : false)(dispatch)
    if (res?.message === 'Action dispatched successfully') {
      if (customerListTablePropsRef?.current) {
        await layoutCallback(1, customerListTablePropsRef?.current?.pageSize, customerListTablePropsRef?.current?.view, customerListTablePropsRef?.current?.sortBy, customerListTablePropsRef?.current?.order, res?.filters, customerListTablePropsRef?.current?.searchTerm)
      }
    }
  }, [customerListTablePropsRef?.current, dispatch, customersRef?.current])

  // TODO: Redux state Clean up
  const customerCleanUpState = useCallback(() => {
    // : Partial<ChargerAction>
    const action = {
      type: customerTypes.CLEAR_CUSTOMER_LIST_DATA,
    }
    dispatch(action);
  }, [])


  useEffect(() => {
    window.addEventListener('beforeunload', customerCleanUpState);
    return (): void => {
      customerCleanUpState()
      window.removeEventListener('beforeunload', customerCleanUpState);
    }
  }, [])



  // ***********************************************************
  // TODO: Export Handlers
  const handleClose = (): void => {
    setExportAnchorEl(null);
  };

  const fileDownloadSuccessToast = useCallback((): Id => {
    setExportingFile(false);
    return toast.success('File downloaded.');
  }, []);

  const fileDownloadFailureToast = useCallback((): Id => {
    setExportingFile(false);
    return toast.warn('Something went wrong. Please export again.');
  }, []);


  const onExportBtnClick = useCallback(async (fileType: 'csv' | 'xlsx'): Promise<void> => {
    if (exportingFile) {
      // If already exporting, prevent another export
      return;
    }
    try {
      const response = await dispatch(exportToCsvForCustomerList({
        fileType: fileType,
        filters: getCurrentFiltersValues('customers')
      }));
      if (response?.status === 202 || response?.status === 200) {
        downloadFile({ url: response?.data?.url });
        fileDownloadSuccessToast()
      } else {
        fileDownloadFailureToast();
        setExportAnchorEl(null);
      }
    } finally {
      // Ensure that exporting status is updated even if there's an error
      setExportingFile(false);
      setExportAnchorEl(null);

    }
  }, [exportingFile, fileDownloadSuccessToast, fileDownloadFailureToast])


  return (
    <>
      <TableDashboard
        layoutsEnabled={false}
        tableId="manage__customer__table"
        tableClassName="manage__customer__table"
        header={'Customers'}
        searchBoxIncluded={true}
        searchBoxConfiguration={searchBoxConfiguration}
        gridColumns={customerColumns}
        tableRows={getTableRows()}
        mobileViewConfiguration={mobileViewConfiguration}
        layouts={layouts}
        layoutView={'list'}
        listLayoutCallBack={layoutCallback}
        headerActionBtns={headerActionBtns}
        totalCount={customerCount}
        showSkeleton={!customers}
        totalCountText={'customers'}
        showLoader={loader}
        customNoDataComponent={NoDataComponentCustomers}
        pageNumber={customerListTableProps?.pageNumber}
        tablePageSize={customerListTableProps?.pageSize}
        sortField={customerListTableProps?.sortBy}
        sortOrder={customerListTableProps?.order}
        handleFilterDataChange={handleFilterDataChange}
        filterProps={[]}
        columnPrefrences={screenTableColumnPrefrences?.find((item) => item.screen === 'customers')}
        handleColumnPrefrenceChange={handleColumnPrefrenceChange}

      />
      <Menu
        id="export-option-dropdown"
        anchorEl={exportAnchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        className='export__option__dropdown'
        sx={{ top: '30px' }}
        open={Boolean(exportAnchorEl)}
        onClose={handleClose}

      >
        <MenuItem>
          <div className='export__btn-option' onClick={(e) => {
            e?.stopPropagation();
            onExportBtnClick('csv')
          }}>
            To CSV
          </div>
        </MenuItem>
        <MenuItem>

          <div className='export__btn-option' onClick={(e) => {
            e?.stopPropagation();
            onExportBtnClick('xlsx')
          }}>
            To Excel
          </div>
        </MenuItem>
      </Menu>
      <CustomDialog
        dialogConfig={{
          dialogDescription: 'Are you sure you want to delete this customer?',
          dialogCancelTitle: 'Cancel',
          dialogOkTitle: 'Delete',
        }}
        show={isDeleteDialogOpen}
        handleClose={handleDeleteDialogClose}
        handleSubmit={handleDeleteUser}
      />
    </>
  );
})

export default CustomerManagementDashboard;
